import React, { createContext, useContext, useState } from 'react';
import { useUndoRedo } from '@worklist-2/core/src';

const WorkMenuContext = createContext({});

export const WorkFlowContextMenuProvider = ({ children }) => {
	const [
		state,
		setState,
		undo,
		redo,
		setCurrentIndex,
		wfaEditableMode,
		setWfaEditableMode,
		setIsUndoRedo,
		isUndoPossible,
		isRedoPossible,
		setHistory,
	] = useUndoRedo({
		name: '',
		isEnable: false,
		item: 'Select',
		status: 'Select',
		conditional: [],
		action: [],
		allSteps: [],
	});
	const [studyPlayerTypes, setStudyPlayerTypes] = useState([]);
	const [organizationRoles, setOrganizationRoles] = useState([]);
	const [studyStatuses, setStudyStatuses] = useState([]);
	const [studyStatusesForPMM, setStudyStatusesForPMM] = useState([]);
	const [studyStatusesExceptAppointment, setStudyStatusesExceptAppointment] = useState([]);
	const [reasonForExam, setReasonForExam] = useState([]);
	const [rejectionReason, setRejectionReason] = useState([]);
	const [modality, setModality] = useState([]);
	const [modalityForPMM, setModalityForPMM] = useState([]);
	const [bodyPart, setBodyPart] = useState([]);
	const [bodyPartForPMM, setBodyPartForPMM] = useState([]);
	const [sex, setSex] = useState([]);
	const [laterality, setLaterality] = useState([]);
	const [loincCode, setLoincCode] = useState([]);
	const [language, setLanguage] = useState([]);
	const [priority, setPriority] = useState([]);
	const [orderStatuses, setOrderStatuses] = useState([]);
	const [groupEmailTargets, setGroupEmailTargets] = useState([]);
	const [missingStudyInformation, setMissingStudyInformation] = useState([
		'Study Description',
		'Clinical Notes',
		'Referring Physician',
		'Referring Physician NPI',
		'Procedure Code',
	]);
	const [missingPatientInformation, setMissingPatientInformation] = useState([
		'First Name',
		'Last Name',
		'Date of Birth',
		'Patient Location',
	]);
	const [studyImageMismatch, setStudyImageMismatch] = useState([
		'Laterality vs. Study Description',
		'Modality vs. Study Description',
		'No. of Frames per series vs. Modality (CT - PT - MR)',
	]);
	const [reminderTime, setReminderTime] = useState([
		0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
	]);
	const [duplicateWorkflow, setDuplicateWorkflow] = useState(null);
	const [isDefaultWorkflow, setIsDefaultWorkflow] = useState(false);
	const [checkBeforeSubmit, setCheckBeforeSubmit] = useState({});
	return (
		<WorkMenuContext.Provider
			value={{
				state,
				setState,
				undo,
				redo,
				setIsUndoRedo,
				setCurrentIndex,
				isUndoPossible,
				isRedoPossible,
				setHistory,
				studyPlayerTypes,
				setStudyPlayerTypes,
				organizationRoles,
				setOrganizationRoles,
				studyStatuses,
				setStudyStatuses,
				studyStatusesForPMM,
				setStudyStatusesForPMM,
				studyStatusesExceptAppointment,
				setStudyStatusesExceptAppointment,
				reasonForExam,
				setReasonForExam,
				rejectionReason,
				setRejectionReason,
				modality,
				setModality,
				modalityForPMM,
				setModalityForPMM,
				bodyPart,
				setBodyPart,
				bodyPartForPMM,
				setBodyPartForPMM,
				sex,
				setSex,
				laterality,
				setLaterality,
				loincCode,
				setLoincCode,
				language,
				setLanguage,
				priority,
				setPriority,
				orderStatuses,
				setOrderStatuses,
				missingStudyInformation,
				setMissingStudyInformation,
				missingPatientInformation,
				setMissingPatientInformation,
				studyImageMismatch,
				setStudyImageMismatch,
				reminderTime,
				setReminderTime,
				duplicateWorkflow,
				setDuplicateWorkflow,
				isDefaultWorkflow,
				setIsDefaultWorkflow,
				wfaEditableMode,
				setWfaEditableMode,
				checkBeforeSubmit,
				setCheckBeforeSubmit,
				groupEmailTargets,
				setGroupEmailTargets,
			}}
		>
			{children}
		</WorkMenuContext.Provider>
	);
};

export const useWorkFlowMenu = () => useContext(WorkMenuContext);
export default WorkFlowContextMenuProvider;
