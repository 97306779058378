import React from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { PropTypes } from 'prop-types';
import {
	useTabContext,
	useAppModeContext,
	searchScopes,
	mediaTablet,
	useAuth,
	useConfig,
	useIsTablet,
} from '@worklist-2/core/src';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import searchApps from '../../../../../utils/marketplace/searchApps';
import SearchTabs from '../SearchTabs/SearchTabs';
import SearchDataSet from '../SearchResults/SearchDataSet';
import SearchResultItem, { getItemPageUrl, checkAccess } from '../SearchResults/SearchResultItem';
import SendDrawer from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const AutoSuggestDialog = ({
	value,
	data,
	closeSearchDialog,
	setTabsValue,
	openArticlesSearchResult,
	scope,
	loggedInUserId,
	isLoadingUserId,
	onGoToUser,
	handleSaveRecentlyViewed,
	showDialogHeader,
	setShowDialogHeader,
	setParentData,
	additionalSearchParams,
	setStudyInDetailedView,
	setIsSharedType,
	isBlumeHomePage,
}) => {
	const navigate = useNavigate();
	const { placeholder, validOrganizations, internalPatients } = useTabContext();
	const { isWorklistMode, isMarketplaceMode, isHelpCenterMode, isPatientPortalMode } = useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsMarketplace = isMarketplaceMode();
	const appModeIsHelpCenter = isHelpCenterMode();
	const appModeIsPatientPortal = isPatientPortalMode();
	const __config = useConfig();
	const { t } = useTranslation('Search');
	const { selectedLanguage, loggedInUser } = useAuth();
	const metaEmergencySearch = useBooleanFlagValue('meta-emergency-search');
	const isTablet = useIsTablet();

	const getClickHandler = item => {
		const url = getItemPageUrl(item, loggedInUserId, appModeIsWorklist || appModeIsHelpCenter, selectedLanguage);
		if (url || item.resourceType === 'Practitioner')
			return async () => {
				handleSaveRecentlyViewed(item);
				if (url) {
					navigate(url);
				} else {
					await onGoToUser(item);
				}
				closeSearchDialog();
			};
		return undefined;
	};

	return (
		<AutoSuggestionDialogdiv
			appModeIsHelpCenter={appModeIsHelpCenter}
			appModeIsMarketplace={appModeIsMarketplace}
			appModeIsWorklist={appModeIsWorklist}
			isBlumeHomePage={isBlumeHomePage}
			isTablet={isTablet}
		>
			<Box
				className="dialogHeader"
				sx={{
					width: '100%',
					boxSizing: 'border-box',
					display: showDialogHeader ? '' : 'None',
					[mediaTablet]: {
						display: 'none !important',
					},
				}}
			>
				{!appModeIsPatientPortal && (
					<>
						<SearchIcon />
						<Typography
							sx={{
								display: 'block',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
							variant="h6"
						>
							{value}
						</Typography>

						<Typography
							className="disabled"
							sx={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							variant="subtitle1"
						>
							{t('Press enter to view all results')}
						</Typography>
					</>
				)}
			</Box>

			{placeholder === 'All' &&
				data?.length > 0 &&
				(appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter) ? (
				<SearchDataSet
					isAutoSuggest
					data={data}
					getOnClick={getClickHandler}
					isLoadingUserId={isLoadingUserId}
				/>
			) : (
				data?.length > 0 &&
				(appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter) && (
					<List>
						{_.map(data.slice(0, 5), item => {
							const onClick = getClickHandler(item);
							const isAccess = checkAccess(
								item,
								validOrganizations,
								internalPatients,
								loggedInUser?.id,
								metaEmergencySearch
							);
							const cursorType = isAccess ? 'pointer' : 'default';

							return (
								<ListItemButton
									key={item}
									className="searchDialogItem"
									data-cy="list-items"
									sx={{
										cursor: cursorType,
									}}
									onClick={isAccess ? onClick : () => { }}
								>
									<SearchResultItem
										showButton
										SendDrawer={SendDrawer}
										isAccess={isAccess}
										item={item}
										showProgress={
											isLoadingUserId &&
											item.resourceType === 'Practitioner' &&
											item.id === isLoadingUserId
										}
										type="search"
										onGoTo={onClick}
									/>
								</ListItemButton>
							);
						})}
					</List>
				)
			)}

			{data &&
				(appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter) &&
				(data.length > 5 || (data.length > 1 && placeholder === 'All')) && (
					<div
						className="dialog-footer"
						onClick={() => {
							closeSearchDialog();
							if (scope === searchScopes.marketplace) {
								navigate(`/marketplace/appdirectory?name=${value}`);
								if (setParentData) {
									const searchParams = `?name=${value}${additionalSearchParams?.category
											? `&categories=${additionalSearchParams.category}`
											: ''
										}${additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''
										}`;

									searchApps({
										config: __config,
										queryParamString: searchParams,
									}).then(res => {
										setParentData(res);
									});
								}
							} else if (scope === searchScopes.helpElk) {
								openArticlesSearchResult();
							} else {
								setTabsValue(value);
							}
						}}
					>
						<Typography variant="subtitle1">
							<span style={{ cursor: 'pointer' }}>{t('VIEW MORE')}</span>
						</Typography>
					</div>
				)}

			{!(appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace) && (
				<SearchTabs
					closeSearchDialog={closeSearchDialog}
					data={data}
					setIsSharedType={setIsSharedType}
					setShowDialogHeader={setShowDialogHeader}
					setStudyInDetailedView={setStudyInDetailedView}
					showTabsHeader={!showDialogHeader}
					tab={placeholder}
				/>
			)}
		</AutoSuggestionDialogdiv>
	);
};

const AutoSuggestionDialogdiv = styled('div')(
	({ theme, isBlumeHomePage, isTablet, appModeIsWorklist, appModeIsMarketplace, appModeIsHelpCenter }) => ({
		width: '100%',
		top: isBlumeHomePage && !isTablet ? '0px' : '44px',
		position: 'absolute',

		zIndex: '100',
		backgroundColor: theme.palette.rsSecondary.medium,
		color: theme.palette.text.main,
		border: ' 1px solid rgba(255, 255, 255, 0.2)',
		maxHeight:
			appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? 'min(400px, calc(100vh - 200px))' : '',
		overflowY: 'auto',
		borderRadius: '6px',
		//padding: 0 0.5rem;

		'& .dialogHeader': {
			display: 'flex',
			padding: '1rem',
			gap: '2rem',
			justifyContent: 'flex-start',
			alignItems: ' center',
		},

		'& .MuiSvgIcon-root': {
			opacity: '1',
		},

		'& .disabled': {
			opacity: '0.6',
		},

		'& .dialog-footer ': {
			borderTop: '1px solid rgba(255, 255, 255, 0.2)',
			textAlign: 'center',
			padding: ' 1rem',
			color: '#42a5f5',
			'.h6': {
				display: 'inline-block',
				cursor: 'pointer',
			},
		},
	})
);

AutoSuggestDialog.propTypes = {
	/*
	 * The search query
	 */
	value: PropTypes.string.isRequired,
	/*
	 * The data to show in the dialog
	 */
	data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	/*
	 * Dialog event handlers
	 */
	closeSearchDialog: PropTypes.func,
	setSearchText: PropTypes.func,
	/*
	 * Dialog header
	 */
	showDialogHeader: PropTypes.bool,
	/*
	 * Function for updating parent component state, if necessary
	 */
	setParentData: PropTypes.func,
	/*
	 * Additional Search Parameters
	 */
	additionalSearchParams: PropTypes.object,
};

export default AutoSuggestDialog;
