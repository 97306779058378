import React from 'react';
import { Box } from '@mui/material';

const DropZoneFooterWrapper = ({ children, isOpen }) => (
	<Box
		sx={{
			zIndex: 12,
			position: 'absolute',
			bottom: '20px',
			width: '100%',
			display: 'grid',
			justifyContent: 'center',
			'.icon-holder': {
				marginBottom: '15px',
				display: 'flex',
				justifyContent: 'center',
			},
			'.container': {
				width: '250px',
				background: '#393939',
				borderRadius: '10px',
				textAlign: 'center',
				padding: '16px 0px',
			},
			span: {
				fontFamily: 'Roboto',
				color: '#fff',
			},
		}}
	>
		{children}
	</Box>
);

export default DropZoneFooterWrapper;
