import { useRef, useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { shared } from '../contexts/utils/zustand-use-broadcast';
import { produce } from 'immer';
import axios from 'axios';
import { useConfig, useAuth } from '@rs-core/hooks';
import { fetchService } from './fetchService';
import { useMeasurements } from './useMeasurements';
import { logWarn } from '@worklist-2/core/src';

export const resourceType = 'PresentationState';

const initialState = {
	presentationStates: [],
};

export const usePresentationStatesStore = create(
	shared(
		(set, get) => ({
			...initialState, // Initialize with default state
			getPresentationState: ({ studyInstanceUID }) => {
				let presentationState = get().presentationStates.find(b => b.studyInstanceUID === studyInstanceUID);
				if (!presentationState) {
					presentationState = { studyInstanceUID, resources: [] };
					set(state =>
						produce(state, draft => {
							draft.presentationStates.push(presentationState);
						})
					);
				}
				return presentationState;
			},
			getResource: ({ __config, studyInstanceUID, internalManagingId }) => {
				if (!studyInstanceUID || !internalManagingId) return null;
				const presentationState = get().getPresentationState({ studyInstanceUID });
				let resource = presentationState.resources.find(r => r.resourceType === resourceType);
				if (!resource) {
					resource = { resourceType, isLoaded: false, isFetched: false };
					set(state =>
						produce(state, draft => {
							const ps = draft.presentationStates.find(b => b.studyInstanceUID === studyInstanceUID);
							ps.resources.push(resource);
						})
					);
				}

				if (!resource.isLoaded && !resource.isFetched) {
					get().fetchResource({ __config, studyInstanceUID, internalManagingId });
					set(state =>
						produce(state, draft => {
							const ps = draft.presentationStates.find(b => b.studyInstanceUID === studyInstanceUID);
							const res = ps.resources.find(r => r.resourceType === resourceType);
							res.isFetched = true;
						})
					);
				}

				return resource;
			},
			changeResource: ({ studyInstanceUID }, newObj) =>
				set(state =>
					produce(state, draft => {
						const presentationState = draft.presentationStates.find(
							b => b.studyInstanceUID === studyInstanceUID
						);
						if (presentationState) {
							const resource = presentationState.resources.find(r => r.resourceType === resourceType);
							if (resource) {
								Object.assign(resource, newObj);
								resource.isLoaded = true;
							}
						}
					})
				),
			fetchResource: async ({ __config, studyInstanceUID, internalManagingId }) => {
				try {
					const url = `${__config.data_sources.dicom_web}/StudyResource?resourceType=PresentationState&studyInstanceUID=${studyInstanceUID}&internalManagingOrganizationId=${internalManagingId}`;
					const response = await axios.get(url);

					if (response.status === 200) {
						const [r] = response.data;
						get().changeResource({ studyInstanceUID }, r);
					} else {
						logWarn(
							'IV::',
							`usePresentationStatesStore::Failed to fetch resource for studyInstanceUID ${studyInstanceUID}. Status code: ${response?.status}`
						);
					}
				} catch (err) {
					console.error('Error in fetching resource of presentationState: ', err);
				}
			},
			resetPresentationState: () => set({ ...initialState }), // Reset to initial state
		}),
		{ name: 'presentation-state-store' }
	)
);

const usePresentationStates = ({ studyInstanceUID, internalManagingId }) => {
	const __config = useConfig();
	const { accessToken, sessionId } = useAuth();
	const url = `${__config.data_sources.dicom_web}/StudyResource?resourceType=PresentationState&studyInstanceUID=${studyInstanceUID}&internalManagingOrganizationId=${internalManagingId}`;

	const resource = usePresentationStatesStore(state =>
		state.getResource({ __config, studyInstanceUID, internalManagingId })
	);
	const lastSavedResource = useRef(resource);
	const lastModifiedResource = useRef(resource);

	const changeResource = usePresentationStatesStore(state => state.changeResource);
	const { fetchData } = useMeasurements();

	const saveResource = useCallback(async () => {
		const modifiedResource = lastModifiedResource.current;
		const { isLoaded, isFetched, ...rest } = modifiedResource;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: accessToken,
			sessionId,
		};

		//load and save measurements
		const measurements = await fetchData(null, {
			StudyInstanceUID: studyInstanceUID,
			InternalManagingOrgId: internalManagingId,
		});
		Object.assign(rest, { measurements });

		const callback = async response => {
			if (response?.status === 200) {
				const data = await response.json();
				lastSavedResource.current = data;
				changeResource({ studyInstanceUID }, data);
			} else {
				throw new Error(`Failed to save resource. Status code: ${response.status}. URL: ${url}`);
			}
		};

		fetchService.fetchKeepAlive({ url, headers, body: rest, callback });
	}, [accessToken, sessionId]);

	const saveChanges = useCallback(async () => {
		if (JSON.stringify(lastSavedResource.current) !== JSON.stringify(lastModifiedResource.current)) {
			await saveResource();
		}
	}, [saveResource]);

	const handleChangeResource = useCallback(
		obj => {
			changeResource({ studyInstanceUID, resourceType: 'PresentationState' }, obj);
		},
		[changeResource, studyInstanceUID]
	);

	useEffect(() => {
		lastModifiedResource.current = resource;
	}, [resource]);

	return {
		presentationStates: resource,
		changePresentationState: handleChangeResource,
		savePresentationStates: saveChanges,
	};
};

export default usePresentationStates;
