import { find } from 'lodash';
import { parseDate } from './parsers';

const buildDicomURL = ({
	isVideo,
	__config,
	studyInstanceUID,
	seriesInstanceUID,
	InstanceSOPUID,
	frameNumber,
	isBlumeApp,
}) => {
	const prefix = 'wadors:';
	let url =
		__config.data_sources.dicom_web +
		'/studies/' +
		studyInstanceUID +
		'/series/' +
		seriesInstanceUID +
		'/instances/' +
		InstanceSOPUID +
		`/frames/${frameNumber}`;
	if (isBlumeApp) {
		url =
			__config.data_sources.blume +
			'studies/' +
			studyInstanceUID +
			'/series/' +
			seriesInstanceUID +
			'/instances/' +
			InstanceSOPUID +
			`/frames/${frameNumber}`;
	}
	return isVideo ? url : prefix + url;
};

const buildCacheURL = ({
	isVideo,
	cacheUrl,
	studyInstanceUID,
	seriesInstanceUID,
	InstanceSOPUID,
	frameNumber,
	isChecking,
}) => {
	const prefix = 'wadors:';
	const url =
		`https://${cacheUrl}/DicomCache` +
		(isChecking ? '/exists' : '') +
		'/studies/' +
		studyInstanceUID +
		'/series/' +
		seriesInstanceUID +
		'/instances/' +
		InstanceSOPUID +
		`/frames/${frameNumber}`;

	return isVideo || isChecking ? url : prefix + url;
};

const getStudyDate = study => {
	const dateString = study?.['00080020']?.Value?.[0];
	if (!dateString) {
		return;
	} else {
		return parseDate(dateString, '/');
	}
};
const getStudyID = metadata => metadata?.['00200010']?.Value?.at(0);

const getStudyDateRaw = metadata => metadata?.['00080020']?.Value?.at(0);

const getStudyTimeRaw = metadata => metadata?.['00080030']?.Value?.at(0);

const getStudyInstanceUID = study => findMetadataTag(study, '0020000D')?.Value?.[0];

const getStudyAccessionNumber = study => study?.['00080050']?.Value?.[0];

const getStudyDescription = study => study?.['00081030']?.Value?.[0];

const getStudyModality = study => study?.['00080061']?.Value?.[0] || study?.['00080060']?.Value?.[0];

const getStudyBodyPart = study => study?.['00180015']?.Value?.[0];

const getStudyLaterality = study => study?.['00200062']?.Value?.[0] || study?.['00200060']?.Value?.[0];

const getStudyProcedure = study => study?.['00081032']?.Value?.[0];

const getStudyStatus = study => study?.['0032000A']?.Value?.[0] || study?.['0032000a']?.Value?.[0];

const getStudyPatientName = study => study?.['00100010']?.Value?.[0]?.Alphabetic;

const getStudyPatientID = study => study?.['00100020']?.Value?.[0];

const getStudyIssuerOfPatientID = study => study?.['00100021']?.Value?.[0];

const getSeriesUID = series => findMetadataTag(series, '0020000E')?.Value?.[0];

const getSeriesNumberOfFrames = metadata => metadata?.['00280008']?.Value?.[0];

const getSeriesTimeZoneOffset = metadata => metadata?.['00080201']?.Value?.[0];

const getSeriesDate = metadata => metadata?.['00080021']?.Value?.[0];

const getSeriesTime = metadata => metadata?.['00080031']?.Value?.[0];

const getSeriesInstanceCreationDate = metadata => metadata?.['00080012']?.Value?.[0];

const getSeriesInstanceCreationTime = metadata => metadata?.['00080013']?.Value?.[0];

const getSeriesInstanceNumber = metadata => metadata?.['00200013']?.Value?.[0];

const getSeriesAcquisitionDate = metadata => metadata?.['00080022']?.Value?.[0];

const getSeriesAcquisitionTime = metadata => metadata?.['00080032']?.Value?.[0];

const getSeriesAcquisitionDateTime = metadata => findMetadataTag(metadata, '0008002A')?.Value?.[0];

const getSeriesContentDate = metadata => metadata?.['00080023']?.Value?.[0];

const getSeriesContentTime = metadata => metadata?.['00080033']?.Value?.[0];

const getSeriesInstanceCount = series => series?.['00201209']?.Value?.[0];

const getSeriesDescription = series => findMetadataTag(series, '0008103E')?.Value?.[0];

const getSeriesViewPosition = series => series?.['00185101']?.Value?.[0];

const getSeriesScanOptions = series => series?.['00180022']?.Value?.[0];

const getSeriesModality = series => series?.['00080060']?.Value?.[0]; ////????????DUPLICATE

const getSeriesImageLaterality = series => series?.['00200062']?.Value?.[0]; /////??????DUPLICATE

const getSeriesLaterality = series => series?.['00200060']?.Value?.[0];

const getSeriesImageOrientation = series => series?.['00200037']?.Value; //////????DUPLICATE

const getInstanceImageLaterality = series => series?.['00200062']?.Value?.[0]; /////?????DUPLICATE

const getInstanceLaterality = series => series?.['00200060']?.Value?.[0];

const getInstanceViewPosition = instance => instance?.['00185101']?.Value?.[0];

const getInstanceScanOptions = instance => instance?.['00180022']?.Value;

const getInstanceImageOrientation = instance => instance?.['00200037']?.Value; /////?????DUPLICATE

const getInstanceModality = instance => instance?.['00080060']?.Value?.[0]; /////?????DUPLICATE

const getInstanceSyntax = instance => instance?.['00020010']?.Value?.[0];

const getSOPClassUID = instance => instance?.['00080016']?.Value?.[0];

const getInstanceSOPUID = instance => instance?.['00080018']?.Value?.[0];

const getInstanceRowsNumber = instance => instance?.['00280010']?.Value?.[0];

const getInstanceColumnsNumber = instance => instance?.['00280011']?.Value?.[0];

const getInstanceIsDocument = instance => !getInstanceRowsNumber(instance) && !getInstanceColumnsNumber(instance);

const getInstanceSharpness = instance => findMetadataTag(instance, '0016004a')?.Value?.[0];

const getInstanceUnsharpMask = instance => findMetadataTag(instance, '310d1020')?.Value?.[0];

const getInstanceWindowCenter = instance => instance?.['00281050']?.Value?.at(0);

const getInstanceWindowWidth = instance => instance?.['00281051']?.Value?.at(0);

const getInstanceConvolutionKernel = instance => instance?.['00181210']?.Value?.at(0);

const getInstanceAcquisitionMatrix = instance => instance?.['00181310']?.Value?.at(0);

const getInstanceNumberOfAverages = instance => instance?.['00180083']?.Value?.at(0);

const getInstanceEchoTrainLength = instance => instance?.['00180091']?.Value?.at(0);

const getInstanceMRAcquisitionType = instance => instance?.['00180023']?.Value?.at(0);

const getInstanceSequenceName = instance => instance?.['00180024']?.Value?.at(0);

const getInstanceSequenceVariant = instance => instance?.['00180021']?.Value?.at(0);

const getInstanceDateOfBirth = instance => instance?.['00100030']?.Value?.at(0);

const getInstanceSeriesNumber = instance => instance?.['00200011']?.Value?.at(0);

const getInstanceSex = instance => instance?.['00100040']?.Value?.at(0);

const getInstanceSliceThickness = instance => instance?.['00180050']?.Value?.at(0);

const getInstanceSpacingBetweenSlices = instance => instance?.['00180088']?.Value?.at(0);

const getInstanceContrastAgent = instance => instance?.['00180010']?.Value?.at(0);

const getInstanceKVP = instance => instance?.['00180060']?.Value?.at(0);

const getCurrent = instance => instance?.['00181151']?.Value?.at(0);

const getInstanceExposure = instance => instance?.['00181152']?.Value?.at(0);

const getInstanceExposureTime = instance => instance?.['00181150']?.Value?.at(0);

const getInstanceCompressionForce = instance => findMetadataTag(instance, '001811a2')?.Value?.at(0);

const getInstanceReconstructionDiameter = instance => instance?.['00181100']?.Value?.at(0);

const getInstanceOrganDose = instance => instance?.['00400316']?.Value?.at(0);

const getInstancePositionerPrimaryAngle = instance => instance?.['00181510']?.Value?.at(0);

const getInstanceBodyPartThickness = instance => findMetadataTag(instance, '001811a0')?.Value?.at(0);

const getInstanceRepetitionTime = instance => instance?.['00180080']?.Value?.at(0);

const getInstanceEchoTime = instance => instance?.['00180081']?.Value?.at(0);

const getInstanceInversionTime = instance => instance?.['00180082']?.Value?.at(0);

const getInstanceTriggerTime = instance => instance?.['00181060']?.Value?.at(0);

const getInstanceFlipAngle = instance => instance?.['00181314']?.Value?.at(0);

const getInstancePixelBandwidth = instance => instance?.['00180095']?.Value?.at(0);

const getInstanceLossyImageCompression = instance => instance?.['00282110']?.Value?.at(0);

const getInstanceLossyImageCompressionRatio = instance => instance?.['00282112']?.Value?.at(0);

const getInstanceStudyComments = instance => {
	let reasonForVisit = instance?.['00321066']?.Value?.at(0);
	if (typeof reasonForVisit !== 'string' || reasonForVisit === null || reasonForVisit.trim() === '') {
		return instance?.['00324000']?.Value?.at(0);
	}
	return reasonForVisit;
};

const getInstanceDerivationDescription = instance => instance?.['00082111']?.Value?.at(0);

const getInstanceTechName = instance => instance?.['00081070']?.Value?.at(0);

const getInstanceStationName = instance => instance?.['00081010']?.Value?.at(0);

const getInstanceInstitutionName = instance => instance?.['00080080']?.Value?.at(0);

const getInstanceLossyImageCompressionMethod = instance => instance?.['00282114']?.Value?.at(0);

const getInstanceImageType = instance => instance?.['00282114']?.Value?.at(0);

const getInstanceAge = instance => instance?.['00101010']?.Value?.at(0);

const getInstanceFPS = instance => instance?.['00180040']?.Value?.[0] || instance?.['00082144']?.Value?.[0];

const getProtocolName = metadata => metadata['00181030']?.Value?.[0]?.[0]?.toUpperCase();

const getBitsAllowcated = metadata => metadata['00280100']?.Value?.[0];

const getHighBit = metadata => metadata['00280102']?.Value?.[0];

const getSeriesIdFromImageId = imageId => {
	const regex = /series\/([^\/]+)/;
	const match = imageId.match(regex);

	return match ? match[1] : null;
};

const getViewportsCountFromProtocol = viewports => {
	let count = 0;

	viewports
		.filter(el => !el.parentId)
		.forEach(({ layout }) => {
			count = count + (layout.x + 1) * (layout.y + 1);
		});
	return count;
};

const getInitials = fullName => {
	if (!fullName) return;
	const namesArray = fullName.trim().split(' ');
	if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
	else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
};

const getFilteredStudies = ({ studies = [], currentStudy, studyFilter }) => {
	const otherStudies = studies.filter(
		study =>
			getStudyInstanceUID(study) !== getStudyInstanceUID(currentStudy) ||
			study.managingOrganizationId !== currentStudy?.managingOrganizationId
	);

	const currentStudies = otherStudies.filter(item => getStudyDate(item) === getStudyDate(currentStudy));

	const priorStudies = otherStudies.filter(item => getStudyDate(item) !== getStudyDate(currentStudy));

	switch (studyFilter) {
		case 'current':
			return [currentStudy, ...currentStudies];
		case 'prior':
			return priorStudies;
		default:
			return [currentStudy, ...otherStudies].filter(el => !!el);
	}
};

const GetModalityLoadTime = modality => {
	// default is 1000ms
	let time = 1000;

	switch (modality?.toUpperCase()) {
		case 'MG':
			time = 2000;
			break;
		case 'US':
		case 'XA':
			time = 800;
			break;
		case 'DX':
			time = 1400;
			break;
		case 'MR':
			time = 600;
			break;
		case 'CR':
		case 'HC':
			time = 1500;
			break;
		default:
			break;
	}
	return time;
};

/**
 * @param {number} num
 * @returns {number}
 * @description Rounds a number to two decimal places
 */
const getRoundToTwo = num => {
	return Math.round(num * 100) / 100;
};

const isStudyEqual = (source, dest) => {
	const sourceStudyUid =
		source.studyInstanceUID ?? source.StudyInstanceUID ?? source.studyUid ?? getStudyInstanceUID(source);
	const destStudyUid = dest.studyInstanceUID ?? dest.StudyInstanceUID ?? dest.studyUid ?? getStudyInstanceUID(dest);
	const sourceOrganizationId = source.managingOrganizationId ?? source.Managingorganizationid;
	const destOrganizationId = dest.managingOrganizationId ?? dest.Managingorganizationid;

	return (
		sourceStudyUid &&
		destStudyUid &&
		sourceStudyUid === destStudyUid &&
		sourceOrganizationId &&
		destOrganizationId &&
		sourceOrganizationId === destOrganizationId
	);
};

const isSeriesEqual = (source, dest) => {
	const sourceStudyUid =
		source.studyInstanceUID ?? source.StudyInstanceUID ?? source.studyUid ?? getStudyInstanceUID(source);
	const sourceSeriesUid = source.SeriesUID ?? source.SeriesID ?? getSeriesUID(source) ?? source.id;
	const destStudyUid = dest.studyInstanceUID ?? dest.StudyInstanceUID ?? dest.studyUid ?? getStudyInstanceUID(dest);
	const destSeriesUid = dest.SeriesUID ?? dest.SeriesID ?? getSeriesUID(dest) ?? dest.id;
	const sourceOrganizationId = source.managingOrganizationId ?? source.Managingorganizationid;
	const destOrganizationId = dest.managingOrganizationId ?? dest.Managingorganizationid;

	return (
		sourceStudyUid &&
		destStudyUid &&
		sourceStudyUid === destStudyUid &&
		sourceSeriesUid &&
		destSeriesUid &&
		sourceSeriesUid === destSeriesUid &&
		sourceOrganizationId &&
		destOrganizationId &&
		sourceOrganizationId === destOrganizationId
	);
};

const findMetadataTag = (metadata, tag) => find(metadata, (_, key) => key.toUpperCase() === tag.toUpperCase());

const toNumber = val => {
	if (Array.isArray(val)) {
		return val.map(v => (v !== undefined ? Number(v) : v));
	} else {
		return val !== undefined ? Number(val) : val;
	}
};

export {
	buildDicomURL,
	buildCacheURL,
	getStudyDate,
	getStudyAccessionNumber,
	getStudyInstanceUID,
	getStudyDescription,
	getStudyModality,
	getStudyBodyPart,
	getStudyLaterality,
	getStudyProcedure,
	getStudyStatus,
	getStudyPatientName,
	getStudyPatientID,
	getStudyIssuerOfPatientID,
	getSeriesUID,
	getSeriesTimeZoneOffset,
	getSeriesDate,
	getSeriesTime,
	getSeriesInstanceCreationDate,
	getSeriesInstanceCreationTime,
	getSeriesInstanceNumber,
	getSeriesAcquisitionDate,
	getSeriesAcquisitionTime,
	getSeriesAcquisitionDateTime,
	getSeriesContentDate,
	getSeriesContentTime,
	getSeriesInstanceCount,
	getSeriesDescription,
	getSeriesViewPosition,
	getSeriesScanOptions,
	getSeriesModality,
	getSeriesImageLaterality,
	getSeriesLaterality,
	getSeriesImageOrientation,
	getInstanceModality,
	getInstanceSyntax,
	getInstanceSOPUID,
	getInstanceRowsNumber,
	getInstanceColumnsNumber,
	getInstanceIsDocument,
	getInstanceFPS,
	getViewportsCountFromProtocol,
	getInitials,
	getFilteredStudies,
	getInstanceImageOrientation,
	getInstanceScanOptions,
	getInstanceViewPosition,
	getInstanceImageLaterality,
	getInstanceLaterality,
	getRoundToTwo,
	GetModalityLoadTime,
	getProtocolName,
	isStudyEqual,
	isSeriesEqual,
	findMetadataTag,
	toNumber,
	getSeriesNumberOfFrames,
	getStudyDateRaw,
	getStudyTimeRaw,
	getInstanceSharpness,
	getInstanceUnsharpMask,
	getInstanceWindowCenter,
	getInstanceWindowWidth,
	getInstanceConvolutionKernel,
	getInstanceAcquisitionMatrix,
	getInstanceNumberOfAverages,
	getInstanceEchoTrainLength,
	getInstanceMRAcquisitionType,
	getInstanceSequenceName,
	getInstanceSequenceVariant,
	getInstanceDateOfBirth,
	getStudyID,
	getInstanceSeriesNumber,
	getInstanceSex,
	getInstanceSliceThickness,
	getInstanceSpacingBetweenSlices,
	getInstanceContrastAgent,
	getInstanceKVP,
	getInstanceExposure,
	getInstanceExposureTime,
	getInstanceCompressionForce,
	getInstanceReconstructionDiameter,
	getInstanceOrganDose,
	getInstancePositionerPrimaryAngle,
	getInstanceBodyPartThickness,
	getInstanceRepetitionTime,
	getInstanceEchoTime,
	getInstanceInversionTime,
	getInstanceTriggerTime,
	getInstanceFlipAngle,
	getInstancePixelBandwidth,
	getInstanceLossyImageCompression,
	getInstanceLossyImageCompressionRatio,
	getInstanceStudyComments,
	getInstanceDerivationDescription,
	getInstanceTechName,
	getInstanceStationName,
	getInstanceInstitutionName,
	getInstanceLossyImageCompressionMethod,
	getInstanceImageType,
	getInstanceAge,
	getCurrent,
	getBitsAllowcated,
	getHighBit,
	getSeriesIdFromImageId,
	getSOPClassUID,
};
