import React from 'react';
import { Box, Typography } from '@mui/material';
import UploadDocumentsIcon from '../../../assets/icons/PatientInfoCards/upload-document-icon.svg';
import PropTypes from 'prop-types';

const NoDocuments = ({ onClick, t }) => (
	<Box data-testid="no-documents-container" sx={STYLES.MAIN_CONT} onClick={onClick}>
		<Box sx={STYLES.ICON_CONT}>
			{onClick && <UploadDocumentsIcon />}
			<Typography data-testid="no-documents-text" sx={STYLES.TEXT_STYLE}>
				{onClick ? t('Upload Document') : t('No Forms available')}
			</Typography>
		</Box>
	</Box>
);

NoDocuments.propTypes = {
	onClick: PropTypes.func,
};

export default NoDocuments;

const STYLES = {
	MAIN_CONT: {
		border: '1px dashed #FFFFFF66',
		height: '150px',
		display: 'flex',
		borderRadius: '12px',
		userSelect: 'none',
		cursor: 'pointer',
	},
	ICON_CONT: {
		flex: 1,
		alignItems: 'center',
		display: 'inline-flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	TEXT_STYLE: {
		fontSize: '14px',
		fontFamily: 'Roboto',
		fontWeight: 400,
		letterSpacing: '0.25px',
		lineHeight: 1.428,
		color: '#FFFFFF99',
	},
};
