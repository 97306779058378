import { create } from 'zustand';
import _ from 'lodash';
import moment from 'moment';

import { scanTypes } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentUtils/AppointmentUtils';
import getOrganizations from '@worklist-2/patientPortal/src/api/getOrganizations';
import getOrganizationLogo from '../api/getOrganizationLogo';
import getOrgModality from '@worklist-2/patientPortal/src/api/getOrgModality';
import getOrgOrderSets from '../api/getOrgOrderSets';
import getAvailableTimeslots from '@worklist-2/patientPortal/src/api/getAvailableTimeslots';
import bookSelfSchedulingAppointment from '@worklist-2/patientPortal/src/api/bookSelfSchedulingAppointment';
import getReferringPhysicians from '../api/getReferringPhysicians';
import commonMiddlewares from '@rs-core/store/middlewares';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';

export const clacDistance = (distance, country) => {
	if (!distance) return null;
	const isMiles = country?.toLowerCase() === 'united states' || country?.toLowerCase() === 'canada';
	const value = isMiles ? distance * 0.621371 : distance;
	const formattedValue = value.toFixed(0);
	return `${formattedValue} ${isMiles ? 'miles' : 'km'}`;
};

export const useScheduleStore = create(
	commonMiddlewares((set, get) => ({
		// State
		__config: null,
		accountExists: false,
		appointmentBooked: false,
		currentStep: 0,
		isLoading: false,
		modalityCode: '',
		modalities: [],
		orderSets: [],
		timeslots: [],
		referringPhysicians: [],
		orgDetails: null,
		patientList: [],
		selectedFacility: {},
		steps: [
			{ id: 1, completed: false },
			{ id: 2, completed: false },
			{ id: 3, completed: false },
			{ id: 4, completed: false },
			{ id: 5, completed: false },
			{ id: 6, completed: false },
		],
		rescheduleAppointment: null,

		resetState: () => {
			set(
				() => ({
					accountExists: false,
					appointmentBooked: false,
					currentStep: 0,
					isLoading: false,
					modalityCode: '',
					modalities: [],
					orderSets: [],
					timeslots: [],
					referringPhysicians: [],
					orgDetails: null,
					patientList: [],
					selectedFacility: {},
					steps: [
						{ id: 1, completed: false },
						{ id: 2, completed: false },
						{ id: 3, completed: false },
						{ id: 4, completed: false },
						{ id: 5, completed: false },
						{ id: 6, completed: false },
					],
				}),

				false,
				'scheduling/resetState'
			);
		},

		// Actions
		nextStep: () => {
			const { steps, currentStep } = get();
			const next = currentStep + 1;

			if (next > steps.length - 1) return;

			set(
				() => ({
					currentStep: next,
					steps: steps.map(step => (currentStep === step.id ? { ...step, completed: true } : step)),
				}),

				false,
				'scheduling/nextStep'
			);
		},

		prevStep: () => {
			const { currentStep } = get();

			if (currentStep === 0) return;

			set(() => ({ currentStep: currentStep - 1 }), false, 'scheduling/prevStep');
		},

		setModalities: list => {
			if (!list || !Array.isArray(list)) return;
			set(() => ({ modalities: [...list] }), false, 'scheduling/setModalities');
		},

		setModalityCode: code => {
			set(() => ({ modalityCode: code }), false, 'scheduling/setModalityCode');
		},

		jumpToStep: step => {
			const { steps } = get();

			if (step > steps.length - 1 || step < 0) return;

			set(() => ({ currentStep: step }), false, 'scheduling/jumpToStep');
		},

		setPatientList: list => {
			set(() => ({ patientList: list }), false, 'scheduling/setPatientList');
		},

		setRecheduleAppointment: appt => {
			set(() => ({ rescheduleAppointment: appt }), false, 'scheduling/setRescheduleAppointment');
		},

		setSharedStates: ({ __config }) => set(() => ({ __config }), false, 'scheduling/setSharedStates'),

		getOrgDetails: async ({ orgId }) => {
			try {
				const { timezoneExtUrl, timezoneIANAExtUrl, bookingUrl, bookingPhone } = fhirExtensionUrls.organization;

				if (!orgId) return;

				const { __config } = get();

				const result = await getOrganizations({ __config, orgIds: orgId });
				const org = result?.entry[0]?.resource;

				const filePath = org.extension?.find(d => d?.url?.includes('logo'))?.valueString;
				const logo = await getOrganizationLogo({ __config, orgId, filePath: filePath });
				const deepLink = org.extension?.find(d =>
					d?.url?.includes(fhirExtensionUrls.organization.deepLink)
				)?.valueString;

				const orgDetails = {
					id: org.id,
					name: org.name,
					email: _.get(_.find(org.telecom, { system: 'email' }), 'value', ''),
					fax: _.get(_.find(org.telecom, { system: 'fax' }), 'value', ''),
					phone: _.get(_.find(org.extension, { url: bookingPhone }), 'valueString', ''),
					website: _.get(_.find(org.extension, { url: bookingUrl }), 'valueString', ''),
					timezone: _.get(_.find(org.extension, { url: timezoneExtUrl }), 'valueString', ''),
					timezoneIANA: _.get(_.find(org.extension, { url: timezoneIANAExtUrl }), 'valueString', ''),
					filePath,
					active: org.active,
					logo,
					deepLink,
				};

				set(() => ({ orgDetails }), false, 'scheduling/getOrgDetails');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getModalities: async ({ orgId }) => {
			try {
				if (!orgId) return;

				set(() => ({ isLoading: true }), false, 'scheduling/getModalities');

				const { __config } = get();

				const result = await getOrgModality({ __config, orgId });

				const orgModalities = result?.entry?.flatMap(item =>
					item?.resource?.extension?.flatMap(ext => {
						if (ext?.url?.includes('StructureDefinition/modality')) {
							return ext?.extension?.map(m => m.valueCode).filter(Boolean) || [];
						}

						return [];
					})
				);

				const modalities = _.flatMap(_.uniq(orgModalities), item =>
					scanTypes.filter(scanType => scanType.DicomModalities.includes(item))
				);

				set(
					() => ({ modalities: _.uniqBy(modalities, 'Name'), isLoading: false }),
					false,
					'scheduling/getModalities'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getOrgOrderSets: async ({ orgId, searchString }) => {
			try {
				if (!orgId) return;
				const { __config } = get();
				const result = await getOrgOrderSets({ __config, orgId, searchString });
				if (result?.entry) {
					set(
						() => ({ orderSets: result?.entry?.map(ent => ent.resource) }),
						false,
						'scheduling/getOrgOrderSets'
					);
				}
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getAvailableTimeslots: async ({ orgId, date }) => {
			try {
				const { __config, modalityCode } = get();

				if (!orgId || !modalityCode || !date) return;

				set(() => ({ isLoading: true }), false, 'scheduling/getTimeslots');

				const result = await getAvailableTimeslots({ __config, orgId, modality: modalityCode, date });

				const timeslots = result.map((slot, index) => {
					const startTime = moment().startOf('day').add(slot.start, 'minutes').format('hh:mm A');
					const endTime = moment().startOf('day').add(slot.end, 'minutes').format('hh:mm A');

					return {
						...slot,
						id: index + 1,
						selected: false,
						time: `${startTime} - ${endTime}`,
					};
				});

				set(() => ({ timeslots, isLoading: false }), false, 'scheduling/getTimeslots');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		checkAccountExists: async ({ phone }) => {
			try {
				if (!phone) return;

				// set(() => ({ isLoading: true }));

				// const { __config } = get();

				// set(() => ({ isLoading: false, accountExists: true }), false, 'scheduling/checkAccountExists');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		bookAppointment: async ({ data }) => {
			try {
				if (!data) return null;

				set(() => ({ isLoading: true }), false, 'scheduling/bookAppointment');

				const { __config } = get();

				await bookSelfSchedulingAppointment({ __config, data });

				set(() => ({ isLoading: false, appointmentBooked: true }), false, 'scheduling/bookAppointment');
			} catch (err) {
				set(() => ({ isLoading: false, appointmentBooked: false }), false, 'scheduling/bookAppointment');
				console.error(err);
				throw err;
			}
		},

		getReferringPhysicians: async ({ searchTerm }) => {
			try {
				set(() => ({ isLoading: true }), false, 'scheduling/bookAppointment');

				const { __config, orgDetails } = get();

				if (!orgDetails?.id) throw new Error('No org details found');

				const res = await getReferringPhysicians({ __config, orgId: orgDetails.id, searchTerm });

				const physicians = _.map(res.entry, entry => {
					const name = _.get(entry, 'resource.name[0]');
					return { name: name ? name.text : null, id: entry.resource.id };
				}).filter(Boolean);

				set(() => ({
					referringPhysicians: physicians,
					isLoading: false,
				}));

				return physicians;
			} catch (err) {
				console.log(err);
			}
		},

		setSelectedFacility: async ({ facility }) => {
			set(() => ({ selectedFacility: facility }), false, 'scheduling/setSelectedFacility');
		},
	}))
);
