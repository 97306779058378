import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';

import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import useToast from '@rs-core/hooks/useToast';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import GenericDrawer from '@rs-ui/components/Drawers/GenericDrawer/GenericDrawer';
import FormAPIAutocompleteVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import FormSelectVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormSelectVariant';
import FormTextFieldVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormTextFieldVariant';
import searchIconAdornment from '@rs-ui/components/FormNew/formUtils/formMappingUtils';
import PrimaryButton from '@rs-ui/components/PrimaryButton';
import SecondaryButton from '@rs-ui/components/SecondaryButton';
import Toast from '@rs-ui/components/Toast';

const validationSchema = yup.object().shape({
	organization: yup.object().required('Organization is required').nullable(''),
	roles: yup.array().min(1, 'At least one role is required'),
	name: yup.string().required('Worklist name is required').nullable(''),
});

const CopyWorklistDrawer = ({
	anchor,
	currentOrganization,
	organizationOptions,
	columns,
	drawerOpen,
	setDrawerOpen,
	onCopy,
	metaSearchOrganizations,
	...otherProps
}) => {
	const { t } = useTranslation('workList');
	const { setToastUtility, toastOpen, toastMessage, handleToastClose } = useToast();
	const [rolesOptions, setRolesOptions] = useState([]);
	const [rolesOptionsLoading, setRolesOptionsLoading] = useState(false);
	const drawerWidth = 487;
	const form = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			roles: [],
			name: '',
			organization: null,
		},
	});
	const watchOrganization = form.watch('organization');
	const watchRoles = form.watch('roles');
	const watchName = form.watch('name');
	const bundleLoader = useFhirDataLoader({
		scope: searchScopes.bundle,
	});
	const rolesOptionsLoader = useFhirDataLoader({
		endpoint: 'Role',
	});

	const CancelSaveButtons = ({ closeDrawer, onSaveClick }) => (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: 455,
				m: 2,
			}}
		>
			<SecondaryButton label={t('cancel')} testId="CancelSettings" onClick={closeDrawer} />
			<PrimaryButton label={t('save')} onClick={onSaveClick} />
		</Box>
	);

	const onSave = form.handleSubmit(json => {
		const jsonCopy = _.cloneDeep(json);
		const basePayload = {
			name: jsonCopy.name,
			isDefault: false,
			columns,
			organization: json.organization,
			resourceType: 'WorkListLayout',
			extension: [
				{
					url: 'flagSave',
					valueString: '2',
				},
			],
		};

		const { roles } = jsonCopy;
		const payloads = roles?.map(r => ({
			request: {
				method: 'POST',
				url: '/WorklistLayout/',
			},
			resource: {
				role: {
					id: r?.id,
					reference: `${r?.resourceType}/${r?.id}`,
					display: r?.display,
				},
				...basePayload,
			},
		}));

		if (Array.isArray(payloads) && payloads.length > 0) {
			const bundlePayload = {
				id: 'bundle-transaction',
				resourceType: 'bundle',
				type: 'transaction',
				entry: payloads,
			};
			bundleLoader
				.save({}, bundlePayload)
				.then(response => {
					if (response?.status === 200 || response?.status === 201) {
						setToastUtility(true, t('Role Worklists have been saved.'));
						setDrawerOpen(false);
						onCopy();
					} else {
						throw new Error(response?.issue?.[0]?.diagnostics);
					}
				})
				.catch(err => {
					console.error(err);
					setToastUtility(true, t('Saving Worklists unsuccessful. Please try again later.'));
				});
		}
	});

	const loadOrganizationRolesById = orgId => {
		form.setValue('roles', []);
		setRolesOptions([]);
		if (orgId) {
			setRolesOptionsLoading(true);
			rolesOptionsLoader
				.load(
					{
						organization: orgId,
						active: 'true',
						_sort: 'name',
					},
					true
				)
				.then(response => {
					const res = response?.entry?.map(ent => ent?.resource) || [];

					form.setValue('role', []);
					setRolesOptions(res);
				})
				.finally(() => {
					setRolesOptionsLoading(false);
				});
		}
	};

	useEffect(() => {
		if (drawerOpen) {
			form.setValue('organization', currentOrganization);
		} else {
			form.reset();
		}
	}, [drawerOpen]);

	useEffect(() => {
		loadOrganizationRolesById(watchOrganization?.id);
	}, [watchOrganization]);

	return (
		<GenericDrawer
			anchor={anchor}
			drawerOpen={drawerOpen}
			setDrawerOpen={setDrawerOpen}
			title={t('workListDrawer.duplicateRoleWorklist')}
			width={drawerWidth}
			{...otherProps}
		>
			<Stack
				spacing={4}
				sx={{
					py: 4,
					width: 370,
					mx: 'auto',
				}}
			>
				<FormAPIAutocompleteVariant
					TextFieldProps={{
						label: t('workListDrawer.organization'),
						InputProps: {
							...searchIconAdornment,
						},
					}}
					formHook={form}
					name="organization"
					options={organizationOptions}
					props={{
						isOptionEqualToValue: (option, value) => !value || option?.id === value?.id,
						getOptionLabel: option => option?.display || '',
						getOptionDisabled: option => !option?.enabled,
					}}
					setOptions={() => null}
					onSearch={metaSearchOrganizations()}
				/>
				<FormSelectVariant
					formHook={form}
					menuPopoverAdjustments={{
						adjustHorizontal: rolesOptionsLoading ? 321 : 356,
					}}
					name="roles"
					options={rolesOptions}
					props={{
						label: t('workListDrawer.roles'),
						SelectProps: {
							multiple: true,
							isOptionEqualToValue: (option, value) => !value || option?.id === value?.id,
							renderValue: selectedValues => (
								<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									<Typography
										sx={{
											maxWidth: 248,
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{selectedValues[0]?.name}
									</Typography>
									{selectedValues.length >= 2 && (
										<Button
											style={{
												minWidth: 4,
												height: '22px',
												padding: 4,
												color: '#42A5F5',
												background: 'rgba(66, 165, 245, 0.1)',
												borderRadius: '4px',
											}}
										>
											{`+${selectedValues.length - 1}`}
										</Button>
									)}
								</Box>
							),
							customRenderMenuItem: option => (
								<MenuItem key={option?.id} sx={{ pt: 0, pb: 0 }} value={option}>
									<Checkbox checked={watchRoles?.indexOf(option) > -1} />
									<ListItemText primary={option?.name} />
								</MenuItem>
							),
						},
						InputProps: {
							...searchIconAdornment,
							endAdornment: (
								<>
									{rolesOptionsLoading && (
										<CircularProgress color="inherit" size={20} sx={{ mr: '20px' }} />
									)}
								</>
							),
						},
					}}
				/>
				<FormTextFieldVariant
					formHook={form}
					name="name"
					props={{
						placeholder: t('workListDrawer.worklistName'),
						InputProps: {
							...(watchName
								? {
										endAdornment: (
											<IconButton
												size="small"
												onClick={() => {
													form.setValue('name', '');
												}}
											>
												<ClearIcon />
											</IconButton>
										),
								  }
								: null),
						},
					}}
				/>
			</Stack>
			<CancelSaveButtons onSaveClick={onSave} />
			<Toast message={toastMessage} open={toastOpen} onClose={handleToastClose} />
		</GenericDrawer>
	);
};

export default CopyWorklistDrawer;
