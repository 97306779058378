import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Resizable } from 're-resizable';
import CardContainer from './CardContainer';
import CardContainerV2 from './CardContainerV2';

import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const CustomHandle = () => (
	<Box
		sx={{
			position: 'relative',
			minHeight: '12px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 10,
			top: '6px',
		}}
	>
		<Box
			data-testid="resize-handle"
			sx={{
				position: 'absolute',
				height: '4px',
				width: '75px',
				backgroundColor: '#FFFFFF61',
				borderRadius: '2px',
			}}
		/>
	</Box>
);

const minCardHeightOnlyInfo = 248;
const minCardHeight = 378;
const minCardHeightWithNotes = 408;

const PatientInfoCards = ({ sx, handleUploadDocument }) => {
	const {
		selectedCard,
		setIsCardCollapsed,
		resetPatientDoc,
		refHeight,
		setRefHeight,
		minHeight,
		setMinHeight,
		patientPermission,
	} = usePatientInfoCardStore();
	const canViewPatientDetails = patientPermission?.read;
	const galaxyDvGlobalNotes = useBooleanFlagValue('galaxy-dv-global-notes');
	const galaxyDvPatientChartRefactor = useBooleanFlagValue('galaxy-dv-patient-chart-refactor');
	const heightRef = !canViewPatientDetails
		? useRef(minCardHeightOnlyInfo)
		: galaxyDvGlobalNotes
		? useRef(minCardHeightWithNotes)
		: useRef(minCardHeight);
	const selectedCardRef = useRef(selectedCard); // to store previous state of selected card
	const [isHovered, setIsHovered] = useState(false);

	const handleCardReset = () => {
		switch (selectedCard?.idx) {
			case 0:
				setMinHeight(285); // Notes
				break;
			case 1:
				setMinHeight(260); // Patient Documents
				break;
			case 2:
				!canViewPatientDetails
					? setMinHeight(minCardHeightOnlyInfo)
					: galaxyDvGlobalNotes
					? setMinHeight(minCardHeightWithNotes)
					: setMinHeight(minCardHeight); // Patient Info Cards
				break;
			default:
				!canViewPatientDetails ? setMinHeight(30) : galaxyDvGlobalNotes ? setMinHeight(90) : setMinHeight(60);
		}
	};

	useEffect(() => {
		!canViewPatientDetails
			? setIsCardCollapsed(refHeight <= 30)
			: galaxyDvGlobalNotes
			? setIsCardCollapsed(refHeight <= 90)
			: setIsCardCollapsed(refHeight <= 60); //if refHeight is 90 the cards is in collapse state
	}, [refHeight]);

	useEffect(() => {
		// reset card height on switch between cards
		if (selectedCardRef.current?.idx !== selectedCard?.idx) {
			handleResetHeight();
			handleCardReset();
			resetPatientDoc();
		}
		selectedCardRef.current = selectedCard;
	}, [selectedCard]);

	useEffect(() => {
		const resizeListener = () => {
			if (
				(!canViewPatientDetails && heightRef.current === 30) ||
				(galaxyDvGlobalNotes && heightRef.current === 90) ||
				heightRef.current === 60
			)
				return; // If card is collapsed break resize action
			handleResetHeight();
		};

		window.addEventListener('resize', resizeListener);

		// Clean up the event listener on unmount
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	const handleResetHeight = value => {
		const heightToSet =
			value ||
			(window.innerHeight < 800
				? 300
				: !canViewPatientDetails
				? minCardHeightOnlyInfo
				: galaxyDvGlobalNotes
				? minCardHeightWithNotes
				: minCardHeight);
		if (
			(!canViewPatientDetails && heightRef.current === 30) ||
			(galaxyDvGlobalNotes && heightRef.current === 90) ||
			heightRef.current === 60
		) {
			// if collapsed set min height based on selected card
			handleCardReset();
		} else {
			setMinHeight(heightToSet);
		}
		heightRef.current = heightToSet;
		setRefHeight(heightToSet);
	};

	let isResizeActive;
	if (!canViewPatientDetails) {
		isResizeActive = refHeight > 30 && selectedCard?.code === 'INFO';
	} else if (galaxyDvGlobalNotes) {
		isResizeActive = refHeight > 90 && (selectedCard?.code === 'NOTES' || selectedCard?.code === 'DOCUMENTS');
	} else {
		isResizeActive = refHeight > 60 && selectedCard?.code === 'DOCUMENTS';
	}

	return (
		<Resizable
			data-testid="resizer-card"
			enable={{
				bottom: isResizeActive,
			}}
			handleComponent={
				isResizeActive && isHovered
					? {
							bottom: <CustomHandle />,
					  }
					: {}
			}
			minHeight={minHeight}
			size={{ height: refHeight }}
			style={{
				marginBottom: '12px',
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onResizeStop={(e, direction, ref, d) => {
				const newHeight = ref.offsetHeight;
				heightRef.current = newHeight;
				setRefHeight(newHeight);
			}}
		>
			<Box
				className="patient-card-content"
				data-testid="patient-card-content"
				sx={{
					'--main-content-height': `${
						galaxyDvGlobalNotes || !canViewPatientDetails ? refHeight : refHeight + 30
					}px`,
					...STYLES.CONTENT,
					flex: `1 1 ${refHeight}px`,
					height: `${refHeight}px`,
					maxHeight: `${refHeight}px`,
					...sx,
				}}
			>
				{galaxyDvPatientChartRefactor ? (
					<CardContainerV2
						handleUploadDocument={handleUploadDocument}
						resetHeight={handleResetHeight}
						rootHeight={refHeight}
					/>
				) : (
					<CardContainer
						handleUploadDocument={handleUploadDocument}
						resetHeight={handleResetHeight}
						rootHeight={refHeight}
					/>
				)}
			</Box>
		</Resizable>
	);
};

export default PatientInfoCards;

const STYLES = {
	CONTENT: {
		position: 'relative',
		background: '#393939',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '4px',
	},
};
