import { create } from 'zustand';
import commonMiddlewares from '@rs-core/store/middlewares';

export const useDocumentStore = create(
	commonMiddlewares((set, get) => ({
		// state
		isRenameDocumentDrawerOpen: false,

		// actions
		toggleRenameDocumentDrawer: isOpen => {
			set(({ isRenameDocumentDrawerOpen }) => ({
				isRenameDocumentDrawerOpen: isOpen !== undefined ? isOpen : !isRenameDocumentDrawerOpen,
			}));
		},
	}))
);
