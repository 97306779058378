// core
import React, { useState, useEffect, useCallback } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
// components
import GenericDrawer from '@rs-ui/components/Drawers/GenericDrawer';
import NotAuthorized from '@rs-ui/components/NotAuthorized';
import Toast from '@rs-ui/components/Toast';
import GenericDrawerTabs from '@rs-ui/components/Drawers/DrawerTabs/GenericDrawerTabs';
import SendStudyTab from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer/tabs/SendStudyTab';
import SendReportTab from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer/tabs/SendReportTab';
import { useAuth } from '@rs-core/context/UserAuthContext';
import useToast from '@rs-core/hooks/useToast';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SendDrawer = ({ anchor, studies, isSendMultiReport = false, ...otherProps }) => {
	const { t } = useTranslation('sendDrawer');
	const [sendPermissions, setSendPermissions] = useState(null);
	const [canSendStudies, setCanSendStudies] = useState(false);
	const [canSendReports, setCanSendReports] = useState(false);

	const { toastOpen, setToastUtility, handleToastClose, toastMessage } = useToast();

	const featEnableHl7ReportRedistribution = useBooleanFlagValue('feat-enable-hl7-report-redistribution');
	const proactEnableUacHomeSend = useBooleanFlagValue('proact-enable-uac-home-send');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	const { getGuiRoleByOrganizationAndResource, getGuiPrivilegeByOrganizationAndResourceAsync } = useAuth();

	const closeDrawer = () => {
		otherProps.setDrawerOpen(false);
	};

	const fetchHomePermissions = useCallback(
		async (organizationId, referringFacilityId, resource) => {
			const temPermissions = {};
			if (crossGetPrivilegeOnDemand) {
				const organizationPermission = await getGuiPrivilegeByOrganizationAndResourceAsync(
					organizationId,
					resource,
					referringFacilityId
				);

				if (organizationPermission) {
					Object.keys(organizationPermission).forEach(item => {
						temPermissions[item] = organizationPermission[item];
					});
					return temPermissions;
				}
			} else {
				const organizationPermission = getGuiRoleByOrganizationAndResource(
					organizationId,
					resource,
					referringFacilityId
				);

				if (organizationPermission) {
					Object.keys(organizationPermission).forEach(item => {
						temPermissions[item] = organizationPermission[item];
					});
					return temPermissions;
				}
			}
		},
		[crossGetPrivilegeOnDemand]
	);

	const tabList = [!proactEnableUacHomeSend || canSendStudies ? 'SEND STUDY' : null].filter(item => item !== null);
	const tabPanels = [
		!proactEnableUacHomeSend || canSendStudies ? (
			<SendStudyTab
				sendPermissions={sendPermissions}
				setToast={setToastUtility}
				studies={studies}
				onCancel={closeDrawer}
			/>
		) : null,
	].filter(item => item !== null);

	if ((!proactEnableUacHomeSend || canSendReports) && (featEnableHl7ReportRedistribution || studies?.length === 1)) {
		tabList.unshift('SEND REPORTS');
		tabPanels.unshift(
			<SendReportTab
				isMultiReport={isSendMultiReport}
				sendPermissions={sendPermissions ? sendPermissions[0] : null}
				study={studies ? studies[0] : null}
				onCancel={closeDrawer}
			/>
		);
	}

	const translatedTabList = tabList.map(tab => t(tab));

	useEffect(() => {
		const fetchPrivileges = async () => {
			if (studies && studies.length > 0) {
				const permissions = [];
				for (let index = 0; index < studies.length; index++) {
					const study = studies[index];
					if (study?.rawData?.internalManagingOrganizationID) {
						const permission = await fetchHomePermissions(
							study?.rawData?.internalManagingOrganizationID,
							study.referringFacilityId,
							'Home'
						);
						permissions.push(permission?.send);
						if (permission?.send?.['send study']?.read) {
							setCanSendStudies(true);
						}
						if (permission?.send?.['send reports']?.read) {
							setCanSendReports(true);
						}
					} else {
						permissions.push({});
					}
				}

				setSendPermissions(permissions);
			}
		};
		fetchPrivileges();
	}, [studies]);

	return (
		<>
			<Toast message={toastMessage} open={toastOpen} onClose={handleToastClose} />
			<GenericDrawer headerDividerFlag anchor={anchor} title={t('Send')} width={487} {...otherProps}>
				{canSendReports || canSendStudies ? (
					<GenericDrawerTabs
						tabId="send-tab"
						tabNames={translatedTabList}
						tabPanelContents={tabPanels}
						tabPanelId="send-tabpanel"
						tabPanelSx={{
							height: 'calc(100% - 24px)',
						}}
						tabSx={{
							minWidth: '110px',
							ml: '24px',
						}}
					/>
				) : (
					<NotAuthorized t={t} />
				)}
			</GenericDrawer>
		</>
	);
};

export default SendDrawer;
