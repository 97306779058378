import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Icon, IconButton, Tooltip, Box } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import axios from 'axios';

import { DownloadDiscImageBurner } from '@rs-ui/views/utils/downloader';
import downloadIcon from '@worklist-2/ui/src/assets/icons/imageViewer/topToolbar/download-round.svg';
import burnDiscIcon from '@worklist-2/ui/src/assets/icons/imageViewer/topToolbar/burntodisc.svg';
import { useConfig } from '@worklist-2/core/src';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';

import { STUDY_DATA } from '../../../__mocks__/mockData';
import MobileStudyDetailCompleted from './MobileStudyDetailCompleted';
import MobileStudyDetailScheduled from './MobileStudyDetailScheduled';

const MobileStudyDetail = ({ data, onShareClick, fullScreenMode, isShared }) => {
	const [study, setStudy] = useState();
	const [studyAdditionalInfo, setStudyAdditionalInfo] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [menuHovered, setMenuHovered] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const { setToastMsg } = useToastMessageContext();
	const { t } = useTranslation('studyDetail');

	const __config = useConfig();
	const loadStudy = async () => {
		const studyData = STUDY_DATA.filter(e => e.id === data?.study.id);
		if (studyData && studyData.length > 0) {
			setStudyAdditionalInfo({
				isShared: '',
				permission: '',
			});
			setStudy(studyData[0]);
		} else {
			const response = await axios.get(`${__config.data_sources.blume}Study?id=${data?.study.id}`);
			let studyPermission = '';
			if (!data?.study?.permission && isShared === 'withMe') {
				const email = sessionStorage.getItem('login_hint');
				const filteredContact = response.data.sharedInformation.filter(contact => contact.email === email);
				studyPermission = filteredContact.length > 0 ? filteredContact[0].permission : '';
			}
			setStudyAdditionalInfo({
				isShared: isShared || '',
				permission: data?.study?.permission || studyPermission,
			});
			setStudy(response.data);
		}
	};

	const loadData = async () => {
		await Promise.all([loadStudy()]);

		setIsLoading(false);
	};

	useEffect(() => {
		if (data) {
			loadData();
		}
	}, [data]);

	const articles = [
		{
			title: 'Title of the article Title of the article Title of the article 1',
			source: 'Source',
		},
		{
			title: 'Title of the article Title of the article Title of the article 2',
			source: 'Source',
		},
		{
			title: 'Title of the article Title of the article Title of the article 3',
			source: 'Source',
		},
		{
			title: 'Title of the article Title of the article Title of the article 4',
			source: 'Source',
		},
	];

	if (!study || isLoading) {
		return <Box>Loading...</Box>;
	}

	const component = () => {
		switch (study.status) {
			case 'SCHEDULED':
				return (
					<MobileStudyDetailScheduled
						appointmentDate={data?.appointmentDate || null}
						appointmentLocation={data?.appointmentLocation || null}
						study={study}
						studyAdditionalInfo={studyAdditionalInfo}
						onShareClick={onShareClick}
					/>
				);
			case 'Completed':
			case 'Ordered':
			case 'INPROCESS':
			default:
				return (
					<MobileStudyDetailCompleted
						articles={articles}
						fullScreenMode={fullScreenMode}
						study={study}
						studyAdditionalInfo={studyAdditionalInfo}
						onShareClick={onShareClick}
					/>
				);
		}
	};

	const handleStudyDownload = () => {
		if (!study?.studyThumbnail?.seriesUID) {
			setToastMsg(t(`Selected Study does not contain any images`));
			return;
		}
		setDownloadLoading(true);
		const url = `${__config.data_sources.blume}Study/${study?.studyThumbnail?.studyUID}/dicom?managingOrganizationId=${study?.managingOrganization?.id}`;
		axios({
			url,
			method: 'GET',
			responseType: 'blob',
		}).then(response => {
			const urlFile = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = urlFile;
			link.setAttribute('download', `${study?.studyThumbnail?.studyUID}.zip`);
			document.body.appendChild(link);
			link.click();
			setDownloadLoading(false);
		});
	};

	const downloadDiscBurner = () => {
		if (!study?.studyThumbnail?.seriesUID) {
			setToastMsg(t(`Selected Study does not contain any images`));
			return;
		}
		const download = new DownloadDiscImageBurner({
			uniqueId: study?.studyThumbnail?.studyUID,
			authUrl: __config.data_sources.blume,
			accessionNumber: study?.id,
			studyIds: study?.studyThumbnail?.studyUID,
			internalManagingOrganizationIds: study?.managingOrganization?.id,
			isBlumeApp: true,
		}).start({
			errorHandler: e => {
				setToastMsg(t(`CD Burner Error on Create`));
			},
		});
		if (download.enqueueStatus === 'ENQUEUED') {
			setToastMsg(t(`Preparing the disc burner...`, { ns: 'downloader' }));
		} else if (download.enqueueStatus === 'ALREADY-ENQUEUED') {
			setToastMsg(t(`This is being downloaded.`, { ns: 'downloader' }));
		}
	};

	return (
		<>
			{study && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						width: '100%',
						margin: '0px 10px 10px 0px',
					}}
				>
					<Box
						sx={STYLES.ILAND_BOX(menuHovered)}
						onMouseEnter={() => setMenuHovered(true)}
						onMouseLeave={() => setMenuHovered(false)}
					>
						<Tooltip placement="bottom" title="Burn to disc">
							<IconButton sx={STYLES.ILAND_BUTTON(menuHovered)} onClick={downloadDiscBurner}>
								<Icon component={burnDiscIcon} sx={STYLES.ILAND_ICON} />
							</IconButton>
						</Tooltip>
						<Tooltip placement="bottom" title="Download Study">
							<IconButton
								disabled={downloadLoading}
								sx={STYLES.ILAND_BUTTON(menuHovered)}
								onClick={handleStudyDownload}
							>
								<Icon component={downloadIcon} sx={STYLES.ILAND_ICON} />
							</IconButton>
						</Tooltip>
						{downloadLoading ? (
							<CircularProgress color={menuHovered ? 'primary' : 'info'} size={25} sx={{ mx: 0.5 }} />
						) : (
							<IconButton
								sx={{
									border: !menuHovered && '3px solid  #818181',
									height: '35px',
									width: '35px',
									color: menuHovered ? '#d1d1d1' : '#818181',
								}}
							>
								<MoreVert />
							</IconButton>
						)}
					</Box>
				</Box>
			)}
			{component()}
		</>
	);
};

export default MobileStudyDetail;

const STYLES = {
	ILAND_BOX: hovered => ({
		height: !hovered ? '35px' : '43px',
		width: hovered ? '110px' : '35px',
		borderRadius: hovered ? '80px' : '100px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: hovered && '#5d5d5d !important',
	}),
	ILAND_BUTTON: hovered => ({
		height: '35px',
		width: '35px',
		display: hovered ? 'block' : 'none',
		padding: '0px',
		marginLeft: '3px',
	}),
	ILAND_ICON: {
		boxSizing: 'border-box',
		flex: 'none',
		height: '35px',
		width: 'auto',
	},
};
