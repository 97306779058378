import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResendEmailIcon from '../../assets/icons/emailResend.svg';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useTranslation } from 'react-i18next';

const useLongPress = callback => {
	const [value, setValue] = useState(0);

	const [startLongPress, setStartLongPress] = useState(false);

	useEffect(() => {
		if (startLongPress) {
			setValue(100);
		} else {
			setValue(0);
		}

		let timerId;

		if (startLongPress) {
			timerId = setTimeout(callback, 1000);
		} else {
			clearTimeout(timerId);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [startLongPress]);

	const start = e => {
		e.stopPropagation();
		setStartLongPress(true);
	};

	const stop = e => {
		e.stopPropagation();
		setStartLongPress(false);
	};

	return {
		value,
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: stop,
		onTouchStart: start,
		onTouchEnd: stop,
	};
};

const DeleteProgress = ({
	buttonTooltip,
	className,
	deleteLabel,
	isLabelWithinCircle,
	sx,
	onDelete,
	circleSize,
	color,
	backgroundColor,
	...props
}) => {
	const { value, ...longPressProps } = useLongPress(onDelete);
	const { appMode } = useAppModeContext();
	const appModeIsPatientPortal = appMode == 'patientPortal';
	const { t } = useTranslation('drawer');
	const circularProgress = (
		<CircularProgress
			size={circleSize || (isLabelWithinCircle ? 56 : 40)}
			sx={{
				position: 'absolute',
				top: isLabelWithinCircle ? '-2px' : '0',
				left: '0',
				color: appModeIsPatientPortal ? color : 'rsPrimary.main',
				'& .MuiCircularProgress-circle': {
					transition: 'stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				},
			}}
			thickness={4}
			value={value}
			variant="determinate"
		/>
	);

	const label = (
		<span
			className="label"
			style={{
				marginTop: '0',
				display: 'flex',
				justifyContent: 'center',
			}}
			{...longPressProps}
			onClick={e => e.stopPropagation()}
		>
			{deleteLabel}
		</span>
	);

	const deleteIcon = (
		<IconButton
			data-testid="progress-delete-button"
			{...props}
			sx={{
				...sx,
				position: 'relative',

				...(isLabelWithinCircle
					? {
							height: '29px',
							width: '56px',
							paddingTop: '2px',
							paddingBottom: '1px',

							'&, & *': {
								background: 'transparent !important',
								backgroundColor: 'transparent !important',
							},
					  }
					: {}),
			}}
			{...longPressProps}
			onClick={e => e.stopPropagation()}
		>
			{buttonTooltip?.includes('invitation') ? (
				<Icon
					component={ResendEmailIcon}
					sx={{
						color: appModeIsPatientPortal ? color : 'icon.primary',
						paddingTop: '2px',
					}}
				/>
			) : (
				<DeleteOutlineIcon
					sx={{
						color: appModeIsPatientPortal ? color : 'icon.primary',
					}}
				/>
			)}

			{circularProgress}
		</IconButton>
	);

	const tooltip = element => {
		const { t } = useTranslation('drawer');
		return (
			<Tooltip
				disableFocusListener
				componentsProps={{
					tooltip: {
						sx: {
							color: appModeIsPatientPortal ? color : undefined,
							backgroundColor: appModeIsPatientPortal ? backgroundColor : undefined,
							borderRadius: appModeIsPatientPortal ? '10px' : undefined,
						},
					},
				}}
				title={buttonTooltip || (appMode === 'patientPortal' ? t('Hold to Delete') : t('Hold to Delete'))}
			>
				{element}
			</Tooltip>
		);
	};

	return (
		<Box
			className={className}
			sx={
				isLabelWithinCircle
					? {
							borderRadius: '4px',

							'&:hover': {
								backgroundColor: 'rgba(39, 39, 39, 0.08)',
							},
					  }
					: { display: 'flex', flexDirection: 'column', alignItems: 'center' }
			}
		>
			{tooltip(deleteIcon)}
			{deleteLabel ? tooltip(label) : ''}
		</Box>
	);
};

DeleteProgress.propTypes = {
	/**
	 * Tooltip override
	 */
	buttonTooltip: PropTypes.string,
	/**
	 * Custom className
	 */
	className: PropTypes.string,
	/**
	 * Delete icon label
	 */
	deleteLabel: PropTypes.string,
	/**
	 * Additional sx to customize the component
	 */
	sx: PropTypes.object,
	/**
	 * Callback to be invoked when a delete is initiated
	 */
	onDelete: PropTypes.func,
};

DeleteProgress.defaultProps = {
	deleteLabel: '',
};

export default DeleteProgress;
