// @flow

// core
import React, { useCallback, type Node } from 'react';

// components
import { Loader } from '@rs-ui/components/Loader/Loader';
import FemaleIcon from '@worklist-2/ui/src/assets/icons/female.svg';
import MaleIcon from '@mui/icons-material/Male';
import TransgenderIcon from '@mui/icons-material/Transgender';
// libs
import { Box, Stack, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IPatientGenderProps {
	gender: string;
	isLoading: boolean;
}

export const PatientGender = ({ gender, isLoading }: IPatientGenderProps): Node => {
	const { t } = useTranslation('patientInfo');
	const genderIconRenderer = useCallback(() => {
		switch (gender) {
			case 'male':
				return <MaleIcon style={SX.iconStyle} />;
			case 'female':
				return <FemaleIcon />;
			case 'other':
				return <TransgenderIcon style={SX.iconStyle} />;
			default:
		}
	}, [gender]);
	return (
		<Tooltip title={t('Gender')}>
			<Box data-testid="gender-wrapper" sx={SX.wrapper}>
				<Loader.PatientGender isLoading={isLoading}>
					<Stack alignItems="center" direction="row" gap={1}>
						{genderIconRenderer()}
						<Typography data-testid="gender-text" sx={SX.text}>
							{t(gender) || 'N/A'}
						</Typography>
					</Stack>
				</Loader.PatientGender>
			</Box>
		</Tooltip>
	);
};

const SX = {
	wrapper: {
		display: 'flex',
		columnGap: '12px',
		alignItems: 'center',
	},

	text: {
		fontSize: '14px',
		fontWeight: '400',
		color: '#FFFFFF99',
	},
	iconStyle: {
		fill: '#FFFFFF99',
		fontSize: '24px',
	},
};
