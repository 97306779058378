import React, { createContext, useContext, useEffect, useState } from 'react';

import { useFhirDataLoader, searchScopes, scopeMapping, fhirExtensionUrls, useAuth } from '@worklist-2/core/src';
import getColumnMapping from '@rs-core/fhir/resource/columnMapping/getColumnMapping';

import useGetSearchParams from '../hooks/useGetSearchParams';
import getPractitioners from '../api/getPractitioners';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const GlobalContext = createContext({});

const GlobalContextProvider = ({ children }) => {
	const galaxyDvPerformanceRefactorGetCall = useBooleanFlagValue('galaxy-dv-performance-refactor-get-call');
	const [patient, setPatient] = useState(null);
	const [diagnosticReports, setDiagnosticReports] = useState();
	const [documentReferences, setDocumentReferences] = useState();
	const [studies, setStudies] = useState();
	const [practitionerSignature, setPractitionerSignature] = useState();
	const [proxySignature, setproxySignature] = useState(null);
	const [proxyUser, setProxyUser] = useState(null);
	const [practitioner, setPractitioner] = useState();
	const [organizationTimezone, setOrganizationTimezone] = useState();
	const [studyShareQRLink, setStudyShareQRLink] = useState();
	const [practitioners, setPractitioners] = useState([]);
	const [practitionerRoles, setPractitionerRoles] = useState([]);
	const [practionerLoaded, setPractionerLoaded] = useState(false);
	const [proxyPractionerLoaded, setProxyPractionerLoaded] = useState(false);
	const [forms, setForms] = useState([]);
	const [isShownBackdrop, setIsShownBackdrop] = useState(false);

	const { loggedInUser } = useAuth();
	const { internalManagingOrganizationID } = useGetSearchParams();
	const fhirPractitionerDataLoader = useFhirDataLoader({
		scope: searchScopes.practitioner,
	});
	const practitionerRoleLoader = useFhirDataLoader({
		scope: searchScopes.practitionerRole,
		endpoint: scopeMapping[searchScopes.practitionerRole],
	});

	const practitionerColumnMapping = () => getColumnMapping(scopeMapping[searchScopes.practitioner]);

	useEffect(() => {
		if (galaxyDvPerformanceRefactorGetCall) return;
		getPractitioners(
			fhirPractitionerDataLoader,
			setPractitioners,
			practitionerColumnMapping(),
			internalManagingOrganizationID,
			{
				associatedorganization: internalManagingOrganizationID,
			}
		);
	}, []);

	useEffect(() => {
		if (galaxyDvPerformanceRefactorGetCall) return;
		const userId = loggedInUser?.id;

		if (!userId) return null;

		practitionerRoleLoader.load({ practitioner: userId }, true).then(result => {
			const roles = result?.entry?.map(entry => entry?.resource);
			setPractitionerRoles(roles);
		});
	}, [loggedInUser]);

	const practitionerUserType = (practitioner?.extension || []).find(
		ext => ext.url === fhirExtensionUrls.practitioner.userType
	);
	const isReferringPhysicianType = (practitionerUserType?.extension || []).find(
		ext => ext.url === fhirExtensionUrls.practitioner.userTypeIsReferringPhysician
	);
	const isReferringPhysician = Boolean(isReferringPhysicianType?.valueBoolean);

	return (
		<GlobalContext.Provider
			value={{
				patient,
				setPatient,
				diagnosticReports,
				setDiagnosticReports,
				documentReferences,
				setDocumentReferences,
				studies,
				setStudies,
				practitionerSignature,
				setPractitionerSignature,
				isReferringPhysician,
				practitioner,
				setPractitioner,
				organizationTimezone,
				setOrganizationTimezone,
				studyShareQRLink,
				setStudyShareQRLink,
				practitioners,
				forms,
				setForms,
				proxySignature,
				setproxySignature,
				proxyUser,
				setProxyUser,
				practitionerRoles,
				setPractitionerRoles,
				isShownBackdrop,
				setIsShownBackdrop,
				practionerLoaded,
				setPractionerLoaded,
				proxyPractionerLoaded,
				setProxyPractionerLoaded,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalContext, useGlobalContext, GlobalContextProvider as default };
