// core
import React from 'react';
// libs
import { Typography } from '@mui/material';
// utils
import dateTimeUtil from '@rs-core/utils/dateTimeUtil';

export const NotesFieldDateTime = ({ time, sx }) => {
	const formattedDate = dateTimeUtil.parseLocaleDate(time, 'date');
	const formattedTime = dateTimeUtil.parseLocaleDate(time, 'time');

	return <Typography sx={{ ...SX, ...sx }}>{`${formattedDate} ${formattedTime}`}</Typography>;
};

const SX = {
	fontSize: '12px',
	color: 'rgba(255, 255, 255, 0.6)',
};
