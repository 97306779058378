import React, { useState, useMemo, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import PatientCard from './PatientCard';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import getUserFullName, { parseFhirName } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';

import PatientInfo from './PatientInfo/PatientInfo';
import PatientDocument from './PatientDocument/PatientDocument';
import Notes from './Notes/Notes';

import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';
import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import { useGlobalNotesStore } from '@rs-ui/stores/notesStore';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useTranslation } from 'react-i18next';

const cardsInfo = ({ patientName, documentCount, notesCount, t }) => [
	{
		idx: 0,
		title: t('Notes'),
		code: 'NOTES',
		Component: props => <Notes {...props} />,
		color: '#42F59F',
		type: 'LIST',
		count: notesCount,
	},
	{
		idx: 1,
		title: t('Patient Documents'),
		code: 'DOCUMENTS',
		Component: props => <PatientDocument {...props} />,
		color: '#EB459E',
		type: 'LIST',
		count: documentCount,
	},
	{
		idx: 2,
		title: patientName,
		code: 'INFO',
		Component: props => <PatientInfo {...props} />,
		color: '#42A5F5',
		type: 'PATIENT_INFO',
	},
];
const CardContainer = ({ rootHeight, resetHeight, handleUploadDocument }) => {
	// ==================== State ====================
	// DV Team Reference:Patient Info, patient document, and Notes are initialized in the useInitPatientInfoCards.jsx file.
	// Other teams have their own separate initialization calls.
	const { cardOrder, setSelectedCard, setCardOrder } = usePatientInfoCardStore();
	const { patient, documents: patientDocuments } = usePatientStore();
	const { globalNotes } = useGlobalNotesStore();
	const galaxyDvGlobalNotes = useBooleanFlagValue('galaxy-dv-global-notes');
	const crossFormatPersonName = useBooleanFlagValue('cross-format-person-name');
	const { t } = useTranslation('patientInfo');
	const isMouseMovedRef = useRef(false);

	const userFullName = useMemo(
		() =>
			crossFormatPersonName
				? parseFhirName(patient?.patient?.name[0])
				: getUserFullName(patient?.patient?.name?.[0]),
		[patient, crossFormatPersonName]
	);

	const [cardsList, setCardsList] = useState([]);
	const [hoverIdx, setHoverIdx] = useState(null);
	const originalOrder = useRef(cardOrder);

	useEffect(() => {
		setCardsList(
			cardsInfo({
				patientName: userFullName,
				documentCount: patientDocuments?.length,
				notesCount: globalNotes?.length,
				galaxyDvGlobalNotes,
				t,
			}).filter(card => card.code !== 'NOTES' || galaxyDvGlobalNotes)
		);
	}, [userFullName, patientDocuments, globalNotes]);

	const handleClick = index => {
		const newOrder = [...cardOrder];
		const currentIndex = newOrder.indexOf(index);
		isMouseMovedRef.current = false;
		if (currentIndex === -1) return; // Ensure index is valid

		newOrder.splice(currentIndex, 1); // Remove clicked item from its current position
		newOrder.push(index); // Insert clicked item at the end
		originalOrder.current = newOrder; // Update originalOrder to new permanent order
		setCardOrder(newOrder);
		setSelectedCard(cardsList[index]);
	};

	const handleMouseEnter = index => {
		isMouseMovedRef.current && setHoverIdx(index);
	};

	const handleMouseLeave = () => {
		setHoverIdx(null);
		setCardOrder([...originalOrder.current]);
	};

	const handleMouseMove = e => {
		isMouseMovedRef.current = true;
		const orderIndex = parseInt(e.currentTarget.dataset.orderindex, 10);
		handleMouseEnter(orderIndex);
	};

	return (
		<Box
			data-testid="card-container"
			id="patient-info-cont"
			sx={{
				...STYLES.CONTAINER,
				height: `${rootHeight}px`,
			}}
		>
			<TransitionGroup>
				{cardsList.map((card, orderIndex) => {
					const currentIndex = cardOrder.indexOf(orderIndex);
					const isSelected = currentIndex === cardsList.length - 1;
					const isHover = hoverIdx === orderIndex; // False here to execute without hover effect
					const zIndex = isHover ? currentIndex + cardsList.length + 1 : currentIndex; // Calculate z-index
					const stackLevel =
						currentIndex !== 0 && isHover && !isSelected ? currentIndex * 30 - 30 : currentIndex * 30;
					return (
						<CSSTransition key={card.code} timeout={300}>
							<Box
								data-orderIndex={orderIndex}
								sx={{
									transform: `translate(0, ${stackLevel}px) scale(${
										!isSelected && isHover ? 1.072 : 1
									})`,
									zIndex,
									...STYLES.CARD,
								}}
								onClick={() => handleClick(orderIndex)}
								onMouseEnter={() => handleMouseEnter(orderIndex)}
								onMouseLeave={handleMouseLeave}
								onMouseMove={handleMouseMove}
							>
								<PatientCard
									key={orderIndex}
									cardData={card}
									component={card.Component}
									handleUploadDocument={handleUploadDocument}
									isHover={isHover}
									isSelected={isSelected}
									resetHeight={resetHeight}
									rootHeight={rootHeight}
								/>
							</Box>
						</CSSTransition>
					);
				})}
			</TransitionGroup>
		</Box>
	);
};

export default CardContainer;

const STYLES = {
	CONTAINER: {
		position: 'relative',
	},
	CARD: {
		position: 'absolute',
		width: '100%',
		transition: 'transform 0.3s ease-in-out, z-index 0.3s ease-in-out',
	},
};
