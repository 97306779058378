import { useRef, useCallback, useMemo, useEffect } from 'react';
import { create } from 'zustand';
import { shared } from '../contexts/utils/zustand-use-broadcast';
import { produce } from 'immer';
import { useConfig, useAuth } from '@rs-core/hooks';
import { logWarn } from '@worklist-2/core/src';
import axios from 'axios';
import debounce from 'lodash/debounce';

export const ResourceTypes = Object.freeze({
	annotations: 'annotations',
	viewState: 'viewState',
});

export const useBookmarksStore = create(
	shared(
		(set, get) => ({
			bookmarks: [],
			getBookmark: ({ studyID }) => {
				const bookmark = get().bookmarks?.find(b => b.studyID === studyID);
				if (bookmark) {
					return bookmark;
				}
				const newBookmark = { studyID, resources: [] };
				set(state =>
					produce(state, draft => {
						draft.bookmarks.push(newBookmark);
					})
				);
				return get().bookmarks?.find(b => b.studyID === studyID);
			},
			getResource: ({ studyID, resourceType, url, enabled }) => {
				if (!studyID || !resourceType) return null;
				const bookmark = get().getBookmark({ studyID });
				const resource = bookmark?.resources?.find(r => r.resourceType === resourceType);
				if (!resource) {
					set(state =>
						produce(state, draft => {
							const bm = draft.bookmarks?.find(b => b.studyID === studyID);
							bm.resources.push({ resourceType, isLoaded: false });
						})
					);
				}
				if (enabled && !resource?.isLoaded && !resource?.isFetched) {
					get().fetchResource({ studyID, resourceType, url });
					set(state =>
						produce(state, draft => {
							const bm = draft.bookmarks?.find(b => b.studyID === studyID);
							const res = bm.resources.find(r => r.resourceType === resourceType);
							res.isFetched = true;
						})
					);
				}
				return bookmark?.resources?.find(r => r.resourceType === resourceType);
			},
			changeResource: ({ studyID, resourceType }, newObj) =>
				set(state =>
					produce(state, draft => {
						const bookmark = draft.bookmarks?.find(b => b.studyID === studyID);
						if (bookmark) {
							const resource = bookmark.resources.find(r => r.resourceType === resourceType);
							if (resource) {
								// eslint-disable-next-line guard-for-in, no-restricted-syntax
								for (const property in newObj) {
									resource[property] = newObj[property];
								}
								resource.isLoaded = true;
							}
						}
					})
				),
			fetchResource: async ({ studyID, resourceType, url }) => {
				try {
					const response = await axios.get(url);

					if (response.status === 200) {
						const [r] = response.data;
						get().changeResource({ studyID, resourceType }, r);
					} else {
						logWarn(
							'IV::',
							`useBookmarksStore::Failed to fetch resource for studyID ${studyID}. Status code: ${response?.status}`
						);
					}
				} catch (err) {
					console.error('Error in fetching resource of bookmark: ', err);
				}
			},
			resetBookmarkStore: () => set({ bookmarks: [] }),
		}),
		{ name: 'bookmark-store' }
	)
);

const saveResource = debounce((lastModifiedResource, lastSavedResource, accessToken, url) => {
	const modifiedResource = lastModifiedResource.current;
	const { isLoaded, isFetched, ...rest } = modifiedResource;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: accessToken,
	};

	lastSavedResource.current = modifiedResource;

	fetch(url, {
		method: 'POST',
		headers,
		body: JSON.stringify(rest),
	}).catch(err => {
		console.error('Error in saving resource of bookmark: ', err);
	});
}, 500);

const useResource = ({ resourceType, studyID, persistOnUnmount }, enabled = true) => {
	const __config = useConfig();
	const { accessToken } = useAuth();
	const prefixUrl = useMemo(() => __config.data_sources.fhir, [__config.data_sources.fhir]);
	const url = useMemo(
		() => `${prefixUrl}/ImagingStudy/bookmark?studyID=${studyID}&resourceType=${resourceType}`,
		[prefixUrl, resourceType, studyID]
	);

	const resource = useBookmarksStore(state => state.getResource({ resourceType, studyID, url, enabled }));
	const lastSavedResource = useRef(resource);
	const lastModifiedResource = useRef(resource);

	const actions = useBookmarksStore(state => {
		const { changeResource } = state;
		return { changeResource };
	});

	const saveChanges = useCallback(() => {
		if (JSON.stringify(lastSavedResource.current) !== JSON.stringify(lastModifiedResource.current)) {
			saveResource(lastModifiedResource, lastSavedResource, accessToken, url);
		}
	}, [accessToken, url]);

	const changeResource = useCallback(
		obj => {
			actions.changeResource({ studyID, resourceType }, obj);
		},
		[actions, resourceType, studyID]
	);

	useEffect(() => {
		if (!enabled) {
			return;
		}
		lastModifiedResource.current = resource;

		saveChanges();
	}, [enabled, resource]);

	useEffect(
		() => {
			if (!enabled) {
				return;
			}
			function beforeUnloadHandler() {
				saveChanges();
			}

			if (persistOnUnmount) {
				window.addEventListener('beforeunload', beforeUnloadHandler);
			}

			return () => {
				if (persistOnUnmount) {
					return saveChanges();
				}
				return null;
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return {
		resource,
		changeResource,
	};
};

export default useResource;
