/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
/** **********************************************************************************************************************
Resource: ImagingStudyWorklistGroup
Reference: N/A
Proprietary RamSoft resource
*********************************************************************************************************************** */

export default class ImagingStudyWorklistGroupResource extends FhirDomainResource {
	id;
	resourceType;

	constructor(resourceString) {
		super(resourceString, imagingStudyWorklistGroupSchema);
		this.resourceType = 'ImagingStudyWorklistGroup';
		this.populateFields();
	}
}

const imagingStudyWorklistGroupSchema = {
	resourceType: 'ImagingStudyWorklistGroup',
	id: '__positiveInt__',
};
