import { create } from 'zustand';
import commonMiddlewares from '@rs-core/store/middlewares';

export const useDrawersStore = create(
	commonMiddlewares((set, get) => ({
		// State
		homeFilterOpen: true,

		// Mobile drawers state
		studyInformationDetails: null,
		mobileFilterDrawerOpen: false,
		searchText: '',
		searchInput: '',
		mobileSearchText: '',
		mobileSearchDrawerOpen: false,
		dicomDetails: null,
		homePageTab: 0,
		userFeedbackOpen: false,

		// Setters
		setHomeFilterOpen: bool => set(() => ({ homeFilterOpen: bool })),

		// Mobile drawers action
		setStudyInformationDetails: studyInformationDetails => set(() => ({ studyInformationDetails })),
		setMobileFilterDrawerOpen: mobileFilterDrawerOpen => set(() => ({ mobileFilterDrawerOpen })),
		setSearchText: searchText => set(() => ({ searchText })),
		setSearchInput: searchInput => set(() => ({ searchInput })),
		setMobileSearchText: mobileSearchText => set(() => ({ mobileSearchText })),
		setMobileSearchDrawerOpen: mobileSearchDrawerOpen => set(() => ({ mobileSearchDrawerOpen })),
		setDicomDetails: dicomDetails => set(() => ({ dicomDetails })),
		setHomePageTab: val => set(() => ({ homePageTab: val })),
		setUserFeedbackOpen: userFeedbackOpen => set(() => ({ userFeedbackOpen })),
	}))
);
