/* Generated automagically by FhirStarter - DO NOT EDIT */
export const fhirExtensionUrls = {
	test: {
		test: 'http://www.ramsoft.com/fhir/StructureDefinition/test',
	},
	common: {
		orderControl: 'http://www.ramsoft.com/fhir/extension/orderControl',
		ambulatoryStatus: 'http://www.ramsoft.com/fhir/StructureDefinition/ambulatoryStatus',
		anatomicFocus: 'http://www.ramsoft.com/fhir/StructureDefinition/anatomicFocus',
		bodyPart: 'http://www.ramsoft.com/fhir/StructureDefinition/bodyPart',
		modalityModifier: 'http://www.ramsoft.com/fhir/StructureDefinition/modalityModifier',
		pharmaceutical: 'http://www.ramsoft.com/fhir/StructureDefinition/pharmaceutical',
		pharmaceuticalType: 'http://www.ramsoft.com/fhir/StructureDefinition/pharmaceuticalType',
		pharmaceuticalDosage: 'http://www.ramsoft.com/fhir/StructureDefinition/pharmaceuticalDosage',
		pharmaceuticalName: 'http://www.ramsoft.com/fhir/StructureDefinition/pharmaceuticalName',
		reasonForExam: 'http://www.ramsoft.com/fhir/StructureDefinition/reasonForExam',
		laterality: 'http://www.ramsoft.com/fhir/StructureDefinition/laterality',
		technique: 'http://www.ramsoft.com/fhir/StructureDefinition/technique',
		instructions: 'http://www.ramsoft.com/fhir/StructureDefinition/instructions',
		view: 'http://www.ramsoft.com/fhir/StructureDefinition/view',
		birthSex: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
		ethnicity: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
		race: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
		lastUpdateUser: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateUser',
		language: 'http://www.ramsoft.com/fhir/extension/language',
		acceptLanguage: 'http://www.ramsoft.com/fhir/extension/acceptLanguage',
		status: 'http://www.ramsoft.com/fhir/StructureDefinition/status',
		statusChanged: 'http://www.ramsoft.com/fhir/StructureDefinition/statusChanged',
		studyStatus: 'http://www.ramsoft.com/fhir/StructureDefinition/studyStatus',
		priority: 'http://www.ramsoft.com/fhir/StructureDefinition/priority',
		referringPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/referringPhysician',
		performingPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/performingPhysician',
		technologist: 'http://www.ramsoft.com/fhir/StructureDefinition/technologist',
		transcriptionist: 'http://www.ramsoft.com/fhir/StructureDefinition/transcriptionist',
		transcriptionistFacility: 'http://www.ramsoft.com/fhir/StructureDefinition/transcriptionistFacility',
		readingPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/readingPhysician',
		consultingPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/consulting-physician',
		healthcareService: 'http://www.ramsoft.com/fhir/StructureDefinition/HealthcareService',
		modality: 'http://www.ramsoft.com/fhir/StructureDefinition/modality',
		active: 'http://www.ramsoft.com/fhir/StructureDefinition/active',
		customField1: 'http://www.ramsoft.com/fhir/StructureDefinition/customfield1',
		customField2: 'http://www.ramsoft.com/fhir/StructureDefinition/customfield2',
		studyReason: 'http://www.ramsoft.com/fhir/StructureDefinition/studyReason',
		customMemo1: 'http://www.ramsoft.com/fhir/StructureDefinition/custommemo1',
		direct: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-direct',
		studyType: 'http://www.ramsoft.com/fhir/extension/studyType',
		loincCode: 'http://www.ramsoft.com/fhir/extension/loincCode',
		isHl7Updated: 'http://www.ramsoft.com/fhir/extension/isHL7Updated',
		isFHIRUpdated: 'http://www.ramsoft.com/fhir/extension/isFHIRUpdated',
		isBilled: 'http://www.ramsoft.com/fhir/extension/isBilled',
		isPosted: 'http://www.ramsoft.com/fhir/extension/isPosted',
		organization: 'http://www.ramsoft.com/fhir/extension/organization',
		imagingOrganization: 'http://www.ramsoft.com/fhir/extension/imagingOrganization',
		managingOrganization: 'http://www.ramsoft.com/fhir/extension/managingOrganization',
		department: 'http://www.ramsoft.com/fhir/extension/department',
		room: 'http://www.ramsoft.com/fhir/extension/room',
		scheduledExamRoom: 'http://www.ramsoft.com/fhir/extension/scheduledExamRoom',
		faxStatus: 'http://www.ramsoft.com/fhir/extension/faxStatus',
		faxStatusDateTime: 'http://www.ramsoft.com/fhir/extension/faxStatusDateTime',
		chargePostedDateTime: 'http://www.ramsoft.com/fhir/extension/chargePostedDateTime',
		receivedDateTime: 'http://www.ramsoft.com/fhir/extension/receivedDateTime',
		signedDateTime: 'http://www.ramsoft.com/fhir/extension/signedDateTime',
		addendumDateTime: 'http://www.ramsoft.com/fhir/extension/addendumDateTime',
		faxStatusDateTimeUTC: 'http://www.ramsoft.com/fhir/extension/faxStatusDateTimeUTC',
		chargePostedDateTimeUTC: 'http://www.ramsoft.com/fhir/extension/chargePostedDateTimeUTC',
		receivedDateTimeUTC: 'http://www.ramsoft.com/fhir/extension/receivedDateTimeUTC',
		signedDateTimeUTC: 'http://www.ramsoft.com/fhir/extension/signedDateTimeUTC',
		addendumDateTimeUTC: 'http://www.ramsoft.com/fhir/extension/addendumDateTimeUTC',
		requestedProcedureID: 'http://www.ramsoft.com/fhir/extension/requestedProcedureID',
		availabilityCode: 'http://www.ramsoft.com/fhir/extension/availabilityCode',
		appointmentStartDateTime: 'http://www.ramsoft.com/fhir/extension/appointmentStartDateTime',
		appointmentEndDateTime: 'http://www.ramsoft.com/fhir/extension/appointmentEndDateTime',
		appointmentDuration: 'http://www.ramsoft.com/fhir/extension/appointmentDuration',
		hl7Json: 'http://www.ramsoft.com/fhir/extension/hl7Json',
		isActive: 'http://www.ramsoft.com/fhir/StructureDefinition/isActive',
		gender: 'http://www.ramsoft.com/fhir/extension/gender',
		birthdate: 'http://www.ramsoft.com/fhir/extension/birthDate',
		sSN: 'http://www.ramsoft.com/fhir/extension/ssn',
		numberOfAddendumReports: 'http://www.ramsoft.com/fhir/extension/numberOfAddendumReports',
		numberOfDiagReports: 'http://www.ramsoft.com/fhir/extension/numberOfDiagReports',
		numberOfDictations: 'http://www.ramsoft.com/fhir/extension/numberOfDictations',
		numberOfDocuments: 'http://www.ramsoft.com/fhir/extension/numberOfDocuments',
		numberOfFrames: 'http://www.ramsoft.com/fhir/extension/numberOfFrames',
		numberOfImages: 'http://www.ramsoft.com/fhir/extension/numberOfImages',
		numberOfSignedPreliminary: 'http://www.ramsoft.com/fhir/extension/numberOfSignedPreliminary',
		numberOfSignedReports: 'http://www.ramsoft.com/fhir/extension/numberOfSignedReports',
		reasonCode: 'http://www.ramsoft.com/fhir/extension/reasonCode',
		text: 'text',
		address: 'http://www.ramsoft.com/fhir/extension/address',
		matchGrade: 'http://www.ramsoft.com/fhir/StructureDefinition/match-grade',
		severity: 'http://www.ramsoft.com/fhir/reaction-event-severity',
		localFileID: 'http://www.ramsoft.com/fhir/StructureDefinition/LocalFileID',
		isDistributedToHL7: 'http://www.ramsoft.com/fhir/StructureDefinition/isDistributedToHL7',
		studyDateTime: 'http://www.ramsoft.com/fhir/StructureDefinition/studyDateTime',
		studyDescription: 'http://www.ramsoft.com/fhir/StructureDefinition/StudyDescription',
		statusValue: 'http://www.ramsoft.com/fhir/extension/studyStatus/statusValue',
		lastUpdateDevice: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateDevice',
		tagging: 'http://www.ramsoft.com/fhir/StructureDefinition/tagging',
		report: 'http://www.ramsoft.com/fhir/StructureDefinition/report',
		ramSoftUserRole: 'http://www.ramsoft.com/fhir/StructureDefinition/ramSoftUserRole',
	},
	account: {
		guarantorRelationship: 'http://www.ramsoft.com/fhir/extension/guarantorRelationship',
		balance: 'http://www.ramsoft.com/fhir/extension/balance',
		phone: 'http://www.ramsoft.com/fhir/StructureDefinition/phone',
	},
	appointment: {
		blockedTime: 'http://www.ramsoft.com/fhir/extension/appointment/blockedTime',
		recurringBlockedTime: 'http://www.ramsoft.com/fhir/extension/appointment/recurringBlockedTime',
		busyTime: 'http://www.ramsoft.com/fhir/extension/appointment/busyTime',
		excludedDate: 'http://www.ramsoft.com/fhir/extension/appointment/excludedDate',
		rRule: 'http://www.ramsoft.com/fhir/extension/appointment/rrule',
		allowToCreate: 'http://www.ramsoft.com/fhir/extension/appointment/allowToCreate',
		temporaryBlock: 'http://www.ramsoft.com/fhir/StructureDefinition/temporaryBlock',
		appointmentDateTime: 'http://www.ramsoft.com/fhir/StructureDefinition/appointmentDateTime',
		appointment24HrsNotificationTrigger:
			'http://www.ramsoft.com/fhir/StructureDefinition/appointment24HrsNotificationTrigger',
		appointment72HrsNotificationTrigger:
			'http://www.ramsoft.com/fhir/StructureDefinition/appointment72HrsNotificationTrigger',
		reservation: 'http://www.ramsoft.com/fhir/extension/appointment/reservation',
		recurringReservation: 'http://www.ramsoft.com/fhir/extension/appointment/recurringReservation',
		reservationName: 'http://www.ramsoft.com/fhir/extension/appointment/reservationName',
		reservationColor: 'http://www.ramsoft.com/fhir/extension/appointment/reservationColor',
		rescheduleReason: 'http://www.ramsoft.com/fhir/extension/appointment/rescheduleReason',
		rescheduleReasonDetails: 'http://www.ramsoft.com/fhir/extension/appointment/rescheduleReasonDetails',
		cancelReason: 'http://www.ramsoft.com/fhir/extension/appointment/cancelReason',
		cancelReasonDetails: 'http://www.ramsoft.com/fhir/extension/appointment/cancelReasonDetails',
		blumeNotes: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/blumeNotes',
		isPublicBooking: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/isPublicBooking',
		patientFriendlyModality: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/patientFriendlyModality',
		blumeAttachment: 'http://www.ramsoft.com/fhir/extension/appointment/blumeAttachment',
		parkingIndex: 'http://www.ramsoft.com/fhir/extension/appointment/parkingIndex',
		noteTime: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/noteTime',
		author: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/author',
		isAutoScheduled: 'http://www.ramsoft.com/fhir/extension/appointment/isAutoScheduled',
		selfScheduling: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/selfschedulingsettings',
	},
	selfSchedulingSettings: {
		allowReschedule: 'http://www.ramsoft.com/fhir/StructureDefinition/allowReschedule',
		allowCancel: 'http://www.ramsoft.com/fhir/StructureDefinition/allowCancel',
	},
	auditEvent: {
		requestQuery: 'requestQuery',
		accessionNumber: 'AccessionNumber',
		patientName: 'PatientName',
		patientID: 'PatientID',
		managingOrganization: 'ManagingOrganization',
		imagingFacility: 'ImagingFacility',
		studyStatus: 'StudyStatus',
		studyID: 'StudyID',
		visitNumber: 'VisitNumber',
		internalPatientID: 'InternalPatientID',
		internalEncounterID: 'InternalEncounterID',
		internalStudyID: 'InternalStudyID',
		internalProcedureRequestID: 'InternalProcedureRequestID',
		studyUID: 'StudyUID',
		currentResource: 'CurrentResource',
		matchedSubscription: 'MatchedSubscription',
		internalObjectID: 'InternalObjectID',
		lastUpdated: 'lastUpdated',
		taskCode: 'http://www.ramsoft.com/fhir/CodeSystem/task-code',
		userManagingOrganization: 'userManagingOrganization',
	},
	codeSystem: {
		searchConcept: 'searchConcept',
		inCatalog: 'InCatalog',
		catalogList: 'CatalogList',
		active: 'Active',
		mapModalities: 'mapModalities',
		isModality: 'IsModality',
		isSchedule: 'IsSchedule',
		modalityDescription: 'Description',
	},
	communication: {
		status: 'http://www.ramsoft.com/fhir/extension/Communication/status',
		subject: 'http://www.ramsoft.com/fhir/extension/Communication/subject',
		conversationID: 'http://www.ramsoft.com/fhir/extension/Communication/conversationID',
		folder: 'http://www.ramsoft.com/fhir/extension/Communication/folder',
		contactPoint: 'http://www.ramsoft.com/fhir/extension/Communication/contactPoint',
		parent: 'http://www.ramsoft.com/fhir/extension/Communication/parent',
		failedCount: 'http://www.ramsoft.com/fhir/extension/Communication/failedCount',
		note: 'http://www.ramsoft.com/fhir/extension/Communication/note',
		created: 'http://www.ramsoft.com/fhir/extension/Communication/created',
		read: 'http://www.ramsoft.com/fhir/extension/Communication/read',
		readtime: 'http://www.ramsoft.com/fhir/extension/Communication/readtime',
	},
	communicationRequest: {
		nextDate: 'http://www.ramsoft.com/fhir/extension/CommunicationRequest/nextDate',
		frequencyInDays: 'http://www.ramsoft.com/fhir/extension/CommunicationRequest/frequencyInDays',
		active: 'http://www.ramsoft.com/fhir/extension/CommunicationRequest/active',
	},
	coverage: {
		financialClass: 'financialClass',
		insuranceStatus: 'insuranceStatus',
		financialType: 'financialType',
		networkInOut: 'networkInOut',
		copayAmount: 'copayAmount',
		insuranceCardFront: 'insuranceCardFront',
		insuranceCardBack: 'insuranceCardBack',
		rejectionReason: 'rejectionReason',
		followUpAction: 'followUpAction',
		providerName: 'providerName',
		healthCareFinancingProviderIdentifier: 'healthCareFinancingProviderIdentifier',
		eligibilityStatus: 'eligibilityStatus',
		requestId: 'requestId',
		insuranceCard: 'http://www.ramsoft.com/fhir/extension/Coverage/attachment',
		payerType: 'http://www.ramsoft.com/fhir/StructureDefinition/payerType',
		payerID: 'http://www.ramsoft.com/fhir/StructureDefinition/payerID',
		pVerifyId: 'http://www.ramsoft.com/fhir/StructureDefinition/pverifyID',
		eligibilityPayerID: 'http://www.ramsoft.com/fhir/StructureDefinition/eligibilityPayerID',
		employerPhoneNumber: 'http://www.ramsoft.com/fhir/StructureDefinition/phone',
	},
	device: {
		shareStorage: 'http://www.ramsoft.com/fhir/dicomserverconfig/shareStorage',
		healthCareService: 'http://www.ramsoft.com/fhir/dicomserverconfig/healthcareservice',
		aeTitle: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomserveraetitle',
		device: 'http://www.ramsoft.com/fhir/dicomserverconfig/device',
		associatedDevice: 'http://www.ramsoft.com/fhir/dicomserverconfig/associateddevice',
		imagingOrganization: 'http://www.ramsoft.com/fhir/dicomserverconfig/imagingorganization',
		receiveImagingOrganizationAs: 'http://www.ramsoft.com/fhir/dicomserverconfig/receiveImagingOrganizationAs',
		managingOrganization: 'http://www.ramsoft.com/fhir/dicomserverconfig/managingorganization',
		role: 'http://www.ramsoft.com/fhir/StructureDefinition/role',
		features: 'http://www.ramsoft.com/fhir/dicomserverconfig/features',
		feature: 'feature',
		archiveServer: 'http://www.ramsoft.com/fhir/dicomserverconfig/archiveserver',
		hideRejectedInstances: 'http://www.ramsoft.com/fhir/dicomserverconfig/hiderejectedinstances',
		needIOCMNotification: 'http://www.ramsoft.com/fhir/dicomserverconfig/neediocmnotification',
		dicomServerPort: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomserverport',
		dicomTimeout: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomtimeout',
		dicomMaxPDUSize: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicommaxpdusize',
		compression1: 'http://www.ramsoft.com/fhir/dicomserverconfig/compression1',
		compression2: 'http://www.ramsoft.com/fhir/dicomserverconfig/compression2',
		compression3: 'http://www.ramsoft.com/fhir/dicomserverconfig/compression3',
		jpegquality: 'http://www.ramsoft.com/fhir/dicomserverconfig/jpegquality',
		tlsConnection: 'http://www.ramsoft.com/fhir/dicomserverconfig/tlsconnection',
		dicomServerTLSPort: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomservertlsport',
		dicomServerHostName: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomserverhostname',
		receiveStudyAs: 'http://www.ramsoft.com/fhir/dicomserverconfig/receivestudyas',
		forceOverrideStatus: 'http://www.ramsoft.com/fhir/dicomserverconfig/forceoverridestatus',
		receiveTimeout: 'http://www.ramsoft.com/fhir/dicomserverconfig/receiveTimeout',
		dicomscript: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomscript',
		dicomscriptdraft: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomscriptdraft',
		dicomscriptdraftlastupdated: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomscriptdraftlastupdated',
		dicomscriptdraftlastupdatedUser:
			'http://www.ramsoft.com/fhir/dicomserverconfig/dicomscriptdraftlastupdatedUser',
		description: 'http://www.ramsoft.com/fhir/dicomserverconfig/description',
		dicomscriptnote: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomscriptnote',
		enableHeartbeat: 'enableHeartbeat',
		dicomRetrieveSupportCGet: 'dicomRetrieveSupportCGet',
		isActive: 'isActive',
		characterSet: 'characterSet',
		alert: {
			users: 'http://www.ramsoft.com/fhir/device/alertusers',
			notificationsentdatetimeutc: 'http://www.ramsoft.com/fhir/device/alertnotificationsentdatetimeutc',
			notificationintervalminutes: 'http://www.ramsoft.com/fhir/device/alertnotificationintervalminutes',
			reason: 'http://www.ramsoft.com/fhir/device/alertreason',
		},
		dicomweb: {
			featuresend: 'http://www.ramsoft.com/fhir/dicomserverconfig/featuresend',
			featureretrieve: 'http://www.ramsoft.com/fhir/dicomserverconfig/featureretrieve',
			searchuri: 'http://www.ramsoft.com/fhir/dicomserverconfig/searchuri',
			retrieveuri: 'http://www.ramsoft.com/fhir/dicomserverconfig/retrieveuri',
			coerceinfo: 'http://www.ramsoft.com/fhir/dicomserverconfig/coerceinfo',
			storeuri: 'http://www.ramsoft.com/fhir/dicomserverconfig/storeuri',
			authenticationtype: 'http://www.ramsoft.com/fhir/dicomserverconfig/authenticationtype',
			username: 'http://www.ramsoft.com/fhir/dicomserverconfig/username',
			tokenuri: 'http://www.ramsoft.com/fhir/dicomserverconfig/tokenuri',
			claimsissuer: 'http://www.ramsoft.com/fhir/dicomserverconfig/claimsissuerdcweb',
			jwtsigningalgorithm: 'http://www.ramsoft.com/fhir/dicomserverconfig/jwtsigningalgorithm',
			scopes: 'http://www.ramsoft.com/fhir/dicomserverconfig/scopes',
			authorizationuri: 'http://www.ramsoft.com/fhir/dicomserverconfig/authorizationuri',
			clientid: 'http://www.ramsoft.com/fhir/dicomserverconfig/clientiddcweb',
			dicomclientsecret: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomclientsecret',
			dicomprivatekey: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomprivatekey',
			dicompassword: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicompassword',
			dicomStationStudyStatus: 'http://www.ramsoft.com/fhir/dicomserverconfig/dicomStationStudyStatus',
			dicomStationIssuerOfPatientId:
				'http://www.ramsoft.com/fhir/dicomserverconfig/dicomStationIssuerOfPatientId',
		},
		deviceStatus: 'http://www.ramsoft.com/fhir/dicomserverconfig/deviceStatus',
	},
	diagnosisReport: {
		verificationFlag: 'http://www.ramsoft.com/fhir/StructureDefinition/VerificationFlag',
		documentType: 'http://www.ramsoft.com/fhir/StructureDefinition/DocumentType',
		documentTypeID: 'http://www.ramsoft.com/fhir/StructureDefinition/DocumentTypeID',
		isPatientLevel: 'http://www.ramsoft.com/fhir/StructureDefinition/IsPatientLevel',
		isPrior: 'http://www.ramsoft.com/fhir/StructureDefinition/IsPrior',
		studyInstanceUID: 'http://www.ramsoft.com/fhir/StructureDefinition/StudyInstanceUID',
		seriesInstanceUID: 'http://www.ramsoft.com/fhir/StructureDefinition/SeriesInstanceUID',
		sopInstanceUID: 'http://www.ramsoft.com/fhir/StructureDefinition/SOPInstanceUID',
		studyDateTime: 'http://www.ramsoft.com/fhir/StructureDefinition/StudyDateTime',
		studyDescription: 'http://www.ramsoft.com/fhir/StructureDefinition/StudyDescription',
		fileKind: 'http://www.ramsoft.com/fhir/StructureDefinition/FileKind',
		isDistributed: 'http://www.ramsoft.com/fhir/StructureDefinition/IsDistributed',
		signStatus: 'http://www.ramsoft.com/fhir/StructureDefinition/SignStatus',
		modality: 'http://www.ramsoft.com/fhir/StructureDefinition/Modality',
		bodyPart: 'http://www.ramsoft.com/fhir/StructureDefinition/BodyPart',
		laterality: 'http://www.ramsoft.com/fhir/StructureDefinition/Laterality',
		imagingFacility: 'http://www.ramsoft.com/fhir/StructureDefinition/ImagingFacility',
		readingPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/ReadingPhysician',
		performingPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/PerformingPhysician',
		referringPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/ReferringPhysician',
		reportText: 'http://www.ramsoft.com/fhir/StructureDefinition/reportText',
		originalForm: 'http://www.ramsoft.com/fhir/StructureDefinition/originalForm',
		reportThumbnail: 'http://www.ramsoft.com/fhir/StructureDefinition/reportThumbnail',
		isCritical: 'http://www.ramsoft.com/fhir/StructureDefinition/CriticalFindings',
		peerReview: 'http://www.ramsoft.com/fhir/StructureDefinition/PeerReview',
		markedBy: 'http://www.ramsoft.com/StructureDefinition/MarkedBy',
		fieldChanged: 'http://www.ramsoft.com/fhir/StructureDefinition/fieldChanged',
		radPairReportId: 'http://www.ramsoft.com/fhir/StructureDefinition/integratedLinkedEntityId',
		delayedSignTimestampUTC: 'http://www.ramsoft.com/fhir/StructureDefinition/delayedSignTimestampUTC',
	},
	encounter: {
		hasAppointment: 'http://www.ramsoft.com/fhir/extension/Encounter/HasAppointment',
		accident: 'http://www.ramsoft.com/fhir/extension/encounter/accident',
		accidentNotes: 'http://www.ramsoft.com/fhir/extension/encounter/accidentNotes',
		financialClass: 'http://www.ramsoft.com/fhir/extension/encounter/financialClass',
		clinicalComments: 'http://www.ramsoft.com/fhir/extension/encounter/clinicalComments',
		clinicalHistory: 'http://www.ramsoft.com/fhir/extension/encounter/clinicalHistory',
		planCare: 'http://www.ramsoft.com/fhir/extension/encounter/planCare',
		followupInstructions: 'http://www.ramsoft.com/fhir/extension/encounter/followupInstructions',
		notes: 'http://www.ramsoft.com/fhir/StructureDefinition/notes',
	},
	field: {
		defaultValue: 'defaultValue',
		minLength: 'minLength',
		maxLength: 'maxLength',
		mandatory: 'mandatory',
		enabled: 'enabled',
		hidden: 'hidden',
		mask: 'mask',
		errorMessage: 'errorMessage',
	},
	flag: {
		flagName: 'http://www.ramsoft.com/fhir/StructureDefinition/flagName',
	},
	healthcareService: {
		duration: 'Duration',
		color: 'http://www.ramsoft.com/fhir/extension/healthcareservice/color',
		overBooking: 'http://www.ramsoft.com/fhir/StructureDefinition/overBooking',
		selfScheduling: 'http://www.ramsoft.com/fhir/StructureDefinition/selfScheduling',
		blumeLicense: 'http://www.ramsoft.com/fhir/StructureDefinition/blumeLicense',
		holidays: 'http://www.ramsoft.com/fhir/extension/healthcareservice/holidays',
		holiday: 'http://www.ramsoft.com/fhir/extension/healthcareservice/holiday',
		id: 'id',
		date: 'date',
	},
	imagingStudy: {
		note: 'http://www.ramsoft.com/fhir/extension/imagingstudy/notes',
		requestedProcedureID: 'http://www.ramsoft.com/fhir/extension/imagingstudy/requestedProcedureID',
		studyDateTime: 'http://www.ramsoft.com/fhir/extension/imagingstudy/studyDateTime',
		studyID: 'http://www.ramsoft.com/fhir/extension/imagingstudy/studyId',
		notes: {
			clinical: 'http://www.ramsoft.com/fhir/StructureDefinition/clinical',
			history: 'http://www.ramsoft.com/fhir/StructureDefinition/history',
		},
		facility: 'facility',
		transcriptionist: 'http://www.ramsoft.com/fhir/StructureDefinition/transcriptionist',
		performingPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/performingPhysician',
		technologist: 'http://www.ramsoft.com/fhir/StructureDefinition/technologist',
		accession: 'http://ginormoushospital.org/accession',
		duration: 'http://www.ramsoft.com/fhir/StructureDefinition/duration',
		preparation: 'http://www.ramsoft.com/fhir/StructureDefinition/preparation',
		recovery: 'http://www.ramsoft.com/fhir/StructureDefinition/recovery',
		isCritical: 'http://www.ramsoft.com/fhir/StructureDefinition/CriticalFindings',
		markedBy: 'http://www.ramsoft.com/fhir/StructureDefinition/MarkedBy',
		studyShare: {
			practioner: 'http://www.ramsoft.com/fhir/StructureDefinition/sharePractioner',
			privilege: 'http://www.ramsoft.com/fhir/StructureDefinition/sharePrivilege',
		},
		outstandingBalance: 'outstandingBalance',
		oldStudyComments: 'studyComment', // TODO: In the future, we should delete this URL and keep the new one "studyComments"
		studyComments: 'http://www.ramsoft.com/fhir/StructureDefinition/comments',
		signedDateTimeUtc: 'http://www.ramsoft.com/fhir/extension/signedDateTimeUTC',
		signer: 'http://www.ramsoft.com/fhir/extension/signer',
		rejectionReason: 'http://www.ramsoft.com/fhir/extension/imagingstudy/rejectionReasons',
		chargePostingState: 'http://www.ramsoft.com/fhir/extension/chargepostingstate',
		chargePosted: 'IsPosted',
	},
	imagingStudyWorklist: {
		lateralityList: 'http://www.ramsoft.com/fhir/StructureDefinition/LateralityList',
		orderCustomField1: 'http://www.ramsoft.com/fhir/StructureDefinition/ordercustomfield1',
		orderCustomField2: 'http://www.ramsoft.com/fhir/StructureDefinition/ordercustomfield2',
		orderCustomMemo1: 'http://www.ramsoft.com/fhir/StructureDefinition/ordercustommemo1',
		studyCustomField1: 'http://www.ramsoft.com/fhir/StructureDefinition/studycustomfield1',
		studyCustomField2: 'http://www.ramsoft.com/fhir/StructureDefinition/studycustomfield2',
		studyCustomMemo1: 'http://www.ramsoft.com/fhir/StructureDefinition/studycustommemo1',
		seriesInstanceUID: 'http://www.ramsoft.com/fhir/StructureDefinition/SeriesInstanceUID',
		sopInstanceUID: 'http://www.ramsoft.com/fhir/StructureDefinition/SOPInstanceUID',
	},
	invoice: {
		remainingDeductible: 'http://www.ramsoft.com/fhir/extension/remainingDeductible',
		coPayment: 'http://www.ramsoft.com/fhir/extension/coPayment',
		coInsurance: 'http://www.ramsoft.com/fhir/extension/coInsurance',
		outOfPocket: 'http://www.ramsoft.com/fhir/extension/outOfPocket',
	},
	license: {
		id: 'id',
		licenseID: 'LicenseID',
		state: 'State',
		country: 'Country',
		specialty: 'Specialty',
		dea: 'DEA',
		effectiveFrom: 'EffectiveFrom',
		effectiveTo: 'EffectiveTo',
	},

	organization: {
		address: 'http://www.ramsoft.com/fhir/extension/organization/address',
		isManaging: 'http://www.ramsoft.com/fhir/extension/organization/isManaging',
		practiceType: 'http://www.ramsoft.com/fhir/extension/organization/practiceType',
		organizationType: 'http://www.ramsoft.com/fhir/extension/organization/organizationType',
		managingOrganization: 'http://www.ramsoft.com/fhir/extension/organization/managingOrganization',
		imagingOrganization: 'http://www.ramsoft.com/fhir/extension/organization/imagingOrganization',
		linkedOrganization: 'http://www.ramsoft.com/fhir/StructureDefinition/linkedOrganization',
		parentLinkedOrganization: 'http://www.ramsoft.com/fhir/StructureDefinition/parentLinkedOrganization',
		organizationAffiliatedTo: 'http://www.ramsoft.com/fhir/StructureDefinition/organizationAffiliatedTo',
		workflowScript: 'http://www.ramsoft.com/fhir/extension/organization/workflowScript',
		workflowScriptDraft: 'http://www.ramsoft.com/fhir/extension/organization/workflowScriptDraft',
		workflowScriptDraftLastUpdated:
			'http://www.ramsoft.com/fhir/extension/organization/workflowScriptDraftLastUpdated',
		workflowScriptDraftLastUpdatedUser:
			'http://www.ramsoft.com/fhir/extension/organization/workflowScriptDraftLastUpdatedUser',
		importScript: 'http://www.ramsoft.com/fhir/extension/organization/importScript',
		importScriptDraft: 'http://www.ramsoft.com/fhir/extension/organization/importScriptDraft',
		importScriptDraftLastUpdated: 'http://www.ramsoft.com/fhir/extension/organization/importScriptDraftLastUpdated',
		importScriptDraftLastUpdatedUser:
			'http://www.ramsoft.com/fhir/extension/organization/importScriptDraftLastUpdatedUser',
		distributeToFacilityOnly: 'http://www.ramsoft.com/fhir/StructureDefinition/distributeToFacilityOnly',
		distributeToParentOrganization:
			'http://www.ramsoft.com/fhir/StructureDefinition/distributeToParentOrganization',
		distributeToPractitioner: 'http://www.ramsoft.com/fhir/StructureDefinition/distributeToPractitioner',
		inheritFromParentOrganization: 'http://www.ramsoft.com/fhir/StructureDefinition/inheritFromParentOrganization',
		inheritStudyTypeFromParentOrganization:
			'http://www.ramsoft.com/fhir/StructureDefinition/inheritStudyTypeFromParentOrganization',
		preliminary: 'http://www.ramsoft.com/fhir/StructureDefinition/preliminary',
		image: 'http://www.ramsoft.com/fhir/StructureDefinition/image',
		final: 'http://www.ramsoft.com/fhir/StructureDefinition/final',
		notes: 'http://www.ramsoft.com/fhir/StructureDefinition/notes',
		contactName: 'http://www.ramsoft.com/fhir/StructureDefinition/contactName',
		isDefault: 'http://www.ramsoft.com/fhir/StructureDefinition/isDefault',
		billingPayerID: 'http://www.ramsoft.com/fhir/StructureDefinition/billingPayerID',
		payerID: 'http://www.ramsoft.com/fhir/StructureDefinition/payerID',
		payerType: 'http://www.ramsoft.com/fhir/StructureDefinition/payerType',
		carrieId: 'http://www.ramsoft.com/fhir/StructureDefinition/carrieId',
		eligibilityPayerID: 'http://www.ramsoft.com/fhir/StructureDefinition/eligibilityPayerID',
		reportTemplate: 'http://www.ramsoft.com/fhir/StructureDefinition/reportTemplate',
		reportTemplateId: 'http://www.ramsoft.com/fhir/StructureDefinition/reportTemplateId',
		billingPayerIDRequired: 'http://www.ramsoft.com/fhir/StructureDefinition/billingPayerIDRequired',
		financialClass: 'http://www.ramsoft.com/fhir/StructureDefinition/financialClass',
		financialType: 'http://www.ramsoft.com/fhir/StructureDefinition/financialType',
		practiceType2: 'http://www.ramsoft.com/fhir/StructureDefinition/practiceType',
		taxId: 'http://www.ramsoft.com/fhir/StructureDefinition/taxId',
		url: 'http://www.ramsoft.com/fhir/StructureDefinition/url',
		timezone: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
		ianaTimezone: 'http://www.ramsoft.com/fhir/StructureDefinition/timezoneIANA',
		issuer: 'http://www.ramsoft.com/fhir/StructureDefinition/issuer',
		defaultIssuer: 'http://www.ramsoft.com/fhir/StructureDefinition/defaultIssuer',
		issuers: 'http://www.ramsoft.com/fhir/StructureDefinition/issuers',
		isExternalIssuer: 'http://www.ramsoft.com/fhir/StructureDefinition/isExternalIssuer',
		mask: 'http://www.ramsoft.com/fhir/StructureDefinition/mask',
		telecomPurpose: 'http://www.ramsoft.com/fhir/StructureDefinition/telecomPurpose',
		fax: 'http://www.ramsoft.com/fhir/StructureDefinition/fax',
		zipCode: 'http://www.ramsoft.com/fhir/StructureDefinition/zipCode',
		ssn: 'http://www.ramsoft.com/fhir/StructureDefinition/ssn',
		phone: 'http://www.ramsoft.com/fhir/StructureDefinition/phone',
		home: 'http://www.ramsoft.com/fhir/StructureDefinition/home',
		bussiness: 'http://www.ramsoft.com/fhir/StructureDefinition/bussiness',
		cell: 'http://www.ramsoft.com/fhir/StructureDefinition/cell',
		roleName: 'http://www.ramsoft.com/fhir/StructureDefinition/defaultRole',
		grantedOrganizationJson: 'http://www.ramsoft.com/fhir/StructureDefinition/grantedOrganization',
		detail: 'detail',
		inheritStudyStatus: 'http://www.ramsoft.com/fhir/extension/inheritStudyStatus',
		studyStatusList: 'http://www.ramsoft.com/fhir/extension/studyStatusList',
		workflowStepList: 'http://www.ramsoft.com/fhir/extension/workflowStepList',
		workflowStep: 'http://www.ramsoft.com/fhir/extension/workflowStep',
		IDPLastSuccessfulConnection: 'http://www.ramsoft.com/fhir/extension/organization/IDPLastSuccessfulConnection',
		IDPLastSuccessfulConnectionDomain:
			'http://www.ramsoft.com/fhir/extension/organization/IDPLastSuccessfulConnectionDomain',
		DomainName: 'http://www.ramsoft.com/fhir/extension/organization/DomainName',
		TilledAccountId: 'http://www.ramsoft.com/fhir/extension/organization/TilledAccountId',
		networkInOut: 'http://www.ramsoft.com/fhir/StructureDefinition/networkInOut',
		payerSource: 'http://www.ramsoft.com/fhir/StructureDefinition/PayerSource',
		shippingAddress: 'http://www.ramsoft.com/fhir/extension/shippingAddress',
		shippingCity: 'http://www.ramsoft.com/fhir/extension/shippingCity',
		shippingProvince: 'http://www.ramsoft.com/fhir/extension/shippingProvince',
		shippingPostalCode: 'http://www.ramsoft.com/fhir/extension/shippingPostalCode',
		shippingCountry: 'http://www.ramsoft.com/fhir/extension/shippingCountry',
		missingOrganization: 'http://www.ramsoft.com/fhir/StructureDefinition/MissingOrganization',
		fromParentLink: 'http://www.ramsoft.com/fhir/StructureDefinition/fromParentLink',
		reportSignDelaySettings: 'http://www.ramsoft.com/fhir/StructureDefinition/reportSignDelaySettings',
		bookingUrl: 'http://www.ramsoft.com/fhir/extension/organization/bookingUrl',
		bookingPhone: 'http://www.ramsoft.com/fhir/StructureDefinition/bookingPhone',
		timezoneIANAExtUrl: 'http://www.ramsoft.com/fhir/StructureDefinition/timezoneIANA',
		deepLink: 'http://www.ramsoft.com/fhir/StructureDefinition/deepLink',
	},
	organizationAffiliation: {
		studyStatusFrom: 'http://www.ramsoft.com/fhir/StructureDefinition/studyStatusFrom',
		studyStatusTo: 'http://www.ramsoft.com/fhir/StructureDefinition/studyStatusTo',
	},
	patientDocumentReference: {
		documentType: 'http://www.ramsoft.com/fhir/StructureDefinition/DocumentType',
		docTypeID: 'http://www.ramsoft.com/fhir/StructureDefinition/DocumentTypeID',
		conceptNameCode: 'http://www.ramsoft.com/fhir/StructureDefinition/CoceptNameCode',
		reportNumber: 'http://www.ramsoft.com/fhir/StructureDefinition/ReportNumber',
		reportDateTime: 'http://www.ramsoft.com/fhir/StructureDefinition/ReportDateTime',
	},
	patient: {
		mothersMaidenName: 'http://www.ramsoft.com/fhir/StructureDefinition/patient-mothersMaidenName',
		employer: 'http://www.ramsoft.com/fhir/StructureDefinition/employer',
		employmentStatus: 'http://www.ramsoft.com/fhir/StructureDefinition/employmentstatus',
		definitions: 'http://www.ramsoft.com/fhir/StructureDefinition/definitions',
		birthSex: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
		race: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
		ethnicity: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
		matchGrade: 'http://www.ramsoft.com/fhir/StructureDefinition/match-grade',
		originalIssuerOfPatientID: 'http://www.ramsoft.com/fhir/StructureDefinition/originalIssuerOfPatientID',
		originalPatientID: 'http://www.ramsoft.com/fhir/StructureDefinition/originalPatientID',
		linkId: 'http://www.ramsoft.com/fhir/extension/patient/linkId',
		specialcourtesy: 'http://www.ramsoft.com/fhir/StructureDefinition/specialcourtesy',
		notes: 'http://www.ramsoft.com/fhir/extension/patient/notes',
		encounterClass: 'http://www.ramsoft.com/fhir/extension/patient/encounterClass',
		changeRequestStatus: 'http://hl7.org/fhir/us/core/StructureDefinition/change-request-status',
		inviteSentTime: 'http://www.ramsoft.com/fhir/extension/patient/inviteSentTime',
		inviteStatus: 'http://www.ramsoft.com/fhir/extension/patient/inviteStatus',
		blumePatient: 'http://www.ramsoft.com/fhir/extension/patient/blumePatient',
	},
	practitioner: {
		configs: 'http://www.ramsoft.com/fhir/extension/practitioner/configs',
		configsMenuToolbar: 'menuToolbar',
		configsLeftPanel: 'leftPanel',
		configsToggleWorklistGrids: 'toggleWorklistGrids',
		configsSummaryFields: 'summaryFields',
		configsWorklist: 'worklist',
		configsPerspectiveConfiguration: 'perspectiveConfiguration',
		configsPinSection: 'pinSection',
		configsSchedulerPerspective: 'schedulerPerspective',
		userType: 'http://www.ramsoft.com/fhir/extension/practitioner/usertype',
		userTypeIsSyswideAdmin: 'isSyswideAdmin',
		userTypeIsAdmin: 'isAdmin',
		userTypeIsReadingPhysician: 'isReadingPhysician',
		userTypeIsReferringPhysician: 'isReferringPhysician',
		userTypeIsPerformingPhysician: 'isPerformingPhysician',
		userTypeIsPerformingTechnologist: 'isPerformingTechnologist',
		userTypeIsTranscriptionist: 'isTranscriptionist',
		canEmergencyAccess: 'http://www.ramsoft.com/fhir/extension/practitioner/canEmergencyAccess',
		isUserActive: 'http://www.ramsoft.com/fhir/extension/practitioner/isUserActive',
		isRoleActive: 'http://www.ramsoft.com/fhir/extension/practitioner/isRoleActive',
		role: 'http://www.ramsoft.com/fhir/extension/practitioner/Role',
		group: 'http://www.ramsoft.com/fhir/extension/practitioner/Group',
		intergation: 'http://www.ramsoft.com/fhir/extension/practitioner/Integration',
		note: 'http://www.ramsoft.com/fhir/extension/practitioner/note',
		license: 'http://www.ramsoft.com/fhir/extension/practitioner/license',
		externalAuthentication: 'http://www.ramsoft.com/fhir/extension/practitioner/ExternalAuthentication',
		loginName: 'http://www.ramsoft.com/fhir/extension/practitioner/LoginName',
		readingPhysician: 'http://www.ramsoft.com/fhir/extension/practitioner/ReadingPhysician',
		reportingDetail: 'http://www.ramsoft.com/fhir/extension/practitioner/ReportingDetails',
		auth0UserID: 'http://www.ramsoft.com/fhir/extension/practitioner/Auth0UserID',
		reportingDetails: {
			preliminary: 'Preliminary',
			final: 'final',
			image: 'Image',
		},
		reportSignature: 'http://www.ramsoft.com/fhir/extension/practitioner/reportSignature',
		noSoundex: 'http://www.ramsoft.com/fhir/extension/practitioner/noSoundex',
		imageViewerUrl: 'http://www.ramsoft.com/fhir/extension/practitioner/imageviewerurl',
		documentViewerUrl: 'http://www.ramsoft.com/fhir/extension/practitioner/documentviewerurl',
		monitorSetupUrl: 'http://www.ramsoft.com/fhir/extension/practitioner/monitorsetup',
		invitationStatus: 'http://www.ramsoft.com/fhir/extension/practitioner/invitationStatus',
		invitationSendTime: 'http://www.ramsoft.com/fhir/extension/practitioner/invitationSendTime',
		invitationDeliveryTime: 'http://www.ramsoft.com/fhir/extension/practitioner/invitationDeliveryTime',
		invitationAccountCreateTime: 'http://www.ramsoft.com/fhir/extension/practitioner/invitationAccountCreateTime',
		invitationBlockTime: 'http://www.ramsoft.com/fhir/extension/practitioner/invitationBlockTime',
		visibleHours: 'http://www.ramsoft.com/fhir/extension/practitioner/visibleHours',
		lastOrderOrganization: 'http://www.ramsoft.com/fhir/extension/practitioner/lastOrderOrganization',
		referringMarketer: 'http://www.ramsoft.com/fhir/extension/practitioner/referringMarketer',
	},
	practitionerRole: {
		loginName: 'http://www.ramsoft.com/fhir/StructureDefinition/loginName',
		npi: 'http://www.ramsoft.com/fhir/StructureDefinition/practitionerNpi',
		userID: 'http://www.ramsoft.com/fhir/StructureDefinition/organizationUserId',
		role: 'http://www.ramsoft.com/fhir/StructureDefinition/role',
		isImaging: 'http://www.ramsoft.com/fhir/extension/practitioner/isImaging',
		isReferring: 'http://www.ramsoft.com/fhir/extension/practitioner/isReferring',
		studyPlayerType: 'http://www.ramsoft.com/fhir/extension/practitioner/studyPlayerType',
		telecom: 'http://www.ramsoft.com/fhir/extension/practitioner/telecom',
		lastLoginTime: 'http://www.ramsoft.com/fhir/extension/practitioner/lastLoginTime',
	},
	procedureCode: {
		modifier: 'http://www.ramsoft.com/fhir/StructureDefinition/modifier',
		quantity: 'http://www.ramsoft.com/fhir/StructureDefinition/quantity',
		authorizationnumbers: 'http://www.ramsoft.com/fhir/StructureDefinition/authorizationnumbers',
		mapId: 'http://www.ramsoft.com/fhir/procedureCode/mapId',
		preAuth: 'http://www.ramsoft.com/fhir/procedureCode/preAuth',
	},
	procedureRequest: {
		cancellationReason: 'http://www.ramsoft.com/fhir/StructureDefinition/cancellationReason',
		transport: 'http://www.ramsoft.com/fhir/StructureDefinition/transport',
		cdsAppropriate: 'http://www.ramsoft.com/fhir/StructureDefinition/cds-appropriate',
		cdsAppropriateUseCriteria: 'http://www.ramsoft.com/fhir/StructureDefinition/cds-appropriateUseCriteria',
		cdsId: 'http://www.ramsoft.com/fhir/StructureDefinition/cds-id',
		cdsComments: 'http://www.ramsoft.com/fhir/StructureDefinition/cds-comments',
		eligibility: 'http://www.ramsoft.com/fhir/StructureDefinition/eligibility',
		eligibilityPrimaryPayerNotes: 'http://www.ramsoft.com/fhir/StructureDefinition/eligibility-primaryPayerNotes',
		eligibilitySecondaryPayerNotes:
			'http://www.ramsoft.com/fhir/StructureDefinition/eligibility-secondaryPayerNotes',
		consultingPhysician: 'http://www.ramsoft.com/fhir/StructureDefinition/consulting-physician',
		internalStudyTypeId: 'http://www.ramsoft.com/fhir/StructureDefinition/internalStudyTypeId',
		studyDateTime: 'http://www.ramsoft.com/fhir/StructureDefinition/studyDateTime',
		studyStatus: 'http://www.ramsoft.com/fhir/StructureDefinition/studyStatus',
		modality: 'http://www.ramsoft.com/fhir/StructureDefinition/modality',
		laterality: 'http://www.ramsoft.com/fhir/StructureDefinition/laterality',
		requestedProcedureId: 'http://www.ramsoft.com/fhir/StructureDefinition/requestedProcedureId',
		imagingFacility: 'http://www.ramsoft.com/fhir/StructureDefinition/imagingFacility',
		managingOrganization: 'http://www.ramsoft.com/fhir/StructureDefinition/managingOrganization',
		detailed: 'detailed',
		walkIn: 'http://www.ramsoft.com/fhir/StructureDefinition/walkIn',
		studyInstanceUid: 'http://www.ramsoft.com/fhir/StructureDefinition/studyInstanceUid',
		enteredBy: 'http://www.ramsoft.com/fhir/StructureDefinition/enteredBy',
		requestingService: 'http://www.ramsoft.com/fhir/StructureDefinition/requestingService',
		phone: 'http://www.ramsoft.com/fhir/StructureDefinition/phone',
		studiesPreauth: 'http://www.ramsoft.com/fhir/StructureDefinition/studiesPreauth',
		studyPreauth: 'http://www.ramsoft.com/fhir/StructureDefinition/studyPreauth',
		preauth: 'http://www.ramsoft.com/fhir/StructureDefinition/preauth',
	},
	task: {
		userName: 'http://www.ramsoft.com/fhir/StructureDefinition/UserName',
		forAeTitle: 'http://www.ramsoft.com/fhir/StructureDefinition/ForAETitle',
		peerHost: 'http://www.ramsoft.com/fhir/StructureDefinition/PeerHost',
		peerPort: 'http://www.ramsoft.com/fhir/StructureDefinition/PeerPort',
		useTls: 'http://www.ramsoft.com/fhir/StructureDefinition/UseTLS',
		ownerId: 'http://www.ramsoft.com/fhir/StructureDefinition/OwnerID',
		numWarning: 'http://www.ramsoft.com/fhir/StructureDefinition/NumWarning',
		numError: 'http://www.ramsoft.com/fhir/StructureDefinition/NumError',
		numFailures: 'http://www.ramsoft.com/fhir/StructureDefinition/NumFailures',
		numofObjects: 'http://www.ramsoft.com/fhir/StructureDefinition/NumofObjects',
		numofRemaining: 'http://hl7.org/fhir/us/core/StructureDefinition/NumofRemaining',
		patientID: 'http://www.ramsoft.com/fhir/extension/PatientID',
		patient: 'http://www.ramsoft.com/fhir/extension/Patient',
		loginUserID: 'http://www.ramsoft.com/fhir/extension/LoginUserID',
		loginIpAddress: 'http://www.ramsoft.com/fhir/extension/LoginIpAddress',
		loginSessionID: 'http://www.ramsoft.com/fhir/extension/SessionID',
		deviceClientID: 'http://www.ramsoft.com/fhir/extension/DeviceClientID',
		deviceType: 'http://www.ramsoft.com/fhir/extension/DeviceType',
		issuer: 'issuer',
		studyUID: 'StudyInstanceUID',
		seriesUID: 'https://www.ramsoft.com/fhir/extension/SeriesUID',
		sopInstanceUID: 'https://www.ramsoft.com/fhir/extension/SOPInstanceUID',
		localFileID: 'https://www.ramsoft.com/fhir/extension/LocalFileID',
		studyDateTime: 'StudyDateTime',
		studyDescription: 'StudyDescription',
		imagingOrganization: 'ImagingOrganization',
		numCompletedSuboperation: 'http://www.ramsoft.com/fhir/extension/numCompletedSuboperation',
		numFailedSuboperation: 'http://www.ramsoft.com/fhir/extension/numFailedSuboperation',
		numWarningSuboperation: 'http://www.ramsoft.com/fhir/extension/numWarningSuboperation',
		signalRSubscription: 'http://www.ramsoft.com/fhir/extension/signalRSubscription',
		organization: 'http://www.ramsoft.com/fhir/extension/organization',
		receiverUserNames: 'http://www.ramsoft.com/fhir/extension/ReceiverUserNames',
		senderUserName: 'http://www.ramsoft.com/fhir/extension/SenderUserName',
		notificationType: 'http://www.ramsoft.com/fhir/extension/NotificationType',
		description: 'http://www.ramsoft.com/fhir/extension/Description',
		trigger: 'http://www.ramsoft.com/fhir/extension/Trigger',
		failureReason: 'http://www.ramsoft.com/fhir/extension/FailureReason',
		message: 'http://www.ramsoft.com/fhir/extension/Message',
	},
	valueSet: {
		InCatalog: 'InCatalog',
		CatalogList: 'CatalogList',
	},
	subscription: {
		subType: 'http://www.ramsoft.com/fhir/StructureDefinition/SubType',
		SubscriptionUID: 'http://www.ramsoft.com/fhir/extension/SubscriptionUID',
	},
	chargeItem: {
		procedureChargeAmount: 'http://www.ramsoft.com/fhir/extension/procedureChargeAmount',
		procedureAllowedAmount: 'http://www.ramsoft.com/fhir/extension/procedureAllowedAmount',
		authorizationNumber: 'http://www.ramsoft.com/fhir/extension/authorizationNumber',
		authorizationStartDate: 'http://www.ramsoft.com/fhir/extension/authorizationStartDate',
		authorizationEndDate: 'http://www.ramsoft.com/fhir/extension/authorizationEndDate',
		occurrenceDateTimeUTC: 'http://www.ramsoft.com/fhir/extension/occurrenceDateTimeUTC',
		feeScheduleName: 'http://www.ramsoft.com/fhir/extension/feeScheduleName',
	},
	allergyIntolerance: {
		severity: 'http://www.ramsoft.com/fhir/extension/allergyIntolerance/severity',
	},
	role: {
		userCount: 'http://www.ramsoft.com/fhir/extension/userCount',
	},
	studyType: {
		bodyPart: 'http://hl7.org/fhir/us/core/StructureDefinition/bodyPart',
	},
	feeSchedules: {
		feeScheduleDetailId: 'http://www.ramsoft.com/fhir/extension/feeSchedule/feeScheduleDetailID',
		internalProcedureCodeID: 'http://www.ramsoft.com/fhir/extension/feeSchedule/internalProcedureCodeID',
		combinedModifier: 'http://www.ramsoft.com/fhir/extension/feeSchedule/combinedModifier',
		modalityCode: 'http://www.ramsoft.com/fhir/extension/feeSchedule/modalityCode',
		procedureAllowedAmount: 'http://www.ramsoft.com/fhir/extension/feeSchedule/procedureAllowedAmount',
		procedureChargedAmount: 'http://www.ramsoft.com/fhir/extension/feeSchedule/procedureChargeAmount',
		modalityChargeAmount: 'http://www.ramsoft.com/fhir/extension/feeSchedule/modalityChargeAmount',
		isActive: 'http://www.ramsoft.com/fhir/extension/feeSchedule/isActive',
		startTime: 'http://www.ramsoft.com/fhir/extension/feeSchedule/startTime',
		endTime: 'http://www.ramsoft.com/fhir/extension/feeSchedule/endTime',
		billable: 'http://www.ramsoft.com/fhir/extension/feeSchedule/billable',
		procedureCode: 'http://www.ramsoft.com/fhir/extension/feeSchedule/procedureCode',
		procedureDisplay: 'http://www.ramsoft.com/fhir/extension/feeSchedule/procedureDisplay',
	},
	studyStatus: {
		transitionsByRole: 'http://www.ramsoft.com/fhir/extension/StudyStatus/transitionsByRole',
		statusTransition: 'http://www.ramsoft.com/fhir/extension/StudyStatus/statusTransition',
		statusId: 'http://www.ramsoft.com/fhir/extension/StudyStatus/Id',
	},
	studyPriority: {
		customPriority: 'http://www.ramsoft.com/fhir/StructureDefinition/customPriority',
		color: 'http://www.ramsoft.com/fhir/StructureDefinition/priorityColor',
		icon: 'http://www.ramsoft.com/fhir/StructureDefinition/priorityIcon',
	},
	customFields: {
		numDocuments: 'NumDocuments',
	},
};

export default fhirExtensionUrls;
