import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Close from '@mui/icons-material/Close';
import CropFreeOutlined from '@mui/icons-material/CropFreeOutlined';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import RotateLeftOutlined from '@mui/icons-material/RotateLeftOutlined';
import Send from '@mui/icons-material/Send';
import Stop from '@mui/icons-material/Stop';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import PropTypes from 'prop-types';
import { searchScopes, sleep, useConfig, useFhirDataLoader, useImportTabContext, useAuth } from '@worklist-2/core/src';
import { useNavigate } from 'react-router-dom';
import { useImportDispatcher } from './useImportDispatcher';
import Draggable from 'react-draggable';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import SnackBarModal from './SnackBarModal';

function ImportOverview({ margin }) {
	const [open, setOpen] = useState(false);
	let [activeDrag, setActiveDrag] = useState(0);
	const [position] = useState({ x: 65, y: -5 });
	const [value, setValue] = useState(0);
	const __config = useConfig();
	const dicomAppURL = __config.data_sources.dicom_web;
	const {
		updateImportSelectedTab,
		updateImportTabs,
		importTabs,
		selectedTab,
		unitTest,
		documentPayload,
		setDocumentPayload,
		updateImportTabByIndex,
		source,
	} = useImportTabContext();
	const { accessToken, sessionId } = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation('importFiles');
	const galaxyDvUploadUiWidgetNew = useBooleanFlagValue('galaxy-dv-upload-ui-widget-new');

	const tabs = useMemo(() => importTabs, [importTabs]);

	const patientDataLoader = useFhirDataLoader({
		scope: searchScopes.patientElk,
	});

	const studyDataLoader = useFhirDataLoader({
		scope: searchScopes.study,
	});

	let importDispatcher = null;

	if (!window.importDispatcher) {
		importDispatcher = useImportDispatcher(studyDataLoader, patientDataLoader, accessToken, sessionId);
		window.importDispatcher = importDispatcher;
	} else {
		importDispatcher = window.importDispatcher;
	}

	useEffect(() => {
		/**
		 * Avoiding the clicking of start button
		 */
		if ((source === 'DocumentViewer' || source === 'PatientDocument') && documentPayload) {
			startImportTab();
		}
	}, [source]);
	useEffect(() => {
		setValue(selectedTab);
	}, [selectedTab]);

	const handleChange = (event, newValue) => {
		updateImportSelectedTab(newValue);
	};

	const getClassName = tab => {
		if (tab.isFailed) {
			return 'warning-icon';
		}
		if (tab.isProgress) {
			return 'teal-icon';
		}
		if (tab.canceled) {
			return 'stop-icon';
		}
		return '';
	};

	const getIcon = tab => {
		if (tab.isFailed) {
			return <WarningAmberOutlined />;
		}
		if (tab.isProgress && !tab.completed) {
			return <CircularProgress />;
		}
		if (tab.canceled) {
			return <Stop />;
		}
		return <Send />;
	};

	const getImportDescription = tab => {
		if (tab.isFailed) {
			const listErr = _.filter(tab.files, file => file.isFailed);
			return `${t('Import failed')} ${listErr.length} file${listErr.length > 1 ? 's' : ''}`;
		}
		if (tab.completed) {
			return `${t('Successfully imported')} ${tab.folders.length} ${t('item')}${
				tab.folders.length > 1 ? 's' : ''
			}`;
		}
		if (tab.isProgress) {
			return `${t('Importing')} ${tab.folders.length} ${t('item')}${tab.folders.length > 1 ? 's' : ''}`;
		}
		return `${tab.folders.length} ${t('item')}${tab.folders.length > 1 ? 's' : ''}`;
	};

	const setFileProgressAndStatus = (progress, tab) => {
		if (progress) {
			progress.forEach(progressFile => {
				tab.files.forEach(file => {
					if (file.path === progressFile.fileName) {
						file.progress = progressFile.progress;
						file.status = progressFile.status;
					}
				});
			});
		} else {
			tab.files.forEach(file => {
				file.progress = 0;
				file.status = null;
			});
		}
	};

	const startImportTab = () => {
		const tab = tabs[value];
		tab.isFailed = false;
		tab.completed = false;
		tab.isProgress = true;
		tab.progress = 0;
		setFileProgressAndStatus(null, tab);
		requestImport(tab);
		uploadedImportTab();
	};

	const requestImport = async nodeList => {
		if (documentPayload) {
			if (documentPayload.type === 'ReportTemplate') {
				await importDispatcher.importNonDicomFiles(nodeList.files, documentPayload.appUrl, null);
			} else if (documentPayload.type === 'PatientDocument') {
				// To upload Patient Document in info cards
				await importDispatcher.importNonDicomFiles(
					nodeList.files,
					documentPayload.appURL,
					documentPayload.resourcePayload
				);
			} else {
				await importDispatcher.importNonDicomFiles(
					nodeList.files,
					`${__config.data_sources.fhir}/fhir?studyuid=${documentPayload?.entry?.[0].resource.extension?.[0]?.valueString}`,
					documentPayload
				);
			}
			setDocumentPayload(null);
		} else {
			const url = `${dicomAppURL}/studies/`;

			//send to import manager to process
			await importDispatcher.importFiles(nodeList, patientDataLoader, studyDataLoader, url);
		}

		const tab = tabs[selectedTab];
		const index = value;
		tab.canceled = false;
		let importing = true;
		let errorMessage = '';
		let uploadStatus = '';

		while (importing) {
			// wait for upload
			if (!unitTest) await sleep(500);
			if (tab.canceled) break;
			tab.completed = false;
			tab.isProgress = true;
			tab.progress = importDispatcher.getTotalProgressBar();
			errorMessage = importDispatcher.getErrorMsg();
			uploadStatus = importDispatcher.getFileUploadStatus();

			if (importDispatcher.getFilesUploadProgress) {
				const fileUploadProgress = [...importDispatcher.getFilesUploadProgress()];
				setFileProgressAndStatus(fileUploadProgress, tab);
			}

			tab.label = `Uploading`;
			updateImportTabByIndex(tab, index);
			importing = importDispatcher.getImportStatus();

			if ((!!uploadStatus && uploadStatus.length > 0) || (!!errorMessage && errorMessage.length > 0)) {
				for (const file of tab.files) {
					const errorMessageItem = _.find(errorMessage, err => err.fileName === file.path);

					if (errorMessageItem) {
						file.isFailed = true;
						file.errorMessage = errorMessageItem.statusText;
					}

					const status = _.find(uploadStatus, item => item.fileName === file.path);
					if (status) {
						file.isUploaded = status.isUploaded;
					}
				}
			}

			//Completed importing
			if (!importing) {
				tab.progress = 100;
			}
		}

		if (tab.progress === 100) {
			if (!!errorMessage && errorMessage.length > 0) {
				tab.isFailed = true;
				tab.label = `Failed`;
			} else {
				const indexUploadStatus = uploadStatus.length ? uploadStatus[index] : {};
				let fileUploadPayload = {};
				if (indexUploadStatus?.messageEvent) {
					try {
						fileUploadPayload = JSON.parse(indexUploadStatus.messageEvent.responseText);
					} catch (err) {
						fileUploadPayload = {};
					}
				}
				tab.isFailed = false;
				tab.label = `Completed`;
				tab.messageEvent = fileUploadPayload;
				/** Closing upload widget on successful upload and source is DocumentViewer */
				if (source === 'DocumentViewer' || source === 'PatientDocument') {
					// Increased timeout duration to prevent the UI widget from closing immediately upon completion of the upload.
					setTimeout(cancelImportSession, galaxyDvUploadUiWidgetNew ? 2000 : 100);
				}
			}
			tab.completed = true;
			tab.isUploaded = true;
			updateImportSelectedTab(index);
			updateImportTabByIndex(tab, index);
		}
	};

	const uploadedImportTab = () => {
		const tab = tabs[value];
		tab.isUploaded = true;
		const newTabs = [...tabs];
		updateImportTabs(newTabs);
	};

	const expandImportList = () => {
		updateImportSelectedTab(value);
		navigate('/import');
	};

	const onStart = () => {
		setActiveDrag(++activeDrag);
	};

	const onStop = () => {
		setActiveDrag(--activeDrag);
	};

	const cancelImportSession = useCallback(() => {
		const newTabs = _.filter(tabs, (tab, i) => i !== (selectedTab || 0));
		const newIndex = selectedTab - 1;
		updateImportTabs(newTabs);
		updateImportSelectedTab(newIndex < 0 ? (_.isEmpty(newTabs) ? null : 0) : newIndex);
	}, [selectedTab, tabs]);

	const stopImportTab = idx => {
		const tabIndex = idx || selectedTab;
		const tab = tabs[tabIndex];
		if (!tab) return;
		tab.canceled = true;
		tab.isProgress = false;
		tab.isUploaded = false;
		tab.isFailed = false;
		tab.progress = 0;
		tab.label = `Stopped`;
		const newTabs = [...tabs];
		updateImportTabs(newTabs);
		importDispatcher.stopImport();
	};

	return (
		<>
			<Draggable bounds="body" defaultPosition={position} onStart={onStart} onStop={onStop}>
				<div className="global-import-overview">
					{galaxyDvUploadUiWidgetNew ? (
						_.map([...tabs], (tab, index) => {
							const fileName = tab.files?.[0]?.folderName || 'File';
							return (
								<SnackBarModal
									key={`upload_${index}`}
									close={() => stopImportTab(index)}
									failed={tab.progress === 100 && tab.isFailed}
									fileName={fileName}
									inprogress={tab.label === 'Uploading'}
									progressCount={tab.progress}
									success={tab.progress === 100 && tab.isUploaded}
									tab={tab}
								/>
							);
						})
					) : (
						<TabContext value={value}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList aria-label="lab API tabs example" onChange={handleChange}>
									{_.map(tabs, (tab, index) => (
										<Tab
											className={getClassName(tab)}
											icon={getIcon(tab)}
											label={
												tab.label === 'Uploading'
													? `${t(tab.label)} - ${tab.progress}%`
													: t(tab.label)
											}
											value={index}
										/>
									))}
								</TabList>
							</Box>
							{_.map(tabs, (tab, index) => (
								<TabPanel className="tab-container" value={index}>
									<div className="import-ready-tab">
										<div className="details">{getImportDescription(tab)}</div>
										<div className="actions">
											{source !== 'DocumentViewer' && (
												<div
													className="icon"
													data-testid="expandImport"
													onClick={expandImportList}
												>
													<CropFreeOutlined />
													{t('View')}
												</div>
											)}
											{(tab.isUploaded || tab.isFailed) && tab.progress === 100 ? (
												<>
													{tab.isFailed && (
														<>
															<div className="icon" onClick={() => setOpen(true)}>
																<DownloadOutlined />
																<span>{t('Error log')}</span>
															</div>
															<div className="border" />
														</>
													)}
													<div
														className="icon"
														id="global-import-overview-close"
														onClick={cancelImportSession}
													>
														<Close />
														{!tabs[selectedTab]?.completed || tabs[selectedTab]?.isFailed
															? t('Cancel')
															: t('Close')}
													</div>
													{tab.isFailed && (
														<div className="icon" onClick={startImportTab}>
															<RotateLeftOutlined />
															<span>{t('Retry Failed')}</span>
														</div>
													)}
												</>
											) : (
												<>
													{tab.isProgress ? (
														<div
															className="icon"
															data-testid="stopImport"
															onClick={stopImportTab}
														>
															<Stop />
															{t('Stop')}
														</div>
													) : (
														<>
															<div
																className="icon"
																data-testid="cancelImport"
																onClick={cancelImportSession}
															>
																<Close />
																{t('Cancel')}
															</div>
															<div
																className="icon"
																data-testid="startImport"
																onClick={startImportTab}
															>
																<Send />
																{t('Start')}
															</div>
														</>
													)}
												</>
											)}
										</div>
									</div>
								</TabPanel>
							))}
						</TabContext>
					)}
				</div>
			</Draggable>

			<Dialog
				aria-describedby="alert-dialog-description"
				aria-labelledby="alert-dialog-title"
				className="import-error-dialog"
				open={open}
				onClose={() => setOpen(false)}
			>
				<DialogTitle id="alert-dialog-title">Errors</DialogTitle>
				<DialogContent>
					{tabs[selectedTab]?.files?.map(file =>
						file.errorMessage ? (
							<div className="import-error-item">
								{file.name} - {file.errorMessage}
							</div>
						) : (
							''
						)
					)}
				</DialogContent>
				<DialogActions>
					<Button className="close-button" onClick={() => setOpen(false)}>
						{t('Close')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

ImportOverview.propTypes = {
	tabs: PropTypes.array,
	selectedTab: PropTypes.object,
};

export default ImportOverview;
