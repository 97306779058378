import React, { createContext, useContext, useState } from 'react';

const ImageViewerPermissionsContext = createContext({});

const ImageViewerPermissionsContextProvider = ({ children }) => {
	const [managingOrgObj, setManagingOrgObj] = useState({});
	const [imageViewerPermission, setImageViewerPermission] = useState({});
	const [patientPermission, setPatientPermission] = useState({});
	const [practitionerRoles, setPractitionerRoles] = useState([]);

	const isViewOnly = Boolean(!imageViewerPermission?.read);

	return (
		<ImageViewerPermissionsContext.Provider
			value={{
				managingOrgObj,
				setManagingOrgObj,
				imageViewerPermission,
				setImageViewerPermission,
				practitionerRoles,
				setPractitionerRoles,
				isViewOnly,
				patientPermission,
				setPatientPermission,
			}}
		>
			{children}
		</ImageViewerPermissionsContext.Provider>
	);
};

const useImageViewerPermissionsContext = () => useContext(ImageViewerPermissionsContext);

export default ImageViewerPermissionsContextProvider;

export { useImageViewerPermissionsContext, ImageViewerPermissionsContext };
