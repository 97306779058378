import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import moment from 'moment-timezone';

import { useConfig, useAuth, getExtensionValueString, fhirExtensionUrls } from '@worklist-2/core/src';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import { BANNER_SELECT_STATUS } from '@worklist-2/patientPortal/src/consts';

import { getPatientFriendlyModality } from '../AppointmentUtils/AppointmentUtils';
import getAddress from '../../../utils/getAddress';
import getOrganizations from '../../../api/getOrganizations';
import getAppointmentsAPICall from '../../../api/getAppointments';

const AppointmentContext = createContext({});

const statusMapping = {
	proposed: 'requested',
	pending: 'pending',
	waitlist: 'pending',
	confirmed: 'scheduled',
	booked: 'scheduled',
	arrived: 'scheduled',
	noshow: 'cancelled',
	cancelled: 'cancelled',
	fulfilled: 'completed',
	'checked-in': 'scheduled',
};

const AppointmentContextProvider = ({ children }) => {
	// Taking fullscreen prop from global context
	const { expanded: isFullscreen, setExpanded: setIsFullscreen } = useChatGlobalContext();

	const __config = useConfig();
	const [isOpen, setIsOpen] = useState('');
	const [oldAppointmentOpen, setOldAppointmentOpen] = useState(false);
	const [selectedDay, setSelectedDay] = React.useState();
	const [today] = useState(new Date());
	const [selectedMonth, setSelectedMonth] = useState(new Date());
	const [appointments, setAppointments] = useState([]);
	const [showLoading, setShowLoading] = useState(true);
	const [appointmentFormUpdated, setAppointmentFormUpdated] = useState({});
	const [studyFormUpdated, setStudyFormUpdated] = useState({});
	const [studyInDetailedView, setStudyInDetailedView] = useState(null);
	const { shouldUpdateNotification } = useAuth();
	const [hasFirstAppointment, setHasFirstAppointment] = useState(false);
	const [document, setDocument] = useState(null);
	const [filter, setFilter] = useState(null);
	const [bannerSelectStatus, setBannerSelectStatus] = useState(BANNER_SELECT_STATUS.COMPLETED);

	useEffect(() => {
		if (shouldUpdateNotification) {
			getAppointments();
		}
	}, [shouldUpdateNotification]);

	const onSelectDay = day => {
		if (differenceInCalendarDays(selectedDay, day) === 0) {
			setSelectedDay();
		} else {
			setSelectedDay(day);
		}
	};

	const updateAppointment = (appointmentId, status) => {
		const updatedAppointment = appointments.map(apt => {
			if (apt.id === appointmentId) {
				return { ...apt, status: statusMapping[status] };
			}
			return apt;
		});
		setAppointments(updatedAppointment);
	};

	const fetchStudyInfo = useCallback((resource, healthCareInfo) => {
		const healthCareServiceId = resource?.participant
			?.find(item => item?.actor?.reference?.toLowerCase().includes('healthcareservice'))
			?.actor.reference.split('/')[1];
		const organizationIANATimeZone =
			healthCareServiceId && healthCareInfo.hasOwnProperty(healthCareServiceId)
				? healthCareInfo[healthCareServiceId]?.timezoneIANA
				: null;
		let organizationTimeZone = '';
		let startTimeInOrgTimeZone = null;
		let endTimeInOrgTimeZone = null;
		// if not user time zone is same as org show timezone and convert to org timzoen
		if (organizationIANATimeZone && Intl.DateTimeFormat().resolvedOptions().timeZone !== organizationIANATimeZone) {
			organizationTimeZone = healthCareInfo[healthCareServiceId].timezone;
			startTimeInOrgTimeZone = moment(new Date(resource.start))
				.tz(organizationIANATimeZone)
				.format('YYYY-MM-DDTHH:mm:ss');
			endTimeInOrgTimeZone = moment(new Date(resource.end))
				.tz(organizationIANATimeZone)
				.format('YYYY-MM-DDTHH:mm:ss');
		}
		const patientFriendlyModality = resource?.extension?.find(item =>
			item?.url?.includes('patientFriendlyModality')
		)?.valueString;
		const modalityFromOAIAppointment = resource?.extension?.find(
			item => item.url === 'modality' && item?.extension?.length
		)?.extension[0]?.valueCoding?.code;
		const attachments = resource?.extension?.find(item => item?.url?.includes('blumeAttachment'))?.extension;
		const appointmentResult = {
			id: resource.id,
			status: statusMapping[resource.status],
			dateStart: startTimeInOrgTimeZone ? new Date(startTimeInOrgTimeZone) : new Date(resource.start),
			dateEnd: endTimeInOrgTimeZone ? new Date(endTimeInOrgTimeZone) : new Date(resource.end),
			internalOrganizationID: '',
			organization: '',
			phone: '',
			address: '',
			description: resource.description,
			resource,
			type: patientFriendlyModality || getPatientFriendlyModality(modalityFromOAIAppointment),
			origin: patientFriendlyModality ? 'Blume' : 'OAI',
			organizationTimeZone,
			attachments,
		};

		if (healthCareServiceId && healthCareInfo.hasOwnProperty(healthCareServiceId)) {
			const healthCareObj = healthCareInfo[healthCareServiceId];
			if (!appointmentResult.type) {
				appointmentResult.type = healthCareObj?.modality
					? getPatientFriendlyModality(healthCareObj.modality)
					: '';
			}
			appointmentResult.organization = healthCareObj.organization;
			appointmentResult.internalOrganizationID = healthCareObj.internalOrganizationID;
			appointmentResult.phone = healthCareObj.phone;
			appointmentResult.address = healthCareObj.address;
			return appointmentResult;
		}

		return appointmentResult;
	}, []);

	const getHealthCareInfo = useCallback(
		healthCareServiceIds => {
			if (healthCareServiceIds) {
				return axios
					.get(
						`${__config.data_sources.blume}Appointment/healthcareService?id=${healthCareServiceIds}&multipleIds=true`
					)
					.then(response => {
						const healthcareInfoDict = {};
						if (response?.data?.entry) {
							response.data.entry.forEach(r => {
								const modalityExt = r.resource?.extension?.find(item => item?.url.includes('modality'));
								healthcareInfoDict[r.resource.id] = {
									modality: modalityExt?.extension ? modalityExt?.extension[0]?.valueCode : null,
									organization: r.resource?.providedBy?.display,
									internalOrganizationID: r.resource?.providedBy?.reference.split('/')[1],
									phone: r.resource?.providedBy?.extension?.find(item => item?.url.includes('phone'))
										?.valueString,
									address: getAddress(
										r.resource?.providedBy?.extension?.find(item => item?.url.includes('address'))
											?.valueAddress || {}
									),
									timezone: getExtensionValueString(
										r?.resource?.providedBy,
										fhirExtensionUrls.organization.timezone
									),
									timezoneIANA: getExtensionValueString(
										r?.resource?.providedBy,
										fhirExtensionUrls.organization.ianaTimezone
									),
								};
							});
						}
						return healthcareInfoDict;
					});
			}
		},
		[__config.data_sources.blume]
	);

	const getAppointments = useCallback(async () => {
		try {
			const res = await getAppointmentsAPICall({ __config });
			if (!res?.entry) {
				setShowLoading(false);
				return;
			}

			const healthCareIds = _.join(
				_.uniq(
					res.entry.map(
						appt =>
							appt.resource?.participant
								?.find(item => item?.actor?.reference?.toLowerCase().includes('healthcareservice'))
								?.actor.reference.split('/')[1]
					)
				).filter(Boolean)
			);

			const healthCareInfo = await getHealthCareInfo(healthCareIds);
			const appointmentResults = res.entry.map(r => fetchStudyInfo(r.resource, healthCareInfo));

			if (appointmentResults?.length > 0) {
				const hasParkedAppointment = appointmentResults.some(appt => {
					const extension = appt?.resource?.extension;
					const parkingIndex = extension?.find(ext => ext.url === fhirExtensionUrls.appointment.parkingIndex);

					return !!parkingIndex;
				});

				if (!hasParkedAppointment) {
					if (appointmentResults.length) {
						setHasFirstAppointment(true);
					}
					setAppointments(appointmentResults);
					setShowLoading(false);
					return;
				}

				const orgIds = appointmentResults.reduce((ids, appointment) => {
					const orgId = appointment?.resource?.extension?.find(
						ext => ext.url === fhirExtensionUrls.common.managingOrganization
					)?.valueString;

					if (orgId && !appointment.status && !ids.includes(orgId)) {
						ids.push(orgId);
					}

					return ids;
				}, []);

				if (!orgIds.length) {
					if (appointmentResults.length) {
						setHasFirstAppointment(true);
					}
					setAppointments(appointmentResults);
					setShowLoading(false);
					return;
				}

				const orgDetails = await getOrgDetails(orgIds.join(', '));

				const mappedAppointment = appointmentResults?.map(appt => {
					const managingOrgId = appt.resource.extension.find(
						ext => ext.url === fhirExtensionUrls.common.managingOrganization
					)?.valueString;

					if (!appt.status && orgDetails) {
						const organization = orgDetails.find(org => org?.id === managingOrgId);

						return {
							...appt,
							organization: organization?.name,
							phone: organization?.phone,
							address: organization?.address,
						};
					}

					return appt;
				});
				if (mappedAppointment.length) {
					setHasFirstAppointment(true);
				}

				setAppointments(mappedAppointment);
			} else {
				if (appointmentResults.length) {
					setHasFirstAppointment(true);
				}
				setAppointments(appointmentResults);
			}

			setShowLoading(false);
		} catch (err) {
			console.error(err);
			setShowLoading(false);
		}
	}, [__config, getHealthCareInfo, fetchStudyInfo, getOrgDetails]);

	const patchAppointment = (id, patchList, cancellationReason = '') =>
		axios
			.patch(
				`${__config.data_sources.blume}Appointment/${id}?cancellationReason=${cancellationReason}`,
				patchList
			)
			.then(resp => {
				if (!!resp && resp.status >= 200 && resp.status < 300) {
					updateAppointment(id, resp?.data?.status);
					return 'SUCCESS';
				}
			});

	const getOrgDetails = useCallback(
		async orgIds => {
			try {
				const data = await getOrganizations({ __config, orgIds });

				if (!data) return null;

				const orgDetails = data?.entry;

				return orgDetails.map(({ resource }) => ({
					id: resource?.id,
					partOf: resource?.partOf?.display,
					address: getAddress(resource?.address[0]),
					name: resource?.name,
					phone: resource?.telecom?.find(item => item.system === 'phone')?.value,
				}));
			} catch (err) {
				console.error(err);
			}
		},
		[__config]
	);

	return (
		<AppointmentContext.Provider
			value={{
				isOpen,
				setIsOpen,
				isFullscreen,
				setIsFullscreen,
				appointments,
				setAppointments,
				selectedDay,
				setSelectedDay: onSelectDay,
				today,
				selectedMonth,
				setSelectedMonth,
				getAppointments,
				patchAppointment,
				showLoading,
				appointmentFormUpdated,
				setAppointmentFormUpdated,
				studyFormUpdated,
				setStudyFormUpdated,
				studyInDetailedView,
				setStudyInDetailedView,
				hasFirstAppointment,
				setHasFirstAppointment,
				filter,
				setFilter,
				document,
				setDocument,
				bannerSelectStatus,
				setBannerSelectStatus,
				oldAppointmentOpen,
				setOldAppointmentOpen,
			}}
		>
			{children}
		</AppointmentContext.Provider>
	);
};

const useAppointmentContext = () => useContext(AppointmentContext);

export { AppointmentContextProvider, useAppointmentContext };
