import { create } from 'zustand';
import commonMiddlewares from '@rs-core/store/middlewares';

export const useHomeFilters = create(
	commonMiddlewares((set, get) => ({
		// State
		homeFilters: { account: [], time: 'ALL' },
		calendarSelectedDay: null,
		calendarSelectedMonth: new Date(),
		dateRangePickerStartDate: null,
		dateRangePickerEndDate: null,
		accounts: [],

		// Setters
		setHomeFilter: filters => set(() => ({ homeFilters: filters })),
		setCalendarSelectedDay: day => set(() => ({ calendarSelectedDay: day })),
		setCalendarSelectedMonth: month => set(() => ({ calendarSelectedMonth: month })),
		setDateRangePickerStartDate: dateRangePickerStartDate => set(() => ({ dateRangePickerStartDate })),
		setDateRangePickerEndDate: dateRangePickerEndDate => set(() => ({ dateRangePickerEndDate })),
		setAccounts: accounts => set(() => ({ accounts })),
		clearFilters: () => {
			const { homeFilters, accounts } = get();

			set(() => ({
				homeFilters: { homeFilters, account: accounts, time: 'ALL' },
				dateRangePickerStartDate: null,
				dateRangePickerEndDate: null,
				calendarSelectedDay: null,
				calendarSelectedMonth: new Date(),
			}));
		},
		clearCustomDateRange: () => {
			set(() => ({
				dateRangePickerStartDate: null,
				dateRangePickerEndDate: null,
				calendarSelectedDay: null,
				calendarSelectedMonth: new Date(),
			}));
		},
	}))
);
