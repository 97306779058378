import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import Box from '@mui/material/Box';
import GroupingDragItem from './GroupingDragItem';
import GroupingIcon from '@worklist-2/ui/src/assets/icons/Worklist/grouping.svg';
import { useWorklist } from '@rs-ui/components/Worklist/Worklist/contexts/WorklistContext';

const GroupingDropable = ({ name }) => {
	const { groupingItems, setGroupingItems } = useWorklist();

	/**
	 * Grouping Droppable
	 */
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: 'COLUMN',
		drop: () => ({ name }),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		// Override monitor.canDrop() function
		canDrop: item => item.groupable,
	});

	/**
	 * Get Background Color
	 */
	const getBackgroundColor = () => (isOver ? (canDrop ? 'rgb(188,251,255)' : 'rgb(255,188,188)') : '');

	/**
	 * Move Grouping Item
	 */
	const moveGroupingItem = (dragIndex, hoverIndex) => {
		const dragItem = groupingItems[dragIndex];

		if (dragItem) {
			setGroupingItems(prevState => {
				const coppiedStateArray = [...prevState];

				// remove item by "hoverIndex" and put "dragItem" instead
				const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

				// remove item by "dragIndex" and put "prevItem" instead
				coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

				return coppiedStateArray;
			});
		}
	};

	/**
	 * Remove Grouping Item
	 */
	const removeGroupingItem = index => {
		const newGroupingItems = groupingItems.filter((item, i) => i !== index);
		setGroupingItems([...newGroupingItems]);
	};

	return (
		<Box
			ref={drop}
			data-testid="worklist-grouping-dropable"
			sx={{
				backgroundColor: getBackgroundColor(),
				height: '100%',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				gap: '8px',
				alignItems: 'center',
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '35px' }}>
				<GroupingIcon />
			</Box>
			{groupingItems?.map((item, index) => (
				<GroupingDragItem
					key={item.id}
					id={item.id}
					index={index}
					label={item.label}
					moveGroupingItem={moveGroupingItem}
					removeGroupingItem={removeGroupingItem}
				/>
			))}
		</Box>
	);
};

GroupingDropable.propTypes = {
	name: PropTypes.string,
};

GroupingDropable.defaultProps = {
	name: '',
};

export default GroupingDropable;
