import React from 'react';
import { Box, Typography } from '@mui/material';
import AddEmptyIcon from '../../../assets/icons/PatientInfoCards/add-note-empty-icon.svg';

const EmptyNoteCard = ({ onClick, t }) => (
	<Box
		sx={{
			border: '1px dashed #FFFFFF66',
			height: '150px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '12px',
			cursor: 'pointer',
		}}
		onClick={onClick}
	>
		<Box
			sx={{
				textAlign: 'center',
			}}
		>
			<AddEmptyIcon />
			<Typography
				sx={{
					fontSize: '14px',
					fontFamily: 'Roboto',
					fontWeight: 400,
					letterSpacing: '0.25px',
					lineHeight: 1.428,
					color: '#FFFFFF99',
				}}
			>
				{t('Add a Note')}
			</Typography>
		</Box>
	</Box>
);

export default EmptyNoteCard;
