import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SearchableMutiSelectCheckboxDropdown({
	label,
	placeholder,
	options,
	defaultValue,
	list,
	index,
	setListOfNotifications,
}) {
	const [selectedOption, setSelectedOption] = React.useState(defaultValue);
	return (
		<Autocomplete
			disableCloseOnSelect
			multiple
			getOptionLabel={option => option.title}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			options={options}
			renderInput={params => (
				<TextField {...params} label={label} placeholder={placeholder} sx={{ margin: '0px' }} />
			)}
			renderOption={(props, option, { selected }) => {
				const { key, ...optionProps } = props;
				return (
					<li key={key} {...optionProps}>
						<Checkbox checked={selected} checkedIcon={checkedIcon} icon={icon} style={{ marginRight: 8 }} />
						{option.title}
					</li>
				);
			}}
			style={{ width: '100%' }}
			value={selectedOption}
			onChange={(event, newValue) => {
				setSelectedOption(newValue);
				const newList = list;
				newList[index].notificationType = newValue.map(item => item.title).join(',');
				setListOfNotifications(newList);
			}}
		/>
	);
}
