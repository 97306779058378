import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FilterPanel from '../FilterPanel';
import GridCount from '../GridCount';
import { Svg } from '@worklist-2/ui/src/components/Svg';
import { Button, Tooltip, Typography, MenuItem, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';

import WorklistSelector from '../../Worklist/WorklistGrid/WorklistSelector';

const GridToolbar = ({
	className,
	sort,
	children,
	capsules,
	totalRows,
	gridName,
	onRemoveCapsule,
	onClearFilters,
	scope,
	filterMenuItems,
	onCapsuleFilter,
	onDownloadClick,
	enableDownloadButton,
	worklistsOfCurrentUser,
	currWorklistId,
	onWorklistSelected,
	onWorklistEdit,
	onAddWorklist,
	showSaveBtn,
	onSaveClicked,
	newWorklistSelector = false,
	columnMapping,
}) => {
	const filterPanelRef = useRef(null);
	const classes = classnames('grid-toolbar', className);
	const [panelWidth, setPanelWidth] = useState(0);
	const capsuleMaxWidth = 340;
	const { t } = useTranslation('gridToolbar');
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e, target) => {
		setAnchorEl(null);
		if (target === 'csv' || target === 'xlsx') {
			onDownloadClick(target);
		}
	};

	const onWindowResize = () => {
		setPanelWidth(filterPanelRef.current ? filterPanelRef.current.offsetWidth : 300);
	};

	useEffect(() => {
		setPanelWidth(filterPanelRef.current ? filterPanelRef.current.offsetWidth : 300);
		window.addEventListener('resize', onWindowResize);

		return () => {
			window.removeEventListener('resize', onWindowResize);
		};
	}, []);

	return (
		<div className={classes} data-testid="grid-toolbar">
			{newWorklistSelector ? (
				<WorklistSelector
					currWorklistId={currWorklistId}
					gridName={gridName}
					showSaveBtn={showSaveBtn}
					worklistsOfCurrentUser={worklistsOfCurrentUser}
					onAddWorklist={onAddWorklist}
					onSaveClicked={onSaveClicked}
					onWorklistEdit={onWorklistEdit}
					onWorklistSelected={onWorklistSelected}
				/>
			) : (
				<div aria-labelledby="gridTitle" className="left-baby" id="gridTitle">
					<label htmlFor="gridTitle">{gridName}</label>
				</div>
			)}
			<div ref={filterPanelRef} className="filter-panel">
				<FilterPanel
					capsuleMaxWidth={capsuleMaxWidth}
					capsules={capsules}
					columnMapping={columnMapping}
					filterMenuItems={filterMenuItems}
					hideDivider={!gridName}
					panelWidth={panelWidth - capsuleMaxWidth}
					scope={scope}
					size="small"
					sort={sort}
					onCapsuleFilter={onCapsuleFilter}
					onClearFilters={onClearFilters}
					onRemoveCapsule={onRemoveCapsule}
				/>
			</div>
			{children}
			{enableDownloadButton && (
				<Tooltip title={t('Download Data')}>
					<Button data-testid="download-grid-button" onClick={handleClick}>
						<Svg
							name="download"
							sx={{
								fontSize: '14px',
							}}
						/>
					</Button>
				</Tooltip>
			)}
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ horizontal: -50, vertical: 30 }}
				id="measures-menu"
				open={Boolean(anchorEl)}
				sx={{
					borderRadius: '6px',
					'& .MuiList-root': {
						background: '#272727',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
						padding: '6px 6px 6px 6px',
					},
					'& .MuiDivider-root': {
						margin: 'unset !important',
					},
					'& .MuiMenuItem-root': {
						padding: '2px',
						width: '80px',
						'&:hover': {
							borderRadius: '6px',
						},
					},
				}}
				onClose={handleClose}
			>
				<MenuItem key="CSV" onClick={e => handleClose(e, 'csv')}>
					<Typography data-testid="download-csv" sx={{ fontSize: '14px', padding: '4px' }}>
						CSV
					</Typography>
				</MenuItem>
				<MenuItem key="XLSX" onClick={e => handleClose(e, 'xlsx')}>
					<Typography data-testid="download-xlsx" sx={{ fontSize: '14px', padding: '4px' }}>
						Excel
					</Typography>
				</MenuItem>
			</Menu>
			<div className="pagination">
				<GridCount count={totalRows} />
			</div>
		</div>
	);
};

GridToolbar.propTypes = {
	/**
	 * A set of capsules to be displayed
	 */
	capsules: PropTypes.any,
	/**
	 * The name of the grid, eg 'Worklist' or 'Audit Log'. Displayed at the top of the grid.
	 */
	gridName: PropTypes.string,
	/**
	 * The number of rows being displayed in the grid. Used to update the GridCount component
	 */
	totalRows: PropTypes.number,
	/**
	 * A callback to be executed when a capsule is removed
	 */
	onRemoveCapsule: PropTypes.func,
	/**
	 * A callback to be executed when filters are cleared
	 */
	onClearFilters: PropTypes.func,
	/**
	 * Search scope to be used for populating filter values
	 */
	scope: PropTypes.string,
	/**
	 * Callback when download grid button is clicked
	 */
	onDownloadClick: PropTypes.func,
	/**
	 * Boolean to enable or disable the download button
	 */
	enableDownloadButton: PropTypes.bool,
};

export default GridToolbar;
