// core
import React from 'react';

// components
import MobileDrawerTitle from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileDrawerTitle';

// MUI
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import use100vh from '@rs-ui/components/Mobile100vh/use100vh';

const MobileBottomDrawer = ({
	open,
	children,
	title,
	handleBackground = 'rgba(18, 18, 18, 0.1)',
	background = '#FFFFFF',
	textAlign = 'center',
	onClose,
	onOpen = () => null,
	sx,
	fullHeight,
	ModalProps,
	contentRef,
	stickyContentTop,
	stickyContentBottom,
	variant,
	icon,
	sxTitle,
	goBack,
}) => {
	const height100vh = use100vh();
	const maxHeight = `${height100vh - 71}px`;

	const PaperProps = {
		sx: {
			background,
			borderTopLeftRadius: '25px',
			borderTopRightRadius: '25px',
			maxHeight,
			height: fullHeight ? maxHeight : undefined,
			boxShadow: '5px -4px 16px rgba(0, 0, 0, 0.1)',
			...sx,
		},
	};

	return (
		<SwipeableDrawer
			disableSwipeToOpen
			ModalProps={ModalProps}
			PaperProps={PaperProps}
			anchor="bottom"
			open={open}
			variant={variant}
			onClose={onClose}
			onOpen={onOpen}
		>
			<Box
				className="MobileBottomDrawerHandleWrapper"
				data-testid="mobile-bottom-drawer"
				sx={{
					background,
					position: 'absolute',
					top: '0',
					width: '100%',
					height: '30px',
					zIndex: '1000',
					paddingTop: '12px',
				}}
			>
				<Box
					sx={{
						width: '80px',
						height: '5px',
						backgroundColor: handleBackground,
						borderRadius: '7px',
						margin: '0 auto',
					}}
				/>
			</Box>

			{stickyContentTop && <Box sx={{ marginTop: '42px' }}>{stickyContentTop}</Box>}

			<Box
				ref={contentRef}
				sx={{
					padding: `${stickyContentTop ? '0' : '42px'} 16px 25px 16px`,
					overflow: 'auto',
					flex: '1',
				}}
			>
				{title && (
					<MobileDrawerTitle
						goBack={goBack}
						icon={icon}
						sx={{ textAlign, ...sxTitle }}
						title={title}
						onGoBack={goBack?.onGoBack}
					/>
				)}

				{children}
			</Box>

			{stickyContentBottom && <Box>{stickyContentBottom}</Box>}
		</SwipeableDrawer>
	);
};

MobileBottomDrawer.defaultProps = {
	handleBackground: 'rgba(18, 18, 18, 0.1)',
	background: '#FFFFFF',
	textAlign: 'center',
};

export default MobileBottomDrawer;
