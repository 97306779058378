import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useMultiscreen } from '@rs-core/hooks';
import { logDebug } from '@worklist-2/core/src';

const BROADCAST_CHANNEL_NAME = 'IV';

const ImageViewerMultiMonitorContext = createContext({});

const ImageViewerMultiMonitorContextProvider = ({ children }) => {
	const imageViewer3DMultiMonitor = useBooleanFlagValue('ImageViewer3DMultiMonitor');

	const { ivSynchronizedScreens } = useMultiscreen();

	const [searchParams] = useSearchParams();
	const screenNumber = searchParams.get('screenNumber');

	const isEnabledMultiMonitor = Boolean(imageViewer3DMultiMonitor && screenNumber);

	const imageViewerBroadcastChannel = useRef(new BroadcastChannel(BROADCAST_CHANNEL_NAME));
	const isSynchronized = useRef(false);
	const isEnabledMultiMonitorRef = useRef(isEnabledMultiMonitor);
	const isClosedBroadcastChannelRef = useRef(false);

	isEnabledMultiMonitorRef.current = isEnabledMultiMonitor;

	const postMessage = data => {
		if (
			!isEnabledMultiMonitorRef.current ||
			!imageViewerBroadcastChannel.current ||
			isClosedBroadcastChannelRef.current
		) {
			return;
		}

		logDebug('IV_BROADCAST_CHANNEL', 'postMessage:', {
			screenNumber,
			event: data.event,
		});

		imageViewerBroadcastChannel.current.postMessage({
			screenNumber,
			...data,
		});
	};

	useEffect(() => {
		imageViewerBroadcastChannel.current.addEventListener('close', () => {
			isClosedBroadcastChannelRef.current = true;
		});
	}, []);

	return (
		<ImageViewerMultiMonitorContext.Provider
			value={{
				imageViewerBroadcastChannel,
				isSynchronized,
				isEnabledMultiMonitor,
				synchronizedScreens: ivSynchronizedScreens,
				postMessage,
				isEnabledMultiMonitorRef,
				screenNumber,
				isClosedBroadcastChannelRef,
			}}
		>
			{children}
		</ImageViewerMultiMonitorContext.Provider>
	);
};

const useImageViewerMultiMonitorContext = () => useContext(ImageViewerMultiMonitorContext);

export { useImageViewerMultiMonitorContext, ImageViewerMultiMonitorContextProvider, ImageViewerMultiMonitorContext };
