import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@worklist-2/core/src';
import { ERoutes } from '@worklist-2/worklist/src/routes/routes';

import getUserFullName, { parseFhirName } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';

// Components
import { BirthDate } from '@rs-ui/views/PatientInformationView/components/BirthDate';
import { PatientGender } from '@rs-ui/views/PatientInformationView/components/PatientGender';
import { Vitals } from '@rs-ui/views/PatientInformationView/components/Vitals';
import { SmokingAlcoholObservations } from '@rs-ui/views/PatientInformationView/components/SmokingAlcoholObservations';
// Store
import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';

// Utils
import {
	getAlcoholLabelText,
	getSmokingLabelText,
} from '@rs-ui/views/PatientInformationView/utils/smokingAlcoholUtils';

import PatientAvatar from './PatientAvatar';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const PatientInfo = ({ isSelected, setCollapsed, isExpandMain }) => {
	const [expanded, setExpanded] = useState(true);
	const { initConfig, patientPermission } = usePatientInfoCardStore();
	const canViewPatientDetails = patientPermission?.read;
	const { patient, isLoading: isPatientLoading, isLoaded, observations } = usePatientStore();

	const isLoading = !isLoaded || isPatientLoading;
	const crossFormatPersonName = useBooleanFlagValue('cross-format-person-name');
	const { t } = useTranslation('patientInfo');

	const patientId = useMemo(() => patient?.patient?.id, [patient]);
	const { goTo } = useRouter();

	useEffect(() => {
		setExpanded(isExpandMain);
	}, [isExpandMain]);

	const toggleExpanded = e => {
		e?.preventDefault();
		if (isSelected) {
			setExpanded(val => !val);
			setCollapsed(!expanded);
		}
	};

	const isLowerHeight = useMediaQuery('(max-height: 800px)');

	const userFullName = useMemo(
		() =>
			crossFormatPersonName
				? parseFhirName(patient?.patient?.name?.[0])
				: getUserFullName(patient?.patient?.name?.[0], true),
		[patient, crossFormatPersonName]
	);

	const alcoholLabelText = getAlcoholLabelText(observations?.alcoholObservations?.code);
	const smokingLabelText = getSmokingLabelText(observations?.smokingObservations?.code);
	const pregnantLabelText = t('pregnant'); // Currently harcoded in LeftPanelBaseInfo.jsx

	const goToPatientPage = useCallback(() => {
		const constructPatientDetailUrl = id => ERoutes.PATIENT_DETAIL.replace(':patientId', id);
		if (isSelected) {
			const url = constructPatientDetailUrl(patientId);
			goTo.external(url, true);
		}
	}, [goTo, patientId, isSelected]);

	return (
		<Box
			sx={{
				...STYLES.CONTAINER,
				fontSize: isLowerHeight ? '6px' : '16px',
			}}
		>
			<PatientAvatar
				initConfig={initConfig}
				internalPatientId={patientId}
				isLoading={isLoading}
				name={userFullName}
				onCollapse={toggleExpanded}
				onNavigate={goToPatientPage}
			/>

			{/* BIRTHDATE*/}
			<Box sx={STYLES.BIRTHDATE_WRAPPER(isLowerHeight)}>
				<BirthDate birthDate={patient?.patient?.birthDate} isLoading={isLoading} />
				{patient?.patient?.gender && <PatientGender gender={patient?.patient?.gender} isLoading={isLoading} />}
			</Box>

			{/* OBSERVATIONS */}
			{(alcoholLabelText || smokingLabelText) && canViewPatientDetails ? (
				<Box sx={STYLES.OBSERVATIONS_WRAPPER(isLowerHeight)}>
					<SmokingAlcoholObservations
						alcoholStatus={alcoholLabelText}
						isLoading={isLoading}
						smokingStatus={smokingLabelText}
						// pregnantStatus={pregnantLabelText}
					/>
				</Box>
			) : (
				<Box sx={{ height: '24px' }} />
			)}

			{/* VITAL OBSERVATIONS */}
			{canViewPatientDetails && (
				<Box sx={STYLES.VITALS_WRAPPER(isLowerHeight)}>
					<Vitals isLoading={isLoading} observationInfo={observations?.vitalObservations} />
				</Box>
			)}
		</Box>
	);
};

export default PatientInfo;

const STYLES = {
	CONTAINER: {
		padding: '1em 1.4375em',
	},
	BIRTHDATE_WRAPPER: isLowerHeight => ({
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: isLowerHeight ? '8px' : '12px',
		columnGap: '0.75em',
		cursor: 'default',
	}),
	OBSERVATIONS_WRAPPER: isLowerHeight => ({
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: isLowerHeight ? '8px' : '12px',
	}),
	VITALS_WRAPPER: isLowerHeight => ({
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0 2px',
		boxSizing: 'border-box',
		marginTop: isLowerHeight ? '8px' : '12px',
	}),
};
