import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotesIcon from '../../../assets/icons/PatientInfoCards/stylus_note.svg';
import EmptyNoteCard from './EmptyNoteCard';
import NotesCard from './NotesCard';
import NotesListHeader from './NotesListHeader';
import { useConfig, useAuth } from '@worklist-2/core/src';
import useGetSearchParams from '@worklist-2/worklist/src/DocumentViewerV3/hooks/useGetSearchParams';
import _ from 'lodash';
// API's
import getGlobalNotes from '../api/getGlobalNotes';
import createGlobalNote from '../api/createGlobalNote';
import deleteGlobalNote from '../api/deleteGlobalNote';
import updateGlobalNote from '../api/updateGlobalNote';
// Store
import { useGlobalNotesStore } from '@rs-ui/stores/notesStore';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useTranslation } from 'react-i18next';

const EDIT_STATUS = {
	EDIT: 'EDIT',
	CANCEL: 'CANCEL',
};

const Notes = ({ rootHeight, isSelected, isHover, setCollapsed, isExpandMain }) => {
	const [expanded, setExpanded] = useState(true);
	const [isEditMode, setIsEditMode] = useState(false);
	const [editStatus, setEditStatus] = useState(EDIT_STATUS.EDIT); // to reset the value in NotesCard if editing is canceled
	const [selectedId, setSelectedId] = useState('');
	const [notesList, setNotesList] = useState([]);
	const [noteContent, setNoteContent] = useState('');

	const __config = useConfig();
	const { globalNotes, setGlobalNotes } = useGlobalNotesStore();
	const { loggedInUser } = useAuth();
	const { internalManagingOrganizationID, studyInstanceUIDs: studyInstanceUID } = useGetSearchParams();
	const galaxyDvPatientChartRefactor = useBooleanFlagValue('galaxy-dv-patient-chart-refactor');
	const { t } = useTranslation('patientInfo');
	useEffect(() => {
		setExpanded(isExpandMain);
	}, [isExpandMain]);

	useEffect(() => {
		// To Sort the last edited Note to display first
		setNotesList(_.orderBy(globalNotes, [note => new Date(note.CreationDateTime)], ['desc']));
	}, [globalNotes]);

	const toggleExpanded = e => {
		e?.preventDefault();
		if (isSelected) {
			setExpanded(val => !val);
			setCollapsed(!expanded);
		}
	};

	const fetchNotes = useCallback(async () => {
		const res = await getGlobalNotes({
			__config,
			resourceType: 'StudyNote',
			studyInstanceUID,
			internalManagingOrganizationID,
		});
		setGlobalNotes(res);
	}, [__config, studyInstanceUID, internalManagingOrganizationID, setGlobalNotes]);

	const handleEdit = useCallback(currentNote => {
		setIsEditMode(true);
		setEditStatus(EDIT_STATUS.EDIT);
		setSelectedId(currentNote.ResourceID); // Set selected note id to edit mode
		setNoteContent(currentNote.note); // Set note content to state whenever edit icon is clicked
	}, []);

	const handleDelete = useCallback(
		async currentNote => {
			const params = new URLSearchParams([
				['resourceType', currentNote.ResourceType],
				['ResourceID', currentNote.ResourceID],
				['studyInstanceUID', studyInstanceUID],
				['internalManagingOrganizationId', internalManagingOrganizationID],
			]);
			await deleteGlobalNote({ __config, params });
			setGlobalNotes(prevNotes => prevNotes.filter(note => note.ResourceID !== currentNote.ResourceID));
		},
		[__config, studyInstanceUID, internalManagingOrganizationID, setGlobalNotes]
	);

	const handleAddNotes = useCallback(() => {
		setIsEditMode(true);
		setEditStatus(EDIT_STATUS.EDIT);
		setSelectedId(''); // clear selected id on add new note
		setNotesList(prevNotes => [
			{
				ResourceType: 'StudyNote',
				note: '',
				ResourceID: '', // resource id is empty initially to indicate new note
			},
			...prevNotes,
		]);
	}, []);

	const handleOnSave = useCallback(async () => {
		setIsEditMode(false);
		setEditStatus(EDIT_STATUS.EDIT);
		const isCreateReport = selectedId === '';

		if (isCreateReport) {
			const params = new URLSearchParams([
				['resourceType', 'StudyNote'],
				['studyInstanceUID', studyInstanceUID],
				['internalManagingOrganizationId', internalManagingOrganizationID],
			]);
			await createGlobalNote({
				__config,
				params,
				noteText: noteContent,
			});
			fetchNotes();
		} else {
			const currentStudy = notesList.find(x => x.ResourceID === selectedId);
			const updateParams = new URLSearchParams([
				['resourceType', 'StudyNote'],
				['ResourceID', selectedId],
				['studyInstanceUID', studyInstanceUID],
				['internalManagingOrganizationId', internalManagingOrganizationID],
			]);
			const payload = {
				id: currentStudy.id,
				note: noteContent,
				Creator: currentStudy.Creator,
				CreatorDisplayName: currentStudy.CreatorDisplayName,
				CreatorId: currentStudy.CreatorId,
			};
			await updateGlobalNote({
				__config,
				params: updateParams,
				payload,
			});
			setGlobalNotes(prevNotes =>
				prevNotes.map(note => (note.ResourceID === selectedId ? { ...note, note: noteContent } : note))
			);
		}
	}, [
		selectedId,
		noteContent,
		fetchNotes,
		__config,
		studyInstanceUID,
		internalManagingOrganizationID,
		notesList,
		setGlobalNotes,
	]);

	const handleClose = useCallback(() => {
		setIsEditMode(false);
		setEditStatus(EDIT_STATUS.CANCEL);
		if (selectedId === '') {
			// remove added new notes from the list
			setNotesList(prevNotes => prevNotes.filter(note => note.ResourceID !== ''));
		}
		setSelectedId('');
	}, [selectedId]);

	return (
		<Accordion
			disableGutters
			expanded={expanded}
			id="doc"
			sx={{
				...STYLES.ACCORDIAN,
				...(galaxyDvPatientChartRefactor && {
					height: '100%',
					'& .MuiCollapse-root': {
						height: 'calc(100% - 64px) !important',
						'.MuiCollapse-wrapper, .MuiCollapse-wrapperInner, .MuiAccordion-region, .MuiAccordionDetails-root':
							{
								height: '100% !important',
							},
					},
				}),
			}}
		>
			<AccordionSummary
				data-testid="notes-component-accordion"
				expandIcon={<ExpandMoreIcon />}
				id="doc-summary"
				sx={STYLES.ACCORDIAN_SUMMARY}
				onClick={toggleExpanded}
			>
				<Typography
					sx={{
						display: 'inline-flex',
						alignItems: 'center',
					}}
				>
					<NotesIcon
						style={{
							marginRight: '8px',
						}}
					/>
					{t('Notes')}
				</Typography>
				{globalNotes?.length > 0 && <Typography sx={STYLES.LIST_COUNT}>{globalNotes.length}</Typography>}
			</AccordionSummary>
			<AccordionDetails data-testid="notes-component-accordion-details" sx={STYLES.ACCORDIAN_DETAILS}>
				<NotesListHeader
					isEditMode={isEditMode}
					t={t}
					onAddNote={handleAddNotes}
					onClose={handleClose}
					onSave={handleOnSave}
				/>
				<Box
					sx={{
						...STYLES.DOC_LIST_CONT,
						...(!galaxyDvPatientChartRefactor && {
							maxHeight: `${!isSelected && isHover ? 234 : Math.max(rootHeight - 64 - 90 - 35, 0)}px`,
							height: `${!isSelected && isHover ? 234 : Math.max(rootHeight - 64 - 90 - 35, 0)}px`,
						}),
					}}
				>
					{notesList.length > 0 ? (
						notesList.map((notes, idx) => (
							<NotesCard
								key={`note-card-${notes.ResourceID}`}
								editStatus={editStatus}
								isEditMode={isEditMode}
								loggedInUser={loggedInUser}
								selectedId={selectedId}
								setNoteChage={setNoteContent} //update selected content note on change
								onDelete={() => handleDelete(notes)}
								onEdit={() => handleEdit(notes)}
								{...notes}
							/>
						))
					) : (
						<EmptyNoteCard t={t} onClick={handleAddNotes} />
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default Notes;

const STYLES = {
	ACCORDIAN: {
		boxShadow: 'unset',
		background: 'unset',
		'&:before': {
			display: 'none',
		},
	},
	ACCORDIAN_SUMMARY: {
		background: '#363636',
		borderRadius: '10px 10px 0 0',
		position: 'sticky',
		top: '0',
		zIndex: '2',
		minHeight: '64px',
		borderBottom: '1px solid rgba(255,255,255,0.06)',
		'& .MuiAccordionSummary-content': {
			margin: '0',
		},
		'&:hover': {
			backgroundColor: '#4f4f4f',
		},
	},
	ACCORDIAN_DETAILS: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		background: '#303030',
		borderRadius: '4px',
	},
	DOC_LIST_CONT: {
		display: 'flex',
		flexDirection: 'column',
		padding: '0 15px 15px',
		gap: '10px',
		overflowY: 'auto',
	},
	LIST_COUNT: {
		width: '26px',
		height: '26px',
		borderRadius: '6px',
		background: '#282828',
		fontWeight: '400',
		fontSize: '13px',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		ml: '8px',
	},
};
