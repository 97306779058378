// core
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { getDateHintText } from '@rs-ui/components/utils/dateUtils';

// MUI
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

// Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { SKIP_TRANSLATION_LIST } from '@rs-ui/helpers/constants';
import FilterCapsuleMenu from './FilterCapsuleMenu';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const FilterCapsule = ({
	label,
	columnLabel,
	columnIdentity,
	menuItems,
	capsuleMenuItems,
	values,
	displayValue,
	size,
	capsuleMaxWidth,
	showMore,
	onRemoveCapsule,
	onCapsuleFilter,
	searchParameter,
	toolTip,
	searchValueSet,
	filterType,
	columnMapping,
}) => {
	const displayVal = displayValue ?? values;
	const elementRef = useRef();
	const [currentLabels, setCurrentLabels] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuWidth, setMenuWidth] = useState(150);
	const [openMenu, setOpenMenu] = useState(false);
	const [capsuleLabel, setCapsuleLabel] = useState(label);
	const [chipLabelMap, setChipLabelMap] = useState({});
	const { t } = useTranslation(['datetimerangepicker', 'codePool']);
	const proactEnableCustomStudyPriority = useBooleanFlagValue('proact-enable-custom-study-priority');
	if (proactEnableCustomStudyPriority) {
		SKIP_TRANSLATION_LIST.push('priority');
	}

	const composedCapsuleLabel = useMemo(() => {
		// For date-range columns, displayVal is an array and array elements are date string values
		const isDateValue =
			_.isArray(displayVal) &&
			(Number.isNaN(Number(displayVal[0])) || displayVal[0] instanceof Date) &&
			new Date(displayVal[0])?.toString() !== 'Invalid Date';
		// Skip translation for text-search columns (displayVal is not an array), date-range columns and columns in SKIP_TRANSLATION_LIST
		// Translate multi-select columns (displayVal is an array)
		let tempValues =
			SKIP_TRANSLATION_LIST.includes(searchValueSet) || !_.isArray(displayVal) || isDateValue
				? displayVal
				: displayVal.map(value => t(`codePool:${searchValueSet}.${value}`));

		const separator = isDateValue ? ' - ' : ' | ';
		if (isDateValue && displayVal[0] instanceof Date) {
			// Handle format if item is date object
			tempValues = getDateHintText(t, tempValues);
		}

		return tempValues?.length > 0
			? `${columnLabel}: ${_.isArray(tempValues) ? tempValues.join(separator) : tempValues}`
			: columnLabel;
	}, [columnLabel, displayVal, capsuleLabel, t]);

	const selectedMenuItems = {};
	if (displayVal?.length > 0) {
		_.map(displayVal, item => {
			const index = _.indexOf(_.flatten([displayVal]), item);
			if (index > -1) {
				selectedMenuItems[item] = true;
			} else {
				selectedMenuItems[item] = false;
			}
		});
	}

	useEffect(() => {
		setCapsuleLabel(composedCapsuleLabel);
		setChipLabelMap(selectedMenuItems);
		setCurrentLabels(displayVal);
	}, [columnLabel, displayVal, composedCapsuleLabel]);

	// Onclick , show the menu
	const handleMenuDropDownClick = event => {
		if (openMenu) {
			setOpenMenu(false);
			setAnchorEl(null);
			return;
		}
		setOpenMenu(true);
		setMenuWidth(elementRef.current ? elementRef.current.offsetWidth : 200);
		setAnchorEl(event && event.currentTarget ? event.currentTarget : null);
	};

	// if clicked somewhere, close the menu
	const handleClose = () => {
		setAnchorEl(null);
		setOpenMenu(false);
	};

	const handleDelete = () => {
		if (onRemoveCapsule) {
			onRemoveCapsule(columnIdentity);
		}
	};

	const filterRecords = (chipMap, shouldFilterTable) => {
		const selectedRows = [];
		for (const item in chipMap) {
			if (chipMap[item]) {
				selectedRows.push(item);
			}
		}
		// Updated table rows
		if (onCapsuleFilter && shouldFilterTable) {
			onCapsuleFilter(columnIdentity, selectedRows, selectedRows, capsuleMenuItems);
		}
	};

	const handleSingleSelect = itemName => {
		const newChipLabelMap = { [itemName]: true };
		setChipLabelMap(newChipLabelMap);
		const newCurrentLabels = [itemName];
		setCurrentLabels(newCurrentLabels);
		const newCapsuleLabel = `${columnLabel}: ${
			SKIP_TRANSLATION_LIST.includes(searchValueSet) ? itemName : t(`codePool:${searchValueSet}.${itemName}`)
		}`;
		setCapsuleLabel(newCapsuleLabel);
		filterRecords(newChipLabelMap, true);
		setAnchorEl(null);
		setOpenMenu(false);
	};

	const handleMultiSelectInifiniteScroll = item => {
		const selectedOptions = item?.selectedOptions || [];
		setCurrentLabels(selectedOptions);

		const newChipLableMap = {};

		selectedOptions.map(option => {
			newChipLableMap[option] = true;
		});

		setChipLabelMap(newChipLableMap);

		setCapsuleLabel(
			selectedOptions?.length > 0
				? `${columnLabel}: ${
						SKIP_TRANSLATION_LIST.includes(searchValueSet)
							? selectedOptions.join(' | ')
							: selectedOptions.map(value => t(`codePool:${searchValueSet}.${value}`)).join(' | ')
				  }`
				: columnLabel
		);

		if (onRemoveCapsule && selectedOptions?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		filterRecords(newChipLableMap, true);
	};

	const handleMenuCheckBox = (itemName, event, index) => {
		const checkboxItem = document.getElementById(`checkbox_${index}`);
		let checkBoxValue = !checkboxItem.checked;
		if (event.target === checkboxItem) {
			checkBoxValue = event.target.checked;
		}
		chipLabelMap[itemName] = checkBoxValue;
		setChipLabelMap(chipLabelMap);
		let chipLabelList = currentLabels;
		if (checkBoxValue && !_.find(chipLabelList, item => item === itemName)) {
			chipLabelList.push(itemName);
		} else {
			chipLabelList = _.filter(chipLabelList, item => item != itemName);
		}
		setCurrentLabels(chipLabelList);
		setCapsuleLabel(
			chipLabelList?.length > 0
				? `${columnLabel}: ${
						SKIP_TRANSLATION_LIST.includes(searchValueSet)
							? chipLabelList.join(' | ')
							: chipLabelList.map(value => t(`codePool:${searchValueSet}.${value}`)).join(' | ')
				  }`
				: columnLabel
		);
		if (onRemoveCapsule && chipLabelList?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		filterRecords(chipLabelMap, true);
	};

	const handleSelectFn = (itemName, event, index) => {
		switch (filterType) {
			case 'checkbox-single-select':
			case 'multi-select':
			case 'checkbox-multi-select':
				handleMenuCheckBox(itemName, event, index);
				break;
			case 'single-select':
				handleSingleSelect(itemName);
				break;
			case 'infinite-scroll-multi-select-suggest':
				handleMultiSelectInifiniteScroll(itemName);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		setMenuWidth(elementRef.current ? elementRef.current.offsetWidth : 200);
	}, [capsuleLabel, size]);

	const chipBackgroundColor = '#293138';
	const chipBackgroundOpenColor = '#343739';

	return (
		<Tooltip
			arrow
			disableFocusListener
			disableInteractive
			disableTouchListener
			placement="top-end"
			title={toolTip && capsuleLabel ? capsuleLabel : ''}
		>
			<Box
				sx={{
					'&:hover': {
						...showMore?.avatarHover,
					},
				}}
			>
				<Chip
					ref={elementRef}
					data-cy={typeof capsuleLabel === 'string' ? `${capsuleLabel.split(':')[0]}_capsule_filter` : ''}
					deleteIcon={
						<CancelIcon
							data-cy={
								typeof capsuleLabel === 'string' ? `${capsuleLabel.split(':')[0]}_capsule-delete` : ''
							}
						/>
					}
					icon={menuItems?.length > 0 ? <ArrowDropDownIcon onClick={handleMenuDropDownClick} /> : null}
					label={
						<>
							<Typography
								sx={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									fontSize: '12px',
								}}
							>
								{capsuleLabel}
							</Typography>
							{searchParameter === 'internalParentOrganizationID' &&
								columnIdentity === 'managingOrganization' && (
									<AccountTreeOutlinedIcon sx={{ ml: '4px' }} />
								)}
						</>
					}
					size={size}
					sx={{
						maxWidth: showMore?.capsuleMaxWidth || capsuleMaxWidth,
						color: 'rsPrimary.main',
						'& .MuiChip-label': {
							...showMore?.labelProps,
							width: showMore?.capsuleMaxWidth - 100,
							display: 'flex',
							alignItems: 'center',
						},
						width: showMore?.capsuleWidth,
						...(openMenu
							? {
									borderRadius: showMore?.borderRadius || '16px 16px 0px 0px',
									backgroundColor: chipBackgroundOpenColor,
									'&:hover': {
										backgroundColor: chipBackgroundOpenColor,
									},
							  }
							: {
									borderRadius: showMore?.borderRadius || '16px 16px 16px 16px',
									backgroundColor: showMore?.backgroundColor || chipBackgroundColor,
									'&:hover': {
										backgroundColor: chipBackgroundColor,
									},
							  }),
						'& .MuiChip-icon': {
							order: 1,
							paddingRight: '14px',
							color: 'rsPrimary.main',
							opacity: '0.60',
							border: showMore?.deleteIconBorder,
							...showMore?.dropDownIconProps,
							'&:hover': {
								color: 'rsPrimary.hover',
							},
							visibility: showMore?.avatarVisibility || 'visible',
						},
						'& .MuiChip-deleteIcon': {
							order: 3,
							color: 'rsPrimary.main',
							opacity: '0.60',
							'&:hover': {
								color: 'rsPrimary.hover',
							},
							...showMore?.deleteIconProps,
							visibility: showMore?.avatarVisibility || 'visible',
						},
					}}
					onClick={menuItems?.length > 0 ? handleMenuDropDownClick : null}
					onDelete={columnIdentity !== 'faxDirection' ? handleDelete : null}
				/>
				{menuItems?.length > 0 && (
					<Box sx={{ visibility: openMenu ? 'visible' : 'hidden' }}>
						<FilterCapsuleMenu
							anchorEl={anchorEl}
							avatarHover={showMore?.avatarHover}
							columnMapping={columnMapping}
							displayVal={displayVal}
							filterType={filterType}
							isStack={showMore?.isStack}
							label={columnIdentity}
							menuItems={menuItems}
							menuWidth={menuWidth}
							open={Boolean(anchorEl)}
							openMenu={openMenu}
							openMenuOnload={openMenu}
							searchValueSet={searchValueSet}
							selectedItems={chipLabelMap}
							t={t}
							onClose={handleClose}
							onMenuChecked={handleSelectFn}
						/>
					</Box>
				)}
				{openMenu && menuItems?.length > 0 && showMore?.isStack && (
					<Divider
						sx={{
							width: showMore?.capsuleWidth - 20,
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
					/>
				)}
			</Box>
		</Tooltip>
	);
};

FilterCapsule.propTypes = {
	/**
	 * Chip label, indicates the column name, e.g Modality
	 */
	columnLabel: PropTypes.string,

	/**
	 * Identity of the capsule, unique for each capsule, e.g modality, managingOrg
	 */
	columnIdentity: PropTypes.string.isRequired,

	/**
	 * List of unique items, e.g for Modality, menuItems=['CT', 'CR]
	 */
	menuItems: PropTypes.array,

	/**
	 * An array of values
	 */
	values: PropTypes.array,

	/**
	 * An array of display values
	 */
	displayValue: PropTypes.array,

	/**
	 * Size of capsule
	 */
	size: PropTypes.oneOf(['small', 'medium']),

	/**
	 * The maximum width of the capsule in pixels. As items are selected, the capsule width will grow
	 * up to this limit.
	 */
	capsuleMaxWidth: PropTypes.number,

	/**
	 * This is a attribute of the filter capsule if the capsule is child component of ShowMore component.
	 */
	showMore: PropTypes.object,

	/**
	 * Callback function to filter table rows.
	 * It accepts columnIdentity and list of items of table column from Menu.
	 * Based on those list of items, table is filtered.
	 */
	onCapsuleFilter: PropTypes.func,

	/**
	 * Callback function to remove capsule from WorklistGrid
	 * It accepts columnIdentity and list of items table column from Menu(Optional)
	 */
	onRemoveCapsule: PropTypes.func,

	/**
	 * A search param string
	 */
	searchParameter: PropTypes.string,

	/**
	 * This is a attribute to display toolTip for capsure panel
	 */
	toolTip: PropTypes.bool,

	/**
	 * Value set name
	 */
	searchValueSet: PropTypes.string,

	/**
	 * Filter type
	 */
	filterType: PropTypes.string,
};

FilterCapsule.defaultProps = {
	menuItems: [],
	size: 'medium',
	capsuleMaxWidth: 300,
};

export default FilterCapsule;
