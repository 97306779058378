import React from 'react';
import { HelpPopOverButton } from '..';
import { BannerBody, BannerHeader, Container, HeroBanner } from './whatsNewStyles';
import webDesign from '../../../../assets/img/helpAssets/clip-web-design1.png';
import { useTranslation } from 'react-i18next';

const Highlights = () => {
	const { t } = useTranslation('helpCenter');
	const Content = '';

	return (
		<Container className="content-container">
			<HelpPopOverButton />
			<HeroBanner className="content-banner">
				<BannerBody>
					<BannerHeader>{t(`What's New in OmegaAI`)}</BannerHeader>
					<small>{t('We got to work to improve your experience. Explore the latest features.')}</small>
				</BannerBody>
				<img src={webDesign} />
			</HeroBanner>
			{Content}
		</Container>
	);
};
export default Highlights;
