const defaultRamSoftPermission = {
	organizationId: 1,
	guiPermission: [
		{
			resource: 'Home',
			display: 'Home',
			action: ['read'],
			permissions: [
				{
					resource: 'Patient',
					display: 'Patient',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'General',
							display: 'General',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'PatientName',
									display: 'Patient Name',
									action: ['read', 'update'],
								},
								{
									resource: 'PatientId',
									display: 'Patient Id',
									action: ['read', 'update'],
								},
								{
									resource: 'SSN',
									display: 'SSN',
									action: ['read', 'update'],
								},
								{
									resource: 'DriverLicense',
									display: 'Driver’s License #',
									action: ['read', 'update'],
								},
								{
									resource: 'BirthDate',
									display: 'Birth Date',
									action: ['read', 'update'],
								},
								{
									resource: 'Gender',
									display: 'Gender',
									action: ['read', 'update'],
								},
								{
									resource: 'ManagingOrganization',
									display: 'Managing Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'MotherMaidenName',
									display: 'Mother’s Maiden Name',
									action: ['read', 'update'],
								},
								{
									resource: 'MaritalStatus',
									display: 'Marital Status',
									action: ['read', 'update'],
								},
								{
									resource: 'Language',
									display: 'Language',
									action: ['read', 'update'],
								},
								{
									resource: 'Race',
									display: 'Race',
									action: ['read', 'update'],
								},
								{
									resource: 'Ethnicity',
									display: 'Ethnicity',
									action: ['read', 'update'],
								},
								{
									resource: 'Confidentiality',
									display: 'Confidentiality',
									action: ['read', 'update'],
								},
								{
									resource: 'DeceasedDate',
									display: 'Deceased Date',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Address',
							display: 'Address',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'AddressSearch',
									display: 'Address Search',
									action: ['read', 'update'],
								},
								{
									resource: 'Country',
									display: 'Country',
									action: ['read', 'update'],
								},
								{
									resource: 'State/Province',
									display: 'State/Province',
									action: ['read', 'update'],
								},
								{
									resource: 'ZIP/Postal Code',
									display: 'ZIP/Postal Code',
									action: ['read', 'update'],
								},
								{
									resource: 'City',
									display: 'City',
									action: ['read', 'update'],
								},
								{
									resource: 'Address Line 1',
									display: 'Address Line 1',
									action: ['read', 'update'],
								},
								{
									resource: 'Address Line 2',
									display: 'Address Line 2',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Link',
							display: 'Link',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'PatientName',
									display: 'Patient Name',
									action: ['read', 'update'],
								},
								{
									resource: 'PatientID',
									display: 'Patient ID',
									action: ['read', 'update'],
								},
								{
									resource: 'LinkType',
									display: 'Link Type',
									action: ['read', 'update'],
								},
								{
									resource: 'LinkAndMergePatient',
									display: 'Link & Merge Patient',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'ContactInformation',
							display: 'Contact Information',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'Email',
									display: 'Email',
									action: ['read', 'update'],
								},
								{
									resource: 'Phone',
									display: 'Phone',
									action: ['read', 'update'],
								},
								{
									resource: 'Fax',
									display: 'Fax',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'EmergencyContact',
							display: 'Emergency Contact',
							action: ['read', 'update'],
						},
						{
							resource: 'Activity',
							display: 'Activity',
							action: ['read', 'update'],
						},
						{
							resource: 'Guarantor',
							display: 'Guarantor',
							action: ['read', 'update'],
						},
						{
							resource: 'Coverage',
							display: 'Coverage',
							action: ['read', 'update'],
						},
						{
							resource: 'Alerts',
							display: 'Alerts',
							action: ['read', 'update'],
						},
						{
							resource: 'Allergy',
							display: 'Allergy',
							action: ['read', 'update'],
						},
					],
				},
				{
					resource: 'Visit',
					display: 'Visit',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'General',
							display: 'General',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'VisitID',
									display: 'Visit ID',
									action: ['read', 'update'],
								},
								{
									resource: 'VisitDateTime',
									display: 'Date/Time of Visit',
									action: ['read', 'update'],
								},
								{
									resource: 'EncounterType',
									display: 'Encounter Type',
									action: ['read', 'update'],
								},
								{
									resource: 'EncounterStatus',
									display: 'Encounter Status',
									action: ['read', 'update'],
								},
								{
									resource: 'AttendingPhysicianNurse',
									display: 'Attending Physician/Nurse',
									action: ['read', 'update'],
								},
								{
									resource: 'PatientClass',
									display: 'Patient Class',
									action: ['read', 'update'],
								},
								{
									resource: 'LocationOfEncounter',
									display: 'Location of Encounter',
									action: ['read', 'update'],
								},
								{
									resource: 'VisitReasonHistory',
									display: 'Visit Reason/History',
									action: ['read', 'update'],
								},
								{
									resource: 'SpecialCourtesy',
									display: 'Special Courtesy',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'PatientVitals',
							display: 'Patient Vitals',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'HeartRate',
									display: 'Heart Rate',
									action: ['read', 'update'],
								},
								{
									resource: 'Height',
									display: 'Height',
									action: ['read', 'update'],
								},
								{
									resource: 'BloodPressure',
									display: 'Blood Pressure',
									action: ['read', 'update'],
								},
								{
									resource: 'Weight',
									display: 'Weight',
									action: ['read', 'update'],
								},
								{
									resource: 'SmokingHabit',
									display: 'Smoking Habit',
									action: ['read', 'update'],
								},
								{
									resource: 'DrinkingHabit',
									display: 'Drinking Habit',
									action: ['read', 'update'],
								},
								{
									resource: 'LMP',
									display: 'Last Menstrual Period',
									action: ['read', 'update'],
								},
								{
									resource: 'PregnancyStatus',
									display: 'Pregnancy Status',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Clinical',
							display: 'Clinical',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'PlanOfCare',
									display: 'Plan of Care',
									action: ['read', 'update'],
								},
								{
									resource: 'FollowUpInstructions',
									display: 'Follow-up Instructions',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'AccidentNotes',
							display: 'Accident Notes',
							action: ['read', 'update'],
						},
					],
				},
				{
					resource: 'Order',
					display: 'Order',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'General',
							display: 'General',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'PatientName',
									display: 'Patient Name',
									action: ['read', 'update'],
								},
								{
									resource: 'Accession',
									display: 'Accession #',
									action: ['read', 'update'],
								},
								{
									resource: 'FillerOrder',
									display: 'Filler Order #',
									action: ['read', 'update'],
								},
								{
									resource: 'PlaceOrder',
									display: 'Place Order #',
									action: ['read', 'update'],
								},
								{
									resource: 'PlacedOrder',
									display: 'Placed Order #',
									action: ['read', 'update'],
								},
								{
									resource: 'ReferringPhysician',
									display: 'Referring Physician',
									action: ['read', 'update'],
								},
								{
									resource: 'ReferringOrganization',
									display: 'Referring Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'ConsultingPhysician',
									display: 'Consulting Physician',
									action: ['read', 'update'],
								},
								{
									resource: 'OrderDateTime',
									display: 'Order Date/Time',
									action: ['read', 'update'],
								},
								{
									resource: 'Status',
									display: 'Status',
									action: ['read', 'update'],
								},
								{
									resource: 'Priority',
									display: 'Priority',
									action: ['read', 'update'],
								},
								{
									resource: 'OrderedDate',
									display: 'Ordered Date',
									action: ['read', 'update'],
								},
								{
									resource: 'OrderStatus',
									display: 'Order Status',
									action: ['read', 'update'],
								},
								{
									resource: 'OrderPriority',
									display: 'Order Priority',
									action: ['read', 'update'],
								},
								{
									resource: 'RequestedDate',
									display: 'Requested Date',
									action: ['read', 'update'],
								},
								{
									resource: 'RequestedAppointmentDateTime',
									display: 'Requested Appointment Date/Time',
									action: ['read', 'update'],
								},
								{
									resource: 'CancellationReason',
									display: 'Cancellation Reason',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Study',
							display: 'Study',
							action: ['read', 'update'],
						},
						{
							resource: 'ReasonForStudy',
							display: 'Reason for Study',
							action: ['read', 'update'],
						},
						{
							resource: 'ConsultingPhysician',
							display: 'Consulting Physician',
							action: ['read', 'update'],
						},
						{
							resource: 'AddNewReferringPhysician',
							display: 'Add New Referring Physician',
							action: ['read', 'update'],
						},
						{
							resource: 'AddNewConsultingPhysician',
							display: 'Add New Consulting Physician',
							action: ['read', 'update'],
						},
						{
							resource: 'OrderAppropriateness',
							display: 'Order Appropriateness',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'IsProcedureAppropriatenesstoIndication',
									display: 'Is Procedure Appropriateness to Indication',
									action: ['read', 'update'],
								},
								{
									resource: 'CSDID',
									display: 'CSD ID',
									action: ['read', 'update'],
								},
								{
									resource: 'AppropriateUseCriteria',
									display: 'Appropriate Use Criteria',
									action: ['read', 'update'],
								},
								{
									resource: 'Comments',
									display: 'Comments',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'OrderNotes',
							display: 'Order Notes',
							action: ['read', 'update'],
						},
						{
							resource: 'Note',
							display: 'Note',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'EnteredBy',
									display: 'Entered By',
									action: ['read', 'update'],
								},
								{
									resource: 'RequestingService',
									display: 'Requesting Service',
									action: ['read', 'update'],
								},
								{
									resource: 'Phone',
									display: 'Phone',
									action: ['read', 'update'],
								},
								{
									resource: 'History',
									display: 'History',
									action: ['read', 'update'],
								},
								{
									resource: 'Comment',
									display: 'Comment',
									action: ['read', 'update'],
								},
							],
						},
					],
				},
				{
					resource: 'Study',
					display: 'Study',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'General',
							display: 'General',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'Status',
									display: 'Status',
									action: ['read', 'update'],
								},
								{
									resource: 'StudyDateTime',
									display: 'Study Date/Time',
									action: ['read', 'update'],
								},
								{
									resource: 'StudyID',
									display: 'Study ID',
									action: ['read', 'update'],
								},
								{
									resource: 'RequestedProcedureID',
									display: 'Requested Procedure ID',
									action: ['read', 'update'],
								},
								{
									resource: 'ImagingOrganization',
									display: 'Imaging Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'Department',
									display: 'Department',
									action: ['read', 'update'],
								},
								{
									resource: 'StudyTypeCode',
									display: 'Study Type Code',
									action: ['read', 'update'],
								},
								{
									resource: 'LOINCCode',
									display: 'LOINC Code',
									action: ['read', 'update'],
								},
								{
									resource: 'MaritalStatus',
									display: 'Marital Status',
									action: ['read', 'update'],
								},
								{
									resource: 'Description',
									display: 'Description',
									action: ['read', 'update'],
								},
								{
									resource: 'Modality',
									display: 'Modality',
									action: ['read', 'update'],
								},
								{
									resource: 'ModalityModifier',
									display: 'Modality Modifier',
									action: ['read', 'update'],
								},
								{
									resource: 'Laterality',
									display: 'Laterality',
									action: ['read', 'update'],
								},
								{
									resource: 'BodyPart',
									display: 'Body Part',
									action: ['read', 'update'],
								},
								{
									resource: 'Preparation',
									display: 'Preparation (HH:MM)',
									action: ['read', 'update'],
								},
								{
									resource: 'Duration',
									display: 'Duration (HH:MM)',
									action: ['read', 'update'],
								},
								{
									resource: 'Recovery',
									display: 'Recovery (HH:MM)',
									action: ['read', 'update'],
								},
								{
									resource: 'Pharmaceutical',
									display: 'Pharmaceutical',
									action: ['read', 'update'],
								},
								{
									resource: 'AnatomicFocus',
									display: 'Anatomic Focus',
									action: ['read', 'update'],
								},
								{
									resource: 'Technique',
									display: 'Technique',
									action: ['read', 'update'],
								},
								{
									resource: 'View',
									display: 'View',
									action: ['read', 'update'],
								},
								{
									resource: 'ReasonForExam',
									display: 'Reason For Exam',
									action: ['read', 'update'],
								},
								{
									resource: 'PatientLocation',
									display: 'Patient Location',
									action: ['read', 'update'],
								},
								{
									resource: 'ExamRoom',
									display: 'Exam Room',
									action: ['read', 'update'],
								},
								{
									resource: 'HealthcareService',
									display: 'Healthcare Service',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Personnel',
							display: 'Personnel',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'PerformingTechnologist',
									display: 'Performing Technologist',
									action: ['read', 'update'],
								},
								{
									resource: 'PerformingPhysician',
									display: 'Performing Physician',
									action: ['read', 'update'],
								},
								{
									resource: 'ReadingPhysician',
									display: 'Reading Physician',
									action: ['read', 'update'],
								},
								{
									resource: 'ReadingOrganization',
									display: 'Reading Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'Transcriptionist',
									display: 'Transcriptionist',
									action: ['read', 'update'],
								},
								{
									resource: 'TranscriptionOrganization',
									display: 'Transcription Organization',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Clinical',
							display: 'Clinical',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'Laterality',
									display: 'Laterality',
									action: ['read', 'update'],
								},
								{
									resource: 'BodyPart',
									display: 'Body Part',
									action: ['read', 'update'],
								},
								{
									resource: 'Preparation',
									display: 'Preparation (HH:MM)',
									action: ['read', 'update'],
								},
								{
									resource: 'Duration',
									display: 'Duration (HH:MM)',
									action: ['read', 'update'],
								},
								{
									resource: 'Recovery',
									display: 'Recovery (HH:MM)',
									action: ['read', 'update'],
								},
								{
									resource: 'Pharmaceutical',
									display: 'Pharmaceutical',
									action: ['read', 'update'],
								},
								{
									resource: 'AnatomicFocus',
									display: 'Anatomic Focus',
									action: ['read', 'update'],
								},
								{
									resource: 'Technique',
									display: 'Technique',
									action: ['read', 'update'],
								},
								{
									resource: 'View',
									display: 'View',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Provider',
							display: 'Provider',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'PerformingTechnologist',
									display: 'Performing Technologist',
									action: ['read', 'update'],
								},
								{
									resource: 'PerformingPhysician',
									display: 'Performing Physician',
									action: ['read', 'update'],
								},
								{
									resource: 'ReadingPhysician',
									display: 'Reading Physician',
									action: ['read', 'update'],
								},
								{
									resource: 'ReadingOrganization',
									display: 'Reading Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'Transcriptionist',
									display: 'Transcriptionist',
									action: ['read', 'update'],
								},
								{
									resource: 'TranscriptionOrganization',
									display: 'Transcription Organization',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Procedure',
							display: 'Procedure',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'ProcedureCodes',
									display: 'Procedure Codes',
									action: ['read', 'update'],
								},
								{
									resource: 'Description',
									display: 'Description',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Note',
							display: 'Note',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'ClinicalNotes',
									display: 'Clinical Notes',
									action: ['read', 'update'],
								},
								{
									resource: 'Comments',
									display: 'Comments',
									action: ['read', 'update'],
								},
								{
									resource: 'ClinicalComments',
									display: 'Clinical Comments',
									action: ['read', 'update'],
								},
								{
									resource: 'StudyReasonHistory',
									display: 'Study Reason/History',
									action: ['read', 'update'],
								},
								{
									resource: 'StudyNotes',
									display: 'Study Notes',
									action: ['read', 'update'],
								},
								{
									display: 'Custom Field 1',
									resource: 'CustomField1',
									action: ['read', 'update'],
								},
								{
									display: 'Custom Field 2',
									resource: 'CustomField2',
									action: ['read', 'update'],
								},
							],
						},
					],
				},
				{
					resource: 'ImageViewer',
					display: 'Image Viewer',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'HangingProtocols',
							display: 'Hanging Protocols',
							action: ['read', 'update'],
						},
						{
							resource: 'Toolbar',
							display: 'Toolbar',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'WorkflowFinishingOptions',
									display: 'Workflow Finishing Options',
									action: ['read', 'update'],
								},
								{
									resource: 'DownloadStudy',
									display: 'Download Study',
									action: ['read', 'update'],
								},
								{
									resource: 'DownloadPrintImages',
									display: 'Download/Print Images',
									action: ['read', 'update'],
								},
								{
									resource: 'Share',
									display: 'Share',
									action: ['read', 'update'],
								},
								{
									resource: 'Notes',
									display: 'Notes',
									action: ['read', 'update'],
								},
								{
									resource: 'DeleteNotesDictations',
									display: 'Delete Notes & Dictations',
									action: ['read', 'update'],
								},
								{
									resource: 'VoiceDictations',
									display: 'Voice Dictations',
									action: ['read', 'update'],
								},
								{
									resource: 'CustomizeOverlays',
									display: 'Customize Overlays',
									action: ['read', 'update'],
								},
								{
									resource: 'CustomizeWheel',
									display: 'Customize Wheel',
									action: ['read', 'update'],
								},
								{
									resource: 'CustomizeToolbar',
									display: 'Customize Toolbar',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'MeasurementPanel',
							display: 'Measurement Panel',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'AnnotationsMeasurements',
									display: 'Annotations & Measurements',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'QCModule',
							display: 'QC Module',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'DeleteStudies',
									display: 'Delete Studies',
									action: ['read', 'update'],
								},
								{
									resource: 'MergeStudies',
									display: 'Merge/Unmerge Studies',
									action: ['read', 'update'],
								},
								{
									resource: 'MoveSeries',
									display: 'Move Series',
									action: ['read', 'update'],
								},
								{
									resource: 'DeleteSeries',
									display: 'Delete Series',
									action: ['read', 'update'],
								},
								{
									resource: 'RenameSeries',
									display: 'Rename Series',
									action: ['read', 'update'],
								},
								{
									resource: 'ImportSeries',
									display: 'Import New Series',
									action: ['read', 'update'],
								},
								{
									resource: 'PresentationState',
									display: 'Presentation State',
									action: [],
								},
							],
						},
						{
							resource: 'DocumentReview',
							display: 'Document Review',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'DocumentsReports',
									display: 'Documents & Reports',
									action: ['read', 'update'],
								},
								{
									resource: 'VoiceText',
									display: 'Voice to Text Tool',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'KeyImage',
							display: 'Key Image',
							action: ['read', 'update'],
						},
						{
							resource: 'AutoAssignUponOpen',
							display: 'Auto Assign Upon Open',
							action: [],
						},
					],
				},
				{
					resource: 'DocumentViewer',
					display: 'Document Viewer',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'ReportBrowser',
							display: 'Report Browser',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'PatientCard',
									display: 'Patient Card',
									action: ['read', 'update'],
								},
								{
									resource: 'DocumentList',
									display: 'Document List',
									action: ['read', 'update'],
								},
								{
									resource: 'TemplateList',
									display: 'Template List',
									action: ['read', 'update'],
								},
								{
									resource: 'DocumentFilters',
									display: 'Document Filters',
									action: ['read', 'update'],
								},
								{
									resource: 'UploadOption',
									display: 'Upload Option',
									action: ['read', 'update'],
								},
								{
									resource: 'ReportFilters',
									display: 'Report Filters',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'DocumentViewer',
							display: 'Document Viewer',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'TopToolbar',
									display: 'Top Toolbar',
									action: ['read', 'update'],
								},
								{
									resource: 'ShareOption',
									display: 'Share Option',
									action: ['read', 'update'],
								},
								{
									resource: 'ImageViewer',
									display: 'Image Viewer',
									action: ['read', 'update'],
								},
								{
									resource: 'AdditionalViewport',
									display: 'Additional Viewport',
									action: ['read', 'update'],
								},
								{
									resource: 'FloatingToolbar',
									display: 'Floating Toolbar',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'ReportEditor',
							display: 'Report Editor',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'TemplateCreation',
									display: 'Template Creation',
									action: ['read', 'update'],
								},
								{
									resource: 'Bookmarks',
									display: 'Bookmarks',
									action: ['read', 'update'],
								},
								{
									resource: 'ReportAutolaunch',
									display: 'Report Autolaunch',
									action: ['read', 'update'],
								},
								{
									resource: 'SignStudy',
									display: 'Sign Study',
									action: ['read', 'update'],
								},
								{
									resource: 'PublishTemplate',
									display: 'Publish Template',
									action: ['read', 'update'],
								},
								{
									resource: 'ChangeTemplate',
									display: 'Change Template',
									action: ['read', 'update'],
								},
								{
									resource: 'DeleteDocument',
									display: 'Delete Document',
									action: ['read', 'update'],
								},
								{
									resource: 'DeleteTemplate',
									display: 'Delete Template',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'AutoAssignUponOpen',
							display: 'Auto Assign Upon Open',
							action: [],
						},
					],
				},
				{
					resource: 'SignStudy',
					display: 'Sign Study',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'ProxySign',
							display: 'Proxy Sign',
							action: [],
						},
						{
							resource: 'Transcribe',
							display: 'Transcribe',
							action: [],
						},
					],
				},
				{
					resource: 'Send',
					display: 'Send',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'SendReports',
							display: 'Send Reports',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'SendMethod',
									display: 'Send Method',
									action: ['read', 'update'],
								},
								{
									resource: 'ReferringPhysician',
									display: 'Referring Physician',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'SendStudy',
							display: 'Send Study',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'Priority',
									display: 'Priority',
									action: ['read', 'update'],
								},
								{
									resource: 'ImagingOrganization',
									display: 'Imaging Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'Destination',
									display: 'Destination (Device)',
									action: ['read', 'update'],
								},
								{
									resource: 'ExternalUser',
									display: 'External User',
									action: ['read', 'update'],
								},
							],
						},
					],
				},
				{
					resource: 'StudyHistory',
					display: 'Study History',
					action: ['read', 'update'],
				},
				{
					resource: 'Worklist',
					display: 'Worklist',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'UserWorklist',
							display: 'User Worklist',
							action: ['read', 'update'],
						},
						{
							resource: 'RoleWorklist',
							display: 'Role Worklist',
							action: ['read', 'update'],
						},
						{
							resource: 'StudyAccess',
							display: 'Study Access',
							action: ['read', 'update'],
						},
						{
							resource: 'DeleteStudy',
							display: 'Delete Study',
							action: ['read', 'update'],
						},
						{
							resource: 'LinkAndMergePatient',
							display: 'Link & Merge Patient',
							action: ['read', 'update'],
						},
					],
				},
				{
					resource: 'VoiceRecognition',
					display: 'Voice Recognition',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Organizations',
			display: 'Organizations',
			action: ['read'],
			permissions: [
				{
					resource: 'OrganizationMapView',
					display: 'Organization Map View',
					action: ['read', 'update'],
				},
				{
					resource: 'OrganizationDirectory',
					display: 'Organization Directory',
					action: ['read', 'update'],
				},
				{
					resource: 'General',
					display: 'General',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'GeneralInformation',
							display: 'General Information',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'OrganizationName',
									display: 'Organization Name',
									action: ['read', 'update'],
								},
								{
									resource: 'ParentOrganization',
									display: 'Parent Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'OrganizationType',
									display: 'Organization Type',
									action: ['read', 'update'],
								},
								{
									resource: 'PracticeType',
									display: 'Practice Type',
									action: ['read', 'update'],
								},
								{
									resource: 'NPIOrganization',
									display: 'NPI/Organization',
									action: ['read', 'update'],
								},
								{
									resource: 'TimeZone',
									display: 'Time Zone',
									action: ['read', 'update'],
								},
								{
									resource: 'CorporateWebsite',
									display: 'Corporate Website',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'Address',
							display: 'Address',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'Country',
									display: 'Country',
									action: ['read', 'update'],
								},
								{
									resource: 'StateProvince',
									display: 'State/Province',
									action: ['read', 'update'],
								},
								{
									resource: 'ZipPostalCode',
									display: 'Zip/Postal Code',
									action: ['read', 'update'],
								},
								{
									resource: 'City',
									display: 'City',
									action: ['read', 'update'],
								},
								{
									resource: 'AddressLine1',
									display: 'Address Line 1',
									action: ['read', 'update'],
								},
								{
									resource: 'AddressLine2',
									display: 'Address Line 2',
									action: ['read', 'update'],
								},
							],
						},
						{
							resource: 'ContactInformation',
							display: 'Contact Information',
							action: ['read', 'update'],
							permissions: [
								{
									resource: 'Email',
									display: 'Email',
									action: ['read', 'update'],
								},
								{
									resource: 'Phone',
									display: 'Phone',
									action: ['read', 'update'],
								},
								{
									resource: 'Fax',
									display: 'Fax',
									action: ['read', 'update'],
								},
							],
						},
					],
				},
				{
					resource: 'IdentityProvider',
					display: 'Identity Provider (IdP)',
					action: ['read', 'update'],
				},
				{
					resource: 'Users',
					display: 'Users',
					action: ['read', 'update'],
				},
				{
					resource: 'BlumeForm',
					display: 'Blume Form',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'OrganizationalForms',
							display: 'Organizational Forms',
							action: ['read', 'update'],
						},
						{
							resource: 'BlumeForms',
							display: 'Blume Forms',
							action: ['read', 'update'],
						},
					],
				},
				{
					resource: 'Roles',
					display: 'Roles',
					action: ['read', 'update'],
				},
				{
					resource: 'HealthcareServices',
					display: 'Healthcare Services',
					action: ['read', 'update'],
				},
				{
					resource: 'StudyStatus',
					display: 'Study Statuses',
					action: ['read', 'update'],
				},
				{
					resource: 'WorkflowSteps',
					display: 'Workflow Steps',
					action: ['read', 'update'],
				},
				{
					resource: 'WorkflowConfiguration',
					display: 'Workflow Configuration',
					action: ['read', 'update'],
				},
				{
					resource: 'ImportScripts',
					display: 'Import Scripts',
					action: ['read', 'update'],
				},
				{
					resource: 'Device',
					display: 'Device',
					action: ['read', 'update'],
				},
				{
					resource: 'RIS',
					display: 'RIS',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'Procedure',
							display: 'Procedure',
							action: ['read', 'update'],
						},
						{
							resource: 'FeeSchedule',
							display: 'Fee Schedule',
							action: ['read', 'update'],
						},
						{
							resource: 'FinancialType',
							display: 'Financial Type',
							action: ['read', 'update'],
						},
						{
							resource: 'Payers',
							display: 'Payers',
							action: ['read', 'update'],
						},
						{
							resource: 'OrderSets',
							display: 'Order Sets',
							action: ['read', 'update'],
						},
					],
				},
				{
					resource: 'Delete',
					display: 'Delete',
					action: ['read', 'update'],
					permissions: [
						{
							resource: 'DeleteOrganization',
							display: 'Delete Organization',
							action: ['read', 'update'],
						},
						{
							resource: 'DeleteUser',
							display: 'Delete User',
							action: ['read', 'update'],
						},
						{
							resource: 'DeleteRole',
							display: 'Delete Role',
							action: ['read', 'update'],
						},
						{
							resource: 'DeleteDevice',
							display: 'Delete Device',
							action: ['read', 'update'],
						},
						{
							resource: 'DeleteWorkflowAutomation',
							display: 'Delete Workflow Automation',
							action: ['read', 'update'],
						},
						{
							resource: 'DeleteHealthcareService',
							display: 'Delete Healthcare Service',
							action: ['read', 'update'],
						},
						{
							resource: 'DeleteStudyStatus',
							display: 'Delete Study Status',
							action: ['read', 'update'],
						},
					],
				},
				{
					resource: 'StudyPriority',
					display: 'Study Priority',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Codes',
			display: 'Codes',
			action: ['read'],
			permissions: [
				{
					resource: 'ModalityCodes',
					display: 'Modality Codes',
					action: ['read', 'update'],
				},
				{
					resource: 'IssuerCodes',
					display: 'Issuer Codes',
					action: ['read', 'update'],
				},
				{
					resource: 'ConditionCodes',
					display: 'Condition Codes',
					action: ['read', 'update'],
				},
				{
					resource: 'DiagnosisCodes',
					display: 'Diagnosis Codes',
					action: ['read', 'update'],
				},
				{
					resource: 'ProcedureCodes',
					display: 'Procedure Codes',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Logs',
			display: 'Logs',
			action: ['read'],
			permissions: [
				{
					resource: 'ActivityHistory',
					display: 'Activity History',
					action: ['read', 'update'],
				},
				{
					resource: 'AuditLog',
					display: 'Audit Log',
					action: ['read', 'update'],
				},
				{
					resource: 'TaskLog',
					display: 'Task Log',
					action: ['read', 'update'],
				},
				{
					resource: 'FaxLog',
					display: 'Fax Log',
					action: ['read', 'update'],
				},
				{
					resource: 'Settings',
					display: 'Settings',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Search',
			display: 'Search',
			action: ['read'],
			permissions: [
				{
					resource: 'Study',
					display: 'Study',
					action: ['read', 'update'],
				},
				{
					resource: 'Patient',
					display: 'Patient',
					action: ['read', 'update'],
				},
				{
					resource: 'Order',
					display: 'Order',
					action: ['read', 'update'],
				},
				{
					resource: 'DICOMQuery',
					display: 'DICOM Query',
					action: ['read', 'update'],
				},
				{
					resource: 'Organization',
					display: 'Organization',
					action: ['read', 'update'],
				},
				{
					resource: 'User',
					display: 'User',
					action: ['read', 'update'],
				},
				{
					resource: 'Marketplace',
					display: 'Marketplace',
					action: ['read', 'update'],
				},
				{
					resource: 'Help',
					display: 'Help',
					action: ['read', 'update'],
				},
				{
					resource: 'EmergencySearch',
					display: 'Emergency Search',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Marketplace',
			display: 'Marketplace',
			action: ['read'],
			permissions: [
				{
					resource: 'Like',
					display: 'Like',
					action: ['read', 'update'],
				},
				{
					resource: 'Favorites',
					display: 'Favorites',
					action: ['read', 'update'],
				},
				{
					resource: 'Downloads',
					display: 'Downloads',
					action: ['read', 'update'],
				},
				{
					resource: 'WriteaReview',
					display: 'Write a Review',
					action: ['read', 'update'],
				},
				{
					resource: 'DownloadApp',
					display: 'Download App',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'AddNew',
			display: 'Add New',
			action: ['read'],
			permissions: [
				{
					resource: 'Order',
					display: 'Order',
					action: ['read'],
					permissions: [
						{
							resource: 'Priority',
							display: 'Priority',
							action: ['read', 'update'],
						},
						{
							resource: 'Organization',
							display: 'Organization',
							action: ['read', 'update'],
						},
						{
							resource: 'Patient',
							display: 'Patient',
							action: ['read', 'update'],
						},
						{
							resource: 'OrderSet',
							display: 'Order Set',
							action: ['read', 'update'],
						},
					],
				},
				{
					resource: 'Import',
					display: 'Import',
					action: ['read'],
					permissions: [
						{
							resource: 'ImportAction',
							display: 'Import Action',
							action: ['read', 'update'],
						},
						{
							resource: 'FieldEditing',
							display: 'Field Editing',
							action: ['read', 'update'],
						},
					],
				},
			],
		},
		{
			resource: 'DICOM',
			display: 'DICOM',
			action: ['read'],
			permissions: [
				{
					resource: 'DICOMSend',
					display: 'DICOM Send',
					action: ['read', 'update'],
				},
				{
					resource: 'DICOMRetrieve',
					display: 'DICOM Retrieve',
					action: ['read', 'update'],
				},
				{
					resource: 'DICOMWebStorage',
					display: 'DICOM Web Storage',
					action: ['read', 'update'],
				},
				{
					resource: 'DICOMWebRetrieve',
					display: 'DICOM Web Retrieve',
					action: ['read', 'update'],
				},
				{
					resource: 'DICOMWebSearch',
					display: 'DICOM Web Search',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Root',
			display: 'Root',
			action: ['read'],
			permissions: [
				{
					resource: 'ViewRegularReports',
					display: 'View Regular Reports',
					action: ['read', 'update'],
				},
				{
					resource: 'CreateEditReports',
					display: 'Create/Edit Reports',
					action: ['read', 'update'],
				},
				{
					resource: 'ViewPremiumReports',
					display: 'View Premium Reports',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Chat',
			display: 'Chat',
			action: ['read', 'update'],
			permissions: [
				{
					resource: 'DeleteChat',
					display: 'Delete Chat',
					action: ['read', 'update'],
				},
			],
		},
		{
			resource: 'Notification',
			display: 'Notification',
			action: ['read', 'update'],
		},
		{
			resource: 'Scheduler',
			display: 'Scheduler',
			action: ['read', 'update'],
		},
	],
};

export default defaultRamSoftPermission;
