import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import PatientCard from './PatientCard';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import getUserFullName, { parseFhirName } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';

import PatientInfo from './PatientInfo/PatientInfo';
import PatientDocument from './PatientDocument/PatientDocument';
import Notes from './Notes/Notes';

import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';
import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import { useGlobalNotesStore } from '@rs-ui/stores/notesStore';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useTranslation } from 'react-i18next';

const cardsInfo = ({ patientName, documentCount, notesCount, t }) => [
	{
		idx: 0,
		title: t('Notes'),
		code: 'NOTES',
		Component: props => <Notes {...props} />,
		color: '#42F59F',
		type: 'LIST',
		count: notesCount,
	},
	{
		idx: 1,
		title: t('Patient Documents'),
		code: 'DOCUMENTS',
		Component: props => <PatientDocument {...props} />,
		color: '#EB459E',
		type: 'LIST',
		count: documentCount,
	},
	{
		idx: 2,
		title: patientName,
		code: 'INFO',
		Component: props => <PatientInfo {...props} />,
		color: '#42A5F5',
		type: 'PATIENT_INFO',
	},
];

const updateOrAddCards = (existingCards, newCards) => {
	const updatedCards = [...existingCards];
	newCards.forEach(newCard => {
		const existingCardIndex = updatedCards.findIndex(card => card.code === newCard.code);
		if (existingCardIndex !== -1) {
			if (newCard.code === 'INFO') {
				updatedCards[existingCardIndex].title = newCard.title;
			} else {
				updatedCards[existingCardIndex].count = newCard.count;
			}
		} else {
			updatedCards.push(newCard);
		}
	});
	return updatedCards;
};

const CardContainerV2 = ({ rootHeight, resetHeight, handleUploadDocument }) => {
	// ==================== State ====================
	// DV Team Reference:Patient Info, patient document, and Notes are initialized in the useInitPatientInfoCards.jsx file.
	// Other teams have their own separate initialization calls.
	const { setSelectedCard, patientPermission } = usePatientInfoCardStore();
	const canViewPatientDetails = patientPermission?.read;
	const { patient, documents: patientDocuments } = usePatientStore();
	const { globalNotes } = useGlobalNotesStore();
	const galaxyDvGlobalNotes = useBooleanFlagValue('galaxy-dv-global-notes');
	const crossFormatPersonName = useBooleanFlagValue('cross-format-person-name');
	const { t } = useTranslation('patientInfo');
	const [cardsList, setCardsList] = useState([]);

	const userFullName = useMemo(
		() =>
			crossFormatPersonName
				? parseFhirName(patient?.patient?.name?.[0])
				: getUserFullName(patient?.patient?.name?.[0]),
		[patient, crossFormatPersonName]
	);

	useEffect(() => {
		const newCards = canViewPatientDetails
			? cardsInfo({
					patientName: userFullName,
					documentCount: patientDocuments?.length,
					notesCount: globalNotes?.length,
					t,
			  }).filter(card => card.code !== 'NOTES' || galaxyDvGlobalNotes)
			: cardsInfo({
					patientName: userFullName,
					documentCount: patientDocuments?.length,
					notesCount: globalNotes?.length,
					t,
			  }).filter(card => card.code === 'INFO');

		setCardsList(prevCardsList => updateOrAddCards(prevCardsList, newCards));
	}, [userFullName, patientDocuments, globalNotes, galaxyDvGlobalNotes, canViewPatientDetails]);

	const handleClick = index => {
		setSelectedCard(cardsList[index]);
		setCardsList(prevCardsList => {
			const updatedCardsList = [...prevCardsList];
			const [movedCard] = updatedCardsList.splice(index, 1);
			return [...updatedCardsList, movedCard];
		});
	};

	return (
		<TransitionGroup data-testid="card-container" id="patient-info-cont">
			{cardsList.map((card, orderIndex) => {
				const isSelected = orderIndex === cardsList.length - 1;

				return (
					<CSSTransition key={card.code} timeout={300}>
						<Box
							data-orderIndex={orderIndex}
							sx={cardStyles(isSelected, orderIndex, card.color, rootHeight)}
							onClick={() => handleClick(orderIndex)}
						>
							<PatientCard
								key={`${card.code}_${card.idx}`}
								cardData={card}
								handleUploadDocument={handleUploadDocument}
								isSelected={isSelected}
								resetHeight={resetHeight}
								rootHeight={rootHeight}
							/>
						</Box>
					</CSSTransition>
				);
			})}
		</TransitionGroup>
	);
};

export default CardContainerV2;

const cardStyles = (isSelected, index, color, rootHeight) => ({
	position: 'absolute',
	width: '100%',
	background: '#393939',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	transition: 'transform 0.3s ease-in-out, z-index 0.3s ease-in-out',
	borderRadius: isSelected ? '10px' : '10px 10px 0 0',
	borderTop: `2px solid ${color}`,
	transform: `translate(0, ${index * 30}px)`,
	zIndex: index + 1,
	'&:hover': !isSelected
		? {
				borderRadius: '10px',
				scale: '1.072',
				'& .patient-card-main': {
					display: 'block',
					height: `max(360px, ${rootHeight}px)`,
					maxHeight: `max(360px, ${rootHeight}px)`,
				},
				zIndex: 400,
		  }
		: {},
});
