import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const NotesListHeader = ({ isEditMode, onAddNote, onSave, onClose, t }) => (
	<Box
		sx={{
			display: 'flex',
			justifyContent: !isEditMode ? 'flex-start' : 'flex-end',
			padding: '15px',
		}}
	>
		{!isEditMode && (
			<Typography
				sx={{
					display: 'inline-flex',
					alignItems: 'center',
					fontSize: '12px',
					fontWeight: 400,
					fontFamily: 'Roboto',
					letterSpacing: '0.25px',
					color: 'rgba(255,255,255,0.87)',
					cursor: 'pointer',
					'&:hover': {
						color: '#fff',
					},
				}}
				onClick={onAddNote}
			>
				<AddCircleOutlineIcon sx={{ marginRight: '6px', fontSize: '16px' }} />
				{t('Add new note')}
			</Typography>
		)}

		{isEditMode && (
			<Box
				sx={{
					display: 'inline-flex',
					alignItems: 'center',
				}}
			>
				{/* Save Button */}
				<Tooltip data-testid="save-note-tooltip" placement="top" title={t('Save')}>
					<IconButton
						sx={{
							height: '15px',
							width: '15px',
							margin: 0,
							marginRight: '15px',
						}}
						onClick={onSave}
					>
						<SaveOutlinedIcon sx={{ opacity: 0.6, fontSize: '20px', '&:hover': { opacity: 1 } }} />
					</IconButton>
				</Tooltip>

				{/* Close button */}
				<Tooltip data-testid="discard-save-note" placement="top" title={t('Close')}>
					<IconButton
						sx={{
							height: '15px',
							width: '15px',
							margin: 0,
						}}
						onClick={onClose}
					>
						<CloseOutlinedIcon sx={{ opacity: 0.6, fontSize: '20px', '&:hover': { opacity: 1 } }} />
					</IconButton>
				</Tooltip>
			</Box>
		)}
	</Box>
);

export default NotesListHeader;
