import { formatBytes } from '@rs-core/utils/file';
import axios from 'axios';
import moment from 'moment';
import { isUnixSystem } from '@rs-core/utils/unixSystemDetector';

export const DOWNLOAD_STATES = {
	queued: 'queued',
	inProgress: 'inProgress',
	paused: 'paused',
	cancelled: 'cancelled',
	completed: 'completed',
	errored: 'errored',
};

class Event {
	constructor() {
		this.handlers = new Map();
		this.count = 0;
	}

	subscribe(handler) {
		this.handlers.set(++this.count, handler);
		return this.count;
	}

	unsubscribe(idx) {
		this.handlers.delete(idx);
	}

	fire(sender, args) {
		this.handlers.forEach((v, k) => v(sender, args));
	}
}

class PropertyChangedArgs {
	constructor(name, newValue) {
		this.name = name;
		this.newValue = newValue;
	}
}

export class Downloader {
	constructor(args) {
		const instance = this.constructor.instance;
		if (instance) {
			return instance;
		}
		this.constructor.instance = this;
		this._downloads = [];
		this.downloadProcessed = new Event();
	}

	get downloads() {
		return this._downloads;
	}

	set downloads(array) {
		this._downloads = array;
		this.downloadProcessed.fire(this, new PropertyChangedArgs('downloadQueueChanged', array));
	}

	toString() {
		let stringBuffer = '';
		this.downloads.forEach(download => {
			stringBuffer += download.toString() + '\n';
		});
		return stringBuffer;
	}

	enqueue(download) {
		const downloadInQueue = this.getDownload({ id: download.id });
		if (downloadInQueue) {
			return false;
		} else {
			this.downloads = this.downloads.concat([download]);
			download.onProcess.subscribe(this.handleProcess.bind(this));
			return true;
		}
	}

	getDownload({ id }) {
		return this.downloads.find(download => {
			return download.id === id;
		});
	}

	removeDownload({ id }) {
		this.downloads = this.downloads.filter(download => {
			return download.id !== id;
		});
	}

	getAllDownloadStatus() {
		const statusArray = [];
		this.downloads.forEach(download => {
			let status = {};
			status.displayName = download.displayName;
			status.fileName = download.fileName;
			status.state = download.state || download.downloadStatus?.state;
			status.progress = download.downloadStatus?.progress;
			status.loaded = download.downloadStatus?.loaded;
			status.total = download.downloadStatus?.total;
			status.instance = download;
			statusArray.push(status);
		});

		return statusArray;
	}

	handleProcess(sender, args) {
		if (args.name === 'state' || args.name === 'downloadStatus') {
			if (
				args.name === 'state' &&
				(args.newValue === DOWNLOAD_STATES.cancelled || args.newValue === DOWNLOAD_STATES.completed)
			) {
				this.removeDownload({ id: sender.id });
			}
			this.downloadProcessed.fire(
				this,
				new PropertyChangedArgs('downloadProcessed', this.getAllDownloadStatus())
			);
		}
	}
}

class Download {
	constructor(args) {
		const { uniqueId, downloadType } = args;
		this.downloadType = downloadType;
		this.id = uniqueId;
		this._state = DOWNLOAD_STATES.queued;
		this.onProcess = new Event();
		this._cancelTokenSource = axios.CancelToken.source();
		this._downloader = new Downloader();
	}

	toString() {
		return `fileName: ${this._fileName}. state: ${this.state}. status: ${this.downloadStatus?.progress}`;
	}

	get id() {
		return this._id;
	}

	set id(value) {
		this._id = this.downloadType + ': ' + value;
	}

	get displayName() {
		return this._displayName;
	}

	set displayName(displayName) {
		this._displayName = displayName;
	}

	get fileName() {
		return this._fileName;
	}

	set fileName(fileName) {
		this._fileName = fileName;
	}

	get url() {
		return this._url;
	}

	set url(url) {
		this._url = url;
	}

	get state() {
		return this._state;
	}

	set state(value) {
		this._state = value;
		this.onProcess.fire(this, new PropertyChangedArgs('state', value));
	}

	get downloadStatus() {
		return this._downloadStatus || {};
	}

	set downloadStatus(status) {
		let statusBuffer = {};
		statusBuffer.state = this.state;
		statusBuffer = { ...statusBuffer, ...status };

		this.onProcess.fire(this, new PropertyChangedArgs('downloadStatus', statusBuffer));

		this._downloadStatus = statusBuffer;
	}

	downloadBlobAsZip(blobData, fileName) {
		downloadBlobAsZipUtil(blobData, fileName);
	}

	generateDateString() {
		return generateDateString();
	}

	start() {
		if (this._downloader.enqueue(this)) {
			this.enqueueStatus = 'ENQUEUED';
		} else if (
			(this.enqueueStatus === 'ENQUEUED' || this.enqueueStatus === 'ENQUEUED-FOR-RETRY') &&
			this.state === DOWNLOAD_STATES.errored
		) {
			this.enqueueStatus = 'ENQUEUED-FOR-RETRY';
		} else {
			this.enqueueStatus = 'ALREADY-ENQUEUED';
		}
	}

	retry({ displayName, fileName, progressHandler, errorHandler, completeHandler } = {}) {
		if (this.state === DOWNLOAD_STATES.errored) {
			const params = {
				displayName: displayName || this.displayName,
				fileName: fileName || this._fileName,
				progressHandler: progressHandler || this._progressHandler,
				errorHandler: errorHandler || this._errorHandler,
				completeHandler: completeHandler || this._completeHandler,
			};
			this.start(params);
		}
	}

	cancel() {
		if (this.state === DOWNLOAD_STATES.errored) {
			this._downloader.removeDownload({ id: this.id });
		} else {
			this._cancelTokenSource.cancel('Download cancelled');
		}
	}
}

export class DownloadSingleStudy extends Download {
	constructor(args) {
		super({ ...args, downloadType: 'DownloadSingleStudy' });
		const { prefixUrl, dicomUid, internalManagingOrganizationId, anonymize = false } = args;
		this._url = this.constructGetUrl({ prefixUrl, dicomUid, internalManagingOrganizationId, anonymize });
	}

	constructGetUrl({ prefixUrl, dicomUid, internalManagingOrganizationId, anonymize = false }) {
		return `${prefixUrl}/export/studies/${dicomUid}?internalmanagingorganizationid=${internalManagingOrganizationId}&FileType=zip&anonymize=${anonymize}`;
	}

	start({ displayName, fileName, progressHandler, errorHandler, completeHandler } = {}) {
		super.start();
		if (this.enqueueStatus === 'ALREADY-ENQUEUED' || this.state === DOWNLOAD_STATES.inProgress) {
			return this;
		}

		this._progressHandler = progressHandler;
		this._errorHandler = errorHandler;
		this._completeHandler = completeHandler;

		this.displayName = displayName;

		this.state = DOWNLOAD_STATES.inProgress;

		axios
			.get(this._url, {
				responseType: 'blob',
				headers: { 'unix-os': isUnixSystem() }, // Zip max compatibility
				cancelToken: this._cancelTokenSource.token,
				onDownloadProgress: progressEvent => {
					const { loaded, total } = progressEvent;
					this.downloadStatus = {
						progress: Math.round((loaded * 100) / total),
						loaded: formatBytes(loaded),
						total: formatBytes(total),
					};
					progressHandler && progressHandler(progressEvent);
				},
			})
			.then(res => {
				if (res.status === 200) {
					const disposition = res.headers['content-disposition'];
					const rsFileName = disposition && disposition.slice(disposition.indexOf('=') + 1);
					this._fileName = fileName || rsFileName;

					if (res.data.type === 'application/zip') {
						this.downloadBlobAsZip(res.data, this._fileName);
					}
					this.state = DOWNLOAD_STATES.completed;
					completeHandler && completeHandler();
				} else {
					throw new Error('Something went wrong');
				}
			})
			.catch(err => {
				if (axios.isCancel(err)) {
					this.state = DOWNLOAD_STATES.cancelled;
				} else {
					this.state = DOWNLOAD_STATES.errored;
					console.log('error in downlaod: ', err);
					errorHandler && errorHandler(err);
				}
			})
			.finally(() => {});

		return this;
	}
}

export class DownloadMultipleStudies extends Download {
	constructor(args) {
		super({ ...args, downloadType: 'DownloadMultipleStudies' });
		const { prefixUrl, anonymize = false, studyIds, internalManagingOrganizationIds } = args;
		this._url = `${prefixUrl}/export/studies?FileType=application%2Fzip&anonymize=${anonymize}`;
		this._studyIds = studyIds || [];
		this._internalManagingOrganizationIds = internalManagingOrganizationIds;
	}

	start({ displayName, fileName, progressHandler, errorHandler, completeHandler } = {}) {
		super.start();
		if (this.enqueueStatus === 'ALREADY-ENQUEUED' || this.state === DOWNLOAD_STATES.inProgress) {
			return this;
		}

		this._progressHandler = progressHandler;
		this._errorHandler = errorHandler;
		this._completeHandler = completeHandler;

		this.displayName = displayName || this._studyIds.join(', ');

		this.state = DOWNLOAD_STATES.inProgress;

		axios
			.post(
				this._url,
				{
					studyIds: this._studyIds,
					internalManagingOrganizationIds: this._internalManagingOrganizationIds,
				},
				{
					responseType: 'blob',
					headers: { 'unix-os': isUnixSystem() }, // Zip max compatibility
					cancelToken: this._cancelTokenSource.token,
					onDownloadProgress: progressEvent => {
						const { loaded, total } = progressEvent;
						this.downloadStatus = {
							progress: Math.round((loaded * 100) / total),
							loaded: formatBytes(loaded),
							total: formatBytes(total),
						};
						progressHandler && progressHandler(progressEvent);
					},
				}
			)
			.then(res => {
				if (res.status === 200) {
					const defaultFileName = this._studyIds.length + ' Studies - ' + generateDateString();
					this._fileName = fileName || defaultFileName;

					if (res.data.type === 'application/zip') {
						this.downloadBlobAsZip(res.data, this._fileName);
					}
					this.state = DOWNLOAD_STATES.completed;
					completeHandler && completeHandler();
				} else {
					throw new Error('Something went wrong');
				}
			})
			.catch(err => {
				if (axios.isCancel(err)) {
					this.state = DOWNLOAD_STATES.cancelled;
				} else {
					this.state = DOWNLOAD_STATES.errored;
					console.log('error in downlaod: ', err);
					errorHandler && errorHandler(err);
				}
			})
			.finally(() => {});

		return this;
	}
}

export class DownloadDiscImageBurner extends Download {
	constructor(args) {
		super({ ...args, downloadType: 'DownloadDiscImageBurner' });
		const { authUrl, accessionNumber, studyIds, internalManagingOrganizationIds, isBlumeApp } = args;

		if (isBlumeApp) this._url = `${authUrl}Study/discImageBurner`;
		else this._url = `${authUrl}/Portal/DiscImageBurner`;
		this._accessionNumber = accessionNumber;
		this._studyIds = Array.isArray(studyIds) ? studyIds : [studyIds];
		this._internalManagingOrganizationIds = Array.isArray(internalManagingOrganizationIds)
			? internalManagingOrganizationIds
			: [internalManagingOrganizationIds];
	}

	start({ displayName, fileName, progressHandler, errorHandler, completeHandler } = {}) {
		super.start();
		if (this.enqueueStatus === 'ALREADY-ENQUEUED' || this.state === DOWNLOAD_STATES.inProgress) {
			return this;
		}

		this._progressHandler = progressHandler;
		this._errorHandler = errorHandler;
		this._completeHandler = completeHandler;

		this.displayName = displayName || this._studyIds.join(', ');

		this.state = DOWNLOAD_STATES.inProgress;

		axios
			.post(
				this._url,
				{
					studyIds: this._studyIds,
					internalManagingOrganizationIds: this._internalManagingOrganizationIds,
				},
				{
					responseType: 'blob',
					headers: { 'unix-os': isUnixSystem() }, // Zip max compatibility
					cancelToken: this._cancelTokenSource.token,
					onDownloadProgress: progressEvent => {
						const { loaded, total } = progressEvent;
						this.downloadStatus = {
							progress: Math.round((loaded * 100) / total),
							loaded: formatBytes(loaded),
							total: formatBytes(total),
						};
						progressHandler && progressHandler(progressEvent);
					},
				}
			)
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					const objectUrl = window.URL.createObjectURL(
						new Blob([response.data], {
							type: response.headers['content-type'],
						})
					);
					const disposition = response.headers['content-disposition'];
					const attachment = disposition && disposition.slice(disposition.indexOf('=') + 1);
					const rsFileName = attachment && attachment.slice(0, attachment.indexOf(';'));
					this._fileName = fileName || rsFileName;

					// All disc image burner will have the Ramsoft. prefix
					if (!this._fileName?.toLowerCase()?.startsWith('ramsoft.')) {
						this._fileName = `RamSoft.${this._fileName}`;
					}

					// Add accession number to file name
					if (this._accessionNumber) {
						const name = this._fileName.slice(0, this._fileName.lastIndexOf('.'));
						const extension = this._fileName.slice(this._fileName.lastIndexOf('.'));

						this._fileName = name + '-' + this._accessionNumber + extension;
					}

					let link = document.createElement('a');
					link.href = objectUrl;
					link.download = this._fileName;
					link.click();
					link.remove();
					this.state = DOWNLOAD_STATES.completed;
					completeHandler && completeHandler();

					setTimeout(function () {
						window.URL.revokeObjectURL(objectUrl);
					}, 15000);
				} else {
					throw Error(response.message);
				}
			})
			.catch(err => {
				if (axios.isCancel(err)) {
					this.state = DOWNLOAD_STATES.cancelled;
				} else {
					this.state = DOWNLOAD_STATES.errored;
					console.log('error in downlaod: ', err);
					errorHandler && errorHandler(err);
				}
			})
			.finally(() => {});

		return this;
	}
}

// Function to trigger download without using window or document
export const downloadBlobAsZipUtil = (blobData, fileName) => {
	const blobUrl = URL.createObjectURL(blobData);

	// Create an anchor element
	const anchor = document.createElement('a');
	anchor.href = blobUrl;
	anchor.download = fileName || 'download.zip';

	// Programmatically click the anchor element
	anchor.click();

	// Clean up by revoking the blob URL
	URL.revokeObjectURL(blobUrl);
};

const generateDateString = () => {
	return moment(new Date()).format('MM-DD-YYYY_HH-mm-ss-A');
};
