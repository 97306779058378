/* Generated automagically by FhirStarter - DO NOT EDIT */
import AuditEventResource from './AuditEventResource';
import ImagingStudyResource from './ImagingStudyResource';
import MedicationResource from './MedicationResource';
import OrganizationResource from './OrganizationResource';
import OrganizationAffiliationResource from './OrganizationAffiliationResource';
import PatientResource from './PatientResource';
import ImagingStudyWorklistResource from './ImagingStudyWorklistResource';
import PatientActivityResource from './PatientActivityResource';
import WorkListLayoutResource from './WorkListLayoutResource';
import TaskResource from './TaskResource';
import FaxResource from './FaxResource';
import DeviceResource from './DeviceResource';
import EncounterResource from './EncounterResource';
import AssigningAuthorityResource from './AssigningAuthorityResource';
import WorkflowStepResource from './WorkflowStepResource';
import WorkflowAutomationResource from './WorkflowAutomationResource';
import ServiceRequestResource from './ServiceRequestResource';
import PractitionerResource from './PractitionerResource';
import ReportResource from './ReportResource';
import BlumeFormResource from './BlumeFormResource';
import PriorAuthorizationResource from './PriorAuthorizationResource';
import PractitionerRoleResource from './PractitionerRoleResource';

import InvoiceResource from './InvoiceResource';
import ProcedureCodeResource from './ProcedureCodeResource';
import StudySetResource from './StudySetResource';
import FeeScheduleResource from './FeeScheduleResource';
import ImagingStudyWorklistGroupResource from './ImagingStudyWorklistGroupResource';

const dict = {
	AuditEvent: AuditEventResource,
	ImagingStudy: ImagingStudyResource,
	ServiceRequest: ServiceRequestResource,
	Encounter: EncounterResource,
	Medication: MedicationResource,
	Organization: OrganizationResource,
	OrganizationAffiliation: OrganizationAffiliationResource,
	Patient: PatientResource,
	ImagingStudyWorklist: ImagingStudyWorklistResource,
	PatientActivity: PatientActivityResource,
	WorkListLayout: WorkListLayoutResource,
	Task: TaskResource,
	Fax: FaxResource,
	Device: DeviceResource,
	AssigningAuthority: AssigningAuthorityResource,
	WorkflowStep: WorkflowStepResource,
	WorkflowAutomation: WorkflowAutomationResource,
	Practitioner: PractitionerResource,
	Report: ReportResource,
	BlumeForm: BlumeFormResource,
	PriorAuthorization: PriorAuthorizationResource,
	PractitionerRole: PractitionerRoleResource,
	Invoice: InvoiceResource,
	ProcedureCode: ProcedureCodeResource,
	StudyType: StudySetResource,
	FeeSchedule: FeeScheduleResource,
	ImagingStudyWorklistGroup: ImagingStudyWorklistGroupResource,
};

export const getNewResource = (resourceType, rawData) => {
	return new dict[resourceType](rawData);
};
