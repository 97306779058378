import axios from 'axios';

const getStudyShareableLink = async ({ __config, studyId, patientId }) => {
	const response = await axios.get(`${__config.data_sources.blume}Study/studylink/${studyId}?patientId=${patientId}`);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getStudyShareableLink;
