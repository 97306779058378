import React from 'react';
import { PropTypes } from 'prop-types';

import { mediaTablet } from '@rs-core/utils/responsiveUtils';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import { IconButton } from '@mui/material';
import EmergencySearchIcon from '@rs-ui/assets/icons/GlobalSearch/EmergencySearchIcon';

const EmergencySearch = ({ setEmergencySearchOpen, t }) => (
	<Box
		sx={{
			display: 'flex',
			padding: '15px',
			borderRadius: '8px',
			border: 'none',
			background: '#444343',
			width: 'auto',
			alignItems: 'center',
			margin: '13px',
			cursor: 'pointer',
		}}
		onClick={() => setEmergencySearchOpen(true)}
	>
		<ListItemAvatar>
			<div data-testid="check-btn" id="avatarWrapper" style={{ position: 'relative' }}>
				<Avatar
					sx={{
						bgcolor: '#7e7e7e',
						width: '37.5px',
						height: '37.5px',
					}}
				>
					<EmergencySearchIcon />
				</Avatar>
			</div>
		</ListItemAvatar>
		<ListItemText
			className="list-item"
			primary={
				<span
					style={{
						display: 'block',
						color: '#ddd',
						fontWeight: '300px',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}
				>
					{t('Emergency Access')}
				</span>
			}
			sx={{
				[mediaTablet]: {
					'& .MuiListItemText-primary': {
						fontSize: '14px',
						lineHeight: '20px',
						letterSpacing: '0.25px',
					},
				},
			}}
		/>
		<IconButton>
			<ChevronRightOutlinedIcon />
		</IconButton>
	</Box>
);

EmergencySearch.propTypes = {
	setEmergencySearchOpen: PropTypes.func,
	t: PropTypes.func,
};

export default EmergencySearch;
