import React, { createContext, useContext, useEffect, useState } from 'react';
import useFhirAPI from '@worklist-2/core/src/hooks/useFhirAPI';
import { useAuth } from '@worklist-2/core/src/context/UserAuthContext';
import { faxIsReadOnly, FAX_STATUSES } from '@rs-core/functions/fax';
import fhirExtensionUrls from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const FaxContext = createContext({
	faxes: [],
	selectedFax: null,
	extractedData: null,
});

export async function getPDFURL(fileStream) {
	const fileBlob = new Blob([fileStream], { type: 'application/pdf' });
	const pdfURL = URL.createObjectURL(fileBlob);
	return pdfURL;
}

const EXTRACTED_FIELDS = {
	patient_name: 'patientFullName',
	patient_dob: 'dateOfBirthday',
	patient_sex: 'sex',
	patient_SSN: 'snn',
	patient_phone: 'phoneNumber',
	patient_address: 'address',
	ordering_provider: 'orderingProvider',
	physician_info: 'physicianInfo',
	physician_npi: 'physicianNpi',
	study_kind: 'studyKind',
	insurance_name: 'insuranceName',
	primary_insurance: 'primaryInsurance',
	subscriber_id: 'subscriberId',
};

const FaxProvider = ({ children }) => {
	const [faxes, setFaxes] = useState([]);
	const [selectedFax, setSelectedFax] = useState(null);
	const [selectedFaxLog, setSelectedFaxLog] = useState(null);
	const [extractedData, setExtractedData] = useState(null);
	const [openAddToDrawer, setOpenAddToDrawer] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [dataGridRefValue, setDataGridRefValue] = useState(null);
	const [activeDocument, setActiveDocument] = useState('');
	const [orderFormOpen, setOrderFormOpen] = useState(false);

	const {
		headers,
		downloadFax,
		deleteFax,
		fetchFaxToOrderList,
		updateFaxEntry,
		fetchPatientsFromOrganization,
		fetchStudiesSet,
		fetchReferringPhysician,
		fetchInsurancePayerFromOrganization,
	} = useFhirAPI();
	const metaEnableAiInNewOrder = useBooleanFlagValue('meta-enable-ai-in-new-order');
	const { lastOrderOrganization } = useAuth();
	const sendSelectedFaxToOrderWorkflow = async () => {
		if (!selectedFaxLog || selectedFaxLog.status === FAX_STATUSES.toNewOrder) return;

		const { apiRawData, messageId } = selectedFaxLog;
		await updateFaxEntry(messageId, { ...apiRawData, status: FAX_STATUSES.toNewOrder });

		fetchIncomingFaxList();
		setSelectedFaxLog(null);
	};

	const attachSelectedFaxDocument = async (selectedPatient, selectedCategory, selectedStudy, activeDocumentType) => {
		if (!selectedFaxLog) return;
		const documentPayload = {
			patient: {
				id: selectedPatient.id,
				reference: `Patient/${selectedPatient.id}`,
				display: selectedPatient.label,
			},
			category: {
				code: selectedCategory.id,
				display: selectedCategory.label,
			},
			documentType: activeDocumentType === 'Patient Document' ? 'Patient' : 'Study',
			status:
				activeDocumentType === 'Patient Document'
					? FAX_STATUSES.attachedWithPatient
					: FAX_STATUSES.attachedWithStudy,
			patient_display: selectedPatient.label,
		};

		if (selectedStudy?.id) {
			documentPayload.study = {
				id: `${selectedStudy.id}`,
				reference: `ImagingStudy/${selectedStudy.id}`,
				display: selectedStudy.label,
			};
			documentPayload.study_display = selectedStudy.label;
		}
		await updateFaxEntry(selectedFaxLog.messageId, {
			...selectedFaxLog.apiRawData,
			...documentPayload,
		});
	};

	const selectConfidentAnswer = (firstItem, secondItem) => {
		if (firstItem && secondItem) {
			return firstItem.confident > secondItem.confident ? firstItem?.value : secondItem?.value;
		}

		return firstItem?.value || secondItem?.value;
	};

	const onDeleteFax = async () => {
		const faxData = dataGridRefValue?.current?.getRowData('id', selectedFaxLog.id);
		const updatedData = await deleteFax(faxData.rawData);
		if (updatedData.status === 200) {
			setSelectedFaxLog(null);
			dataGridRefValue?.current?.insertUpdateRow(updatedData.data);
		}
	};

	const getFaxExtractedValues = fax => {
		if (!metaEnableAiInNewOrder) {
			return {};
		}
		let data = {};
		const rowValues = fax?.extension?.find(item => item?.url?.includes('amazonTextractAnswer'))?.valueString;
		if (rowValues) {
			data =
				JSON.parse(rowValues)?.reduce((result, item) => {
					if (EXTRACTED_FIELDS[item?.Alias] && item?.Confidence >= 80) {
						if (item?.Alias === 'ordering_provider' || item?.Alias === 'physician_info') {
							result[EXTRACTED_FIELDS[item.Alias]] = { value: item?.Answer, confident: item?.Confidence };
						} else {
							result[EXTRACTED_FIELDS[item.Alias]] = item?.Answer;
						}
					}

					return result;
				}, {}) || {};
		}

		if (data.patientFullName) {
			if (data.patientFullName.includes(',')) {
				data.patientLastName = data.patientFullName.split(',')[0].trim();
				data.patientFirstName = data.patientFullName.split(',')[1].trim();
			} else if (data.patientFullName.split().length >= 2) {
				data.patientLastName = data.patientFullName.split()[1].trim();
				data.patientFirstName = data.patientFullName.split()[0].trim();
			} else {
				data.patientLastName = data.patientFullName.trim();
			}
		}

		return data;
	};

	const fetchIncomingFaxList = async organizationId => {
		const incomingFaxesToOrder = await fetchFaxToOrderList(organizationId);
		const parsedFaxes = incomingFaxesToOrder.map((fax, index) => ({
			id: fax.id,
			order: index,
			messageId: fax.messageId,
			sender: fax.faxFrom,
			organizationId: fax.organizationId,
			extracted: getFaxExtractedValues(fax),
		}));
		setFaxes(parsedFaxes);
	};

	const parseSelectedFaxData = fax => {
		setSelectedFax({
			id: fax.id,
			messageId: fax.messageId,
			sender: fax.faxFrom,
			organizationId: fax.apiRawData.organizationId,
			extracted: getFaxExtractedValues(fax.apiRawData),
		});
	};
	useEffect(() => {
		if (lastOrderOrganization?.id) {
			fetchIncomingFaxList(lastOrderOrganization.id); // will default to last selected one at first.
		}
	}, [lastOrderOrganization]);

	useEffect(() => {
		if (!selectedFax) return;

		async function onSelectFax() {
			const faxHasPDF = selectedFax.url || selectedFax.headers;
			if (selectedFax.messageId && !faxHasPDF) {
				const fileStream = await downloadFax(selectedFax.id);
				const pdfURL = await getPDFURL(fileStream);
				if (pdfURL) {
					const fax = {
						...selectedFax,
						url: pdfURL,
						headers,
					};
					setSelectedFax(fax);
					setFaxes(faxes =>
						[...faxes.filter(faxItem => faxItem.messageId !== fax.messageId), fax].sort(
							(a, b) => a.order - b.order
						)
					);
				}
			}
		}
		onSelectFax();
	}, [selectedFax, headers, downloadFax]);

	useEffect(() => {
		if (!selectedFaxLog || selectedFaxLog.readOnly !== undefined) return;

		async function onSelectFaxLog() {
			let faxLog = {
				...selectedFaxLog,
			};
			const faxHasPDF = selectedFaxLog.url || selectedFaxLog.headers;
			if (selectedFaxLog.messageId && !faxHasPDF) {
				const fileStream = await downloadFax(selectedFaxLog.id);
				const pdfURL = await getPDFURL(fileStream);
				if (pdfURL) {
					faxLog = {
						...faxLog,
						url: pdfURL,
						headers,
					};
				}
			}
			faxLog.readOnly = faxIsReadOnly(faxLog);
			setSelectedFaxLog(faxLog);
		}

		onSelectFaxLog();
	}, [selectedFaxLog, headers, downloadFax]);

	useEffect(() => {
		const fetchFaxExtracted = async () => {
			const extractedFaxData = selectedFax.extracted;
			const extractedFields = ['patient', 'referringPhysician', 'studiesSet', 'insurance'];
			const referringPhysicianExtractData = selectConfidentAnswer(
				extractedFaxData.orderingProvider,
				extractedFaxData.physicianInfo
			);
			const rawData = await Promise.all([
				extractedFaxData.patientFullName
					? fetchPatientsFromOrganization(undefined, selectedFax.organizationId, 50, {
							patientFirstName: extractedFaxData.patientFirstName,
							patientLastName: extractedFaxData.patientLastName,
							snn: extractedFaxData?.snn,
							birthDate: extractedFaxData?.dateOfBirthday,
					  })
					: [],
				referringPhysicianExtractData
					? fetchReferringPhysician(
							referringPhysicianExtractData,
							extractedFaxData?.physicianNpi,
							selectedFax.organizationId
					  )
					: [],
				extractedFaxData.studyKind
					? fetchStudiesSet(extractedFaxData.studyKind, selectedFax.organizationId)
					: [],
				extractedFaxData.primaryInsurance
					? fetchInsurancePayerFromOrganization(extractedFaxData.primaryInsurance)
					: [],
			]);

			const data = rawData.reduce((result, item, index) => {
				if (!Array.isArray(item) && item.total === 1) {
					result[extractedFields[index]] = item.entry[0].resource;
				}

				return result;
			}, {});

			if (data.insurance) {
				const { insurance } = data;
				const result = {
					id: insurance.id,
					reference: `${insurance.resourceType}/${insurance.id}`,
					display: insurance.name,
					extension: [],
				};

				['payerID', 'payerSource', 'eligibilityPayerID'].forEach(extKey => {
					const extension = item.resource.extension?.find(
						e => e.url === fhirExtensionUrls.organization[extKey]
					);
					if (extension) {
						result.extension.push(extension);
					}
				});

				data.insurance = result;
			}

			setExtractedData({ ...selectedFax.extracted, ...data });
		};

		if (!metaEnableAiInNewOrder) {
			return;
		}

		if (selectedFax) {
			fetchFaxExtracted();
		} else {
			setExtractedData(null);
		}
	}, [selectedFax]);

	return (
		<FaxContext.Provider
			value={{
				faxes,
				isLoading,
				selectedFax,
				selectedFaxLog,
				extractedData,
				openAddToDrawer,
				dataGridRefValue,
				activeDocument,
				setActiveDocument,
				setDataGridRefValue,
				setOpenAddToDrawer,
				setFaxes,
				setIsLoading,
				setSelectedFax,
				setSelectedFaxLog,
				sendSelectedFaxToOrderWorkflow,
				attachSelectedFaxDocument,
				fetchIncomingFaxList,
				parseSelectedFaxData,
				onDeleteFax,
				orderFormOpen,
				setOrderFormOpen,
				getPDFURL,
			}}
		>
			{children}
		</FaxContext.Provider>
	);
};

export const useFaxContextProvider = () => useContext(FaxContext);
export default FaxProvider;
