import React, { useMemo, useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DrawerTabPanel from './DrawerTabPanel';
import ColumnBuilder from '@worklist-2/ui/src/components/ColumnBuilder';
import getColumnMapping from '@rs-core/fhir/resource/columnMapping/getColumnMapping';
import { logDebug } from '@rs-core/utils/logger';
import { Filters, Sort } from './DrawerTabPanelContents/WorklistSettings';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const allyProps = (index, tabId, tabPanelId) => ({
	id: `${tabId}-${index}`,
	'aria-controls': `${tabPanelId}-${index}`,
});

export const getColumnsList = (columnMapping, translateFn, i18nfile) => {
	const columnsList = [];

	_.forEach(_.keys(columnMapping), column => {
		if (columnMapping[column].options?.viewColumns !== false) {
			columnsList.push({
				label:
					columnMapping[column].label && i18nfile == 'workList'
						? translateFn(`imagingWorkListColumns.${column}`, columnMapping[column].label)
						: translateFn(columnMapping[column].label),
				name: column,
				filterType: columnMapping[column].filterType,
				options: columnMapping[column].options,
				valueSet: columnMapping[column].searchValueSet,
				suggestScope: columnMapping[column].suggestScope,
				searchParameter: columnMapping[column].searchParameter,
				customFilterOptions: columnMapping[column].customFilterOptions,
			});
		}
		logDebug('DrawerTabSettings', `Column ${column} added to columnsList`, { columnMapping, columnsList });
		logDebug('DrawerTabSettings', `is i18nfile defined at this moment?`, { i18nfile });
		logDebug(
			'DrawerTabSettings',
			`Suspect Number 1: is translateFn defined at this moment? testing with: ${columnMapping[column].label}: `,
			{
				first_method: translateFn(columnMapping[column].label),
				second_method: translateFn(`imagingWorkListColumns.${column}`),
				combined_method: translateFn(
					[`imagingWorkListColumns.${column}`, columnMapping[column].label],
					'undefined'
				),
				old_method: translateFn(`imagingWorkListColumns.${column}`, columnMapping[column].label),
			}
		);
	});

	return columnsList;
};

const DrawerTabSettings = ({
	onChange,
	endpoint,
	isLarge = false,
	userSavedColumnList,
	filters,
	disableAddButton,
	columnBuilderHeight,
	filterHeight,
	sortHeight,
	componentColumnList,
	setComponentColumnList,
	isWorklist = false,
}) => {
	const [value, setValue] = useState(0);
	const [componentSortList, setComponentSortList] = useState([]);
	const savedColumnList = _.cloneDeep(userSavedColumnList);
	const [preConfigColumnList, setPreConfigColumnList] = useState(savedColumnList);

	const tempColumnList = _.filter(savedColumnList, item => {
		if (item.name !== '' && item.sort && item.sort !== 'none') {
			return item;
		}
	});
	const tempPreConfigColumnList = _.filter(savedColumnList, item => {
		if (item.name !== '') {
			return item;
		}
	});
	useEffect(() => {
		setPreConfigColumnList(savedColumnList.length === 0 ? [] : tempPreConfigColumnList);
		setComponentSortList(savedColumnList.length === 0 ? [] : _.orderBy(tempColumnList, ['sortOrder'], ['asc']));
		setComponentColumnList(savedColumnList.length === 0 ? [] : tempPreConfigColumnList);
	}, [userSavedColumnList]);
	const columnMapping = useMemo(() => getColumnMapping(endpoint), [endpoint]);

	let i18nfile = endpoint;

	if (endpoint == 'AuditEvent' || endpoint == 'ActivityHistory' || endpoint == 'task') {
		i18nfile = 'log';
	} else if (endpoint == 'ImagingStudyWorklist/elk' || !endpoint) i18nfile = 'workList';

	const { t } = useTranslation(i18nfile);
	logDebug('DrawerTabSettings', `what is the value of i18nfile on line 110 at component mount?`, { i18nfile });

	const columnList = useMemo(() => getColumnsList(columnMapping, t, i18nfile), [columnMapping, t, i18nfile]);

	const handleChange = useCallback((event, newValue) => {
		setValue(newValue);
	}, []);

	const selectedColumnsArray = (columnArray, props) => {
		const list = columnArray;
		if (onChange) {
			const listTemp = _.filter(columnArray, column => column.name !== '');
			updateForm(listTemp);
		}

		// Will not update Column Name list if only drag/drop column
		if (!props?.dragItem) {
			setPreConfigColumnList([...list]);
		}
	};

	const selectedColumnsArrayFilter = columnArray => {
		const updatedPreConfigColumnList = [];
		_.map(preConfigColumnList, item => {
			const filterItem = _.find(columnArray, innerItem => innerItem.name === item.name);
			item.filter = filterItem ? filterItem.filter : '';
			item.displayValue = filterItem ? filterItem.displayValue : '';
			updatedPreConfigColumnList.push(item);
		});
		updateForm(updatedPreConfigColumnList);
	};

	const selectedColumnsArraySort = columnArray => {
		const updatedPreConfigColumnList = [];
		const allColumns = [];
		_.map(preConfigColumnList, item => {
			const sortIndex = _.findIndex(columnArray, innerItem => item.name !== '' && innerItem.name === item.name);
			item.sort = sortIndex != -1 ? columnArray[sortIndex].sort : 'none';
			item.sortOrder = sortIndex != -1 ? sortIndex : null;
			if (item.name !== '') {
				updatedPreConfigColumnList.push(item);
			}
			allColumns.push(item);
		});
		setPreConfigColumnList([...allColumns]);
		updateForm(updatedPreConfigColumnList);
	};

	const updateForm = updatedList => {
		onChange(updatedList);
	};

	const onChangeDelete = columnArray => {
		const list = [];
		_.map(componentSortList, item => {
			let innerItem = _.find(columnArray, innerItem => innerItem.name === item.name && innerItem.name !== '');
			if (innerItem) {
				const itemId = item.id;
				innerItem = item;
				innerItem.id = itemId;
				list.push(innerItem);
			}
		});
		setComponentSortList(list);
	};

	const tabNames = useMemo(() => ['COLUMNS', 'FILTERS', 'SORT'], []);

	const tabPanelContents = [
		<ColumnBuilder
			key="workListdrawer-column"
			columnList={columnList}
			componentColumnList={componentColumnList}
			disableAddButton={disableAddButton}
			height={columnBuilderHeight}
			isLarge={isLarge}
			isWorklist={isWorklist}
			preConfiguredColumnList={preConfigColumnList}
			setComponentColumnList={setComponentColumnList}
			width={390}
			onChange={selectedColumnsArray}
			onChangeDelete={onChangeDelete}
		/>,
		<Filters
			key="workListdrawer-filters"
			columnList={columnList}
			filters={filters}
			height={filterHeight}
			isLarge={isLarge}
			placeholder={t('search')}
			preConfiguredColumnList={preConfigColumnList}
			width={390}
			onChange={selectedColumnsArrayFilter}
		/>,
		<Sort
			key="workListdrawer-sort"
			componentSortList={componentSortList}
			disableAddButton={disableAddButton}
			height={sortHeight}
			isLarge={isLarge}
			isWorklist={isWorklist}
			label={t('workListDrawer.columnName')}
			preConfiguredColumnList={preConfigColumnList}
			setComponentSortList={setComponentSortList}
			translateFn={t}
			width={390}
			onChange={selectedColumnsArraySort}
		/>,
	].map((element, ind) => React.cloneElement(element, { key: tabNames[ind] }));

	const tabId = 'worklist-settings-tab';
	const tabPanelId = 'worklist-settings-tabpanel';

	const PopulatedTabs = ({ tabNames, value, handleChange, tabId, tabPanelId }) => (
		<Box
			data-testid={endpoint}
			sx={{
				width: '370px',
				mx: 'auto',
			}}
		>
			<Tabs
				aria-label="Worklist Settings Tabs"
				sx={{
					'& .MuiTabs-indicator': {
						backgroundColor: 'rsPrimary.main',
					},
				}}
				value={value}
				onChange={handleChange}
			>
				{tabNames.map((tabName, ind) => (
					<Tab
						key={tabName}
						data-cy={`${tabName}_WorklistSpeedDial`}
						data-testid={`${tabName}_WorklistSpeedDial`}
						label={t(tabName.toLowerCase())}
						sx={{
							textTransform: 'none',
							minWidth: 0,
							marginLeft: 4,
							'&:first-child': {
								marginRight: '35px',
								marginLeft: '0px',
							},
							'&:hover': {
								color: 'rsPrimary.main',
								opacity: 1,
							},
							'&.Mui-selected': {
								color: 'rsPrimary.main',
							},
						}}
						{...allyProps(ind, tabId, tabPanelId)}
					/>
				))}
			</Tabs>
		</Box>
	);

	const PopulatedTabPanels = ({ value, tabId, tabPanelId, children }) => (
		<>
			{React.Children.map(children, (child, ind) => {
				if (React.isValidElement(child)) {
					logDebug('DrawerTabSettings', `<PopulatedTabPanels /> got rendered just now`);
					return (
						<DrawerTabPanel key={ind} index={ind} tabId={tabId} tabPanelId={tabPanelId} value={value}>
							{child}
						</DrawerTabPanel>
					);
				}
			})}
		</>
	);

	return (
		<Box>
			<PopulatedTabs
				handleChange={handleChange}
				tabId={tabId}
				tabNames={tabNames}
				tabPanelId={tabPanelId}
				value={value}
			/>
			<PopulatedTabPanels tabId={tabId} tabPanelId={tabPanelId} value={value}>
				{tabPanelContents}
			</PopulatedTabPanels>
		</Box>
	);
};

DrawerTabSettings.propTypes = {
	/**
	 * Fhir endpoint for getting column mappings
	 */
	endpoint: PropTypes.string,
	/**
	 * Handler called when columns have changed
	 */
	onChange: PropTypes.func,
	/**
	 * User saved data
	 */
	userSavedColumnList: PropTypes.array,
	/**
	 * Array of filter objects
	 */
	filters: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Whether the children can be taller or not (depends on whether the parent includes the username input)
	 */
	isLarge: PropTypes.bool,
};
export default DrawerTabSettings;
