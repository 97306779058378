import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
	useFhirDataLoader,
	searchScopes,
	useTabContext,
	useAppModeContext,
	mediaTablet,
	useIsTablet,
	useIsMobile,
	fhirExtensionUrls,
	useAuth,
	getFilteredTabData,
	getTabResourceType,
	useConfig,
} from '@worklist-2/core/src/index';
import { useSearchScope } from '@rs-core/context/SearchScopeContext';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { useIsAuthenticated } from '@azure/msal-react';
import classnames from 'classnames';
import SearchDataSetV2 from '@rs-ui/components/GlobalSearch/GlobalSearchV2/AutoSuggestDialogV2/SearchDataSetV2/SearchDataSetV2';
import { LoadingCallout } from './LoadingCallout';
import { NoResultsCallout } from './NoResultsCallout';
import { NoResultsAssistant } from '@worklist-2/patientPortal/src/components/NoResultsAssistant/NoResultsAssistant';
import SearchResultItem, { getItemPageUrl, useGoToUser, checkAccess } from './SearchResultItem';
import SendDrawer from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores';
import axios from 'axios';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SearchResults = ({
	data,
	tab,
	onTabChange,
	handleRelatedTabData,
	closeSearchDialog,
	setStudyInDetailedView,
	setIsSharedType,
	select,
}) => {
	const navigate = useNavigate();
	const { searchTab, validOrganizations, internalPatients } = useTabContext();
	const __searchScopeContext = useSearchScope();
	const [selectedData, setSelectedData] = useState([]);
	const isAuthenticated = useIsAuthenticated();
	const { loggedInUserId, isLoadingUserId, goToUser } = useGoToUser();
	const { isWorklistMode, isMarketplaceMode, isPatientPortalMode, isHelpCenterMode } = useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsMarketplace = isMarketplaceMode();
	const appModeIsPatientPortal = isPatientPortalMode();
	const appModeIsHelpCenter = isHelpCenterMode();
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const fhirDataLoaderTask = useFhirDataLoader({
		scope: searchScopes.task,
	});
	const [dataLoaderTask] = useState(fhirDataLoaderTask);

	const [loadingStudies, setLoadingStudies] = useState([]);
	const [retrievedStudies, setRetrievedStudies] = useState([]);
	const [downloadHover, setDownloadHover] = useState(false);
	const [downloadAllHover, setDownloadAllHover] = useState(false);

	const { selectedLanguage, loggedInUser } = useAuth();
	const metaEmergencySearch = useBooleanFlagValue('meta-emergency-search');

	const { searchText, mobileSearchDrawerOpen, mobileSearchText, setSearchInput } = useDrawersStore(state => ({
		searchText: state.searchText,
		mobileSearchDrawerOpen: state.mobileSearchDrawerOpen,
		mobileSearchText: state.mobileSearchText,
		setSearchInput: state.setSearchInput,
	}));

	const isBlumeHomePage = useMemo(
		() => searchText?.length > 0 || mobileSearchDrawerOpen,
		[mobileSearchDrawerOpen, searchText?.length]
	);
	const forceChatGptAssistant = useMemo(
		() =>
			searchText.toLowerCase().includes('what is') ||
			(isBlumeHomePage && mobileSearchText.toLowerCase().includes('what is')),
		[isBlumeHomePage, mobileSearchText, searchText]
	);

	const __config = useConfig();

	const saveData = useCallback(async (loader, props, payload) => {
		let result = {};
		if (isAuthenticated) {
			try {
				result = await loader.save(props, payload).then(resultResponse => resultResponse.data);
			} catch (e) {
				console.error(e);
			}
		}
		return result;
	});

	const handleSelectedData = item => {
		const existingItem = selectedData.find(selItem => selItem === item);
		if (!existingItem) {
			const newArray = [...selectedData];
			newArray.push(item);
			setSelectedData(newArray);
		} else {
			const newSelected = selectedData.filter(selItem => selItem !== item);
			setSelectedData(newSelected);
		}
	};

	const handleItemClick = (e, item, itemIsLoading, itemIsRetrieved, isAccess, onItemClick) => {
		if (areDicomResults && !itemIsLoading && !itemIsRetrieved) {
			onRetrieve(e, item);
		} else if (areDicomResults && (itemIsLoading || itemIsRetrieved)) {
			onTrack();
		} else if (isAccess) {
			onItemClick();
		}
	};

	const createRetrieveTask = (
		owner,
		forTarget,
		studyUid,
		issuerOfPatientId,
		managingOrg,
		patientName,
		patientId,
		accessionNumber
	) => {
		const patientNameJson = {
			url: fhirExtensionUrls.task.patient,
			valueReference: {
				display: patientName,
			},
		};

		const patientIdJson = {
			url: fhirExtensionUrls.task.patientID,
			valueString: patientId,
		};
		return {
			id: '-1',
			resourceType: 'Task',
			status: 'requested',
			intent: 'plan',
			priority: 'routine',
			code: {
				coding: [
					{
						code: 'approve',
						display: 'Activate/approve the focal resource  ',
					},
				],
			},
			reasonCode: {
				text: 'DicomRetrieve',
			},
			for: forTarget,
			owner,
			input: [
				{
					type: {
						text: 'QueryRetrieveInformationModelSOPClassUID',
					},
					valueString: '1.2.840.10008.5.1.4.1.2.2.2',
				},
				{
					type: {
						text: 'DicomJson',
					},
					valueString:
						`{"0020000D":{"vr":"UI","Value":["${studyUid}"]},` +
						`"00100021":{"vr":"UI","Value":["${issuerOfPatientId}"]},` +
						`"00100010":{"vr":"UI","Value":["${patientName}"]},` +
						`"00100020":{"vr":"UI","Value":["${patientId}"]}` +
						`${
							accessionNumber === undefined
								? ''
								: `,"00080050":{"vr":"SH","Value":["${accessionNumber}"]}`
						}` +
						`}`,
				},
			],
			extension: [managingOrg, patientNameJson, patientIdJson],
		};
	};

	const onRetrieve = async (e, itemToRetrieve) => {
		e?.preventDefault();
		e?.stopPropagation();

		const dataToProcess = itemToRetrieve ? [itemToRetrieve] : selectedData;

		setLoadingStudies(p => [...p, ...dataToProcess]);

		setSelectedData(p => (itemToRetrieve ? p.filter(i => i !== itemToRetrieve) : []));

		const retrieveTaskArray = [];

		for (const item of dataToProcess) {
			const studyUid = item['0020000d'] && item['0020000d'].Value && item['0020000d'].Value[0];
			const issuerOfPatientId = item['00100021'] && item['00100021'].Value && item['00100021'].Value[0];
			const patientName =
				item['00100010'] &&
				item['00100010'].Value &&
				item['00100010'].Value[0] &&
				item['00100010'].Value[0].Alphabetic;
			const patientId = item['00100020'] && item['00100020'].Value && item['00100020'].Value[0];
			retrieveTaskArray.push({
				task: createRetrieveTask(
					item.owner,
					item.for,
					studyUid,
					issuerOfPatientId,
					item.managingOrg,
					patientName,
					patientId
				),
				item,
			});
		}

		for (const { item, task } of retrieveTaskArray) {
			await saveData(dataLoaderTask, {}, task);
			setLoadingStudies(p => p.filter(pi => pi !== item));
			setRetrievedStudies(p => [...p, item]);
		}
	};

	const downloadAllResults = async e => {
		e?.preventDefault();
		e?.stopPropagation();

		const dataToProcess = filteredTabData;

		setLoadingStudies(p => [...p, ...dataToProcess]);

		const retrieveTaskArray = [];

		for (const item of dataToProcess) {
			const studyUid = item['0020000d'] && item['0020000d'].Value && item['0020000d'].Value[0];
			const issuerOfPatientId = item['00100021'] && item['00100021'].Value && item['00100021'].Value[0];
			const patientName =
				item['00100010'] &&
				item['00100010'].Value &&
				item['00100010'].Value[0] &&
				item['00100010'].Value[0].Alphabetic;
			const patientId = item['00100020'] && item['00100020'].Value && item['00100020'].Value[0];
			retrieveTaskArray.push({
				task: createRetrieveTask(
					item.owner,
					item.for,
					studyUid,
					issuerOfPatientId,
					item.managingOrg,
					patientName,
					patientId
				),
				item,
			});
		}

		for (const { item, task } of retrieveTaskArray) {
			await saveData(dataLoaderTask, {}, task);
			setLoadingStudies(p => p.filter(pi => pi !== item));
			setRetrievedStudies(p => [...p, item]);
		}
	};

	const onTrack = e => {
		e?.preventDefault();
		e?.stopPropagation();

		navigate('/log');
	};

	const getClickHandler = item => {
		const url = getItemPageUrl(
			item,
			loggedInUserId,
			appModeIsWorklist || appModeIsHelpCenter,
			selectedLanguage,
			appModeIsPatientPortal
		);

		if ((isMobile || isTablet) && item.ResourceType === 'BlumeStudy')
			return () => {
				setStudyInDetailedView({
					study: {
						id: item.id,
					},
				});
			};
		if ((isMobile || isTablet) && item.ResourceType === 'StudySharedWithMe')
			return () => {
				setIsSharedType('withMe');
				setStudyInDetailedView({
					study: {
						id: item.id,
					},
				});
			};
		if (!!url || item.resourceType === 'Practitioner')
			return async () => {
				__searchScopeContext.handleSaveRecentlyViewed(item);
				if (url) {
					if (searchText?.length > 0 && item.ResourceType !== 'helpcenter') {
						const getAccessToken = `${__config.data_sources.blume}Study/BlumeAccessToken?StudyUID=${item?.StudyThumbnail?.StudyUID}&InternalManagingOrganizationID=${item.ManagingOrganization.Id}&StudyId=${item.id}`;
						const res = await axios.get(getAccessToken);
						if (res.data.AccessToken) {
							navigate('/view-study', {
								state: {
									url: `${__config.omegaai_url}iv?studyId=${item.id}&studyInstanceUID=${item?.StudyThumbnail?.StudyUID}&patientId=${item.PatientId}&issuerOfPatientId=${item?.ManagingOrganization?.Name}&internalManagingOrganizationID=${item?.ManagingOrganization?.Id}&oAiAuthToken=${res.data.AccessToken}&isBlume=true`,
									dob: null,
								},
							});
						} else {
							setToastMsg(t('Access Denied'));
						}
						setSearchInput('');

						closeSearchDialog();
					} else {
						navigate(`${url}?from=global-search`);
					}
				} else {
					goToUser(item);
				}
				if (typeof closeSearchDialog === 'function') {
					closeSearchDialog();
				}
			};
		return undefined;
	};

	useEffect(() => {
		setSelectedData([]);
		setLoadingStudies([]);
		setRetrievedStudies([]);
	}, [data]);

	const tabResourceType = getTabResourceType(tab);
	const filteredTabData = getFilteredTabData(data, tab, tabResourceType);

	const resultsLength = filteredTabData?.length || 0;

	const isScopeAllWithTabFilter = searchTab === 'All' && tab !== 'All';

	const areDicomResults =
		data && data.length
			? data.filter(Boolean).filter(dataItem => dataItem.resourceType === 'DICOMResult').length === data.length
			: false;
	const listItemButtonStyle = {
		marginBottom: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '1rem' : undefined,
		borderRadius: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '6px' : '',
		padding: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '16px 20px' : '',
		paddingLeft: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '16px' : '1px',
		background: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '#393939' : '#FFFFFF',
	};

	return (
		<Container
			maxWidth="md"
			sx={{
				position: 'relative',
				paddingLeft: appModeIsPatientPortal ? 0 : undefined,
				paddingRight: appModeIsPatientPortal ? 0 : undefined,
				'@media (min-width: 600px)': {
					paddingLeft: appModeIsPatientPortal ? 0 : undefined,
					paddingRight: appModeIsPatientPortal ? 0 : undefined,
				},
			}}
		>
			{!__searchScopeContext.loading &&
				resultsLength > 0 &&
				(appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter) && (
					<div className="results-header">
						<Typography variant="subtitle2">{resultsLength}</Typography>
					</div>
				)}

			{__searchScopeContext.loading ? (
				<LoadingCallout />
			) : tab === 'All' && data?.length > 0 && !forceChatGptAssistant ? (
				<Box
					className="search-items-container"
					sx={{
						[mediaTablet]: {
							paddingRight: '0 !important',
						},
					}}
				>
					<SearchDataSetV2
						data={data}
						getOnClick={getClickHandler}
						handleRelatedTabData={handleRelatedTabData}
						handleTabChange={onTabChange}
						isLoadingUserId={isLoadingUserId}
						select={select}
					/>
				</Box>
			) : (
				// For all other tabs
				<div
					className="search-items-container"
					style={
						areDicomResults
							? { height: 'calc(100vh - 246px)' }
							: isScopeAllWithTabFilter || (!filteredTabData?.length && !forceChatGptAssistant)
							? undefined
							: { height: 'calc(100vh - 182px)' }
					}
				>
					{forceChatGptAssistant ? (
						<NoResultsAssistant />
					) : filteredTabData?.length ? (
						filteredTabData.map((item, index) => {
							const onItemClick = getClickHandler(item);

							const selectedItem = selectedData.find(selItem => selItem === item);

							const itemIsLoading = loadingStudies.includes(item);
							const itemIsRetrieved = retrievedStudies.includes(item);
							const isAccess = checkAccess(
								item,
								validOrganizations,
								internalPatients,
								loggedInUser?.id,
								metaEmergencySearch
							);

							return (
								<ListItemButton
									key={index}
									className={classnames(
										'searchItem',
										areDicomResults && !itemIsRetrieved && 'isDicom'
									)}
									data-cy="search-items-container"
									sx={listItemButtonStyle}
									onClick={e =>
										handleItemClick(e, item, itemIsLoading, itemIsRetrieved, isAccess, onItemClick)
									}
								>
									<SearchResultItem
										SendDrawer={SendDrawer}
										isAccess={isAccess}
										isChecked={!!selectedItem && areDicomResults}
										item={item}
										showButton={!areDicomResults}
										showProgress={
											itemIsLoading ||
											(item.id === isLoadingUserId &&
												isLoadingUserId &&
												item.resourceType === 'Practitioner')
										}
										studyBtnClassName="studyBtn"
										type="search"
										onCheck={
											areDicomResults && !itemIsLoading && !itemIsRetrieved
												? e => {
														e?.preventDefault();
														e?.stopPropagation();

														handleSelectedData(item);
												  }
												: undefined
										}
										onGoTo={onItemClick}
										onRetrieve={
											areDicomResults && !itemIsRetrieved && !itemIsLoading
												? e => onRetrieve(e, item)
												: undefined
										}
										onTrack={
											(itemIsLoading || itemIsRetrieved) && areDicomResults ? onTrack : undefined
										}
									/>
								</ListItemButton>
							);
						})
					) : (
						!__searchScopeContext.loading &&
						!data?.length &&
						(!forceChatGptAssistant ? <NoResultsCallout /> : <NoResultsAssistant />)
					)}
				</div>
			)}

			{areDicomResults && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						position: 'absolute',
						bottom: '-60px',
					}}
				>
					<Box
						sx={{
							height: '45px',
							borderRadius: '29px',
							backgroundColor: '#2C7EC0',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: 'rgba(255,255,255,.6) !important',
							transition: 'color .3s ease',
							boxShadow: '0px 1px 10px rgba(0,0,0,.5)',
						}}
					>
						{selectedData?.length > 1 && (
							<>
								<Typography
									sx={{
										fontSize: '10px',
										textTransform: 'uppercase',
										letterSpacing: '0.1em',
										padding: '0 16px',
										lineHeight: '13px',
										borderRight: '1px solid rgba(255,255,255,0.3)',
									}}
								>
									{selectedData.length} STUDIES
								</Typography>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										padding: '15px 0px 15px 20px',
										cursor: 'pointer',

										'&:hover': {
											color: 'rgba(255,255,255,1) !important',
										},
									}}
									onClick={onRetrieve}
									onMouseEnter={() => setDownloadHover(true)}
									onMouseLeave={() => setDownloadHover(false)}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											backgroundColor: '#2975B2',
											borderRadius: '20px',
											padding: '10px',
										}}
									>
										<DownloadOutlined sx={{ width: '15px', height: '15px' }} />
										{downloadHover && (
											<Typography
												sx={{
													fontSize: '10px',
													textTransform: 'uppercase',
													letterSpacing: '0.1em',
													padding: '0 16px',
													lineHeight: '13px',
												}}
											>
												DOWNLOAD
											</Typography>
										)}
									</Box>
								</Box>
							</>
						)}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								padding: '15px 25px 15px 20px',
								cursor: 'pointer',

								'&:hover': {
									color: 'rgba(255,255,255,1) !important',
								},
							}}
							onClick={downloadAllResults}
							onMouseEnter={() => setDownloadAllHover(true)}
							onMouseLeave={() => setDownloadAllHover(false)}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									backgroundColor: '#2975B2',
									borderRadius: '20px',
									padding: '10px',
								}}
							>
								<DownloadForOfflineOutlinedIcon sx={{ width: '15px', height: '15px' }} />
								{downloadAllHover && (
									<Typography
										sx={{
											fontSize: '10px',
											textTransform: 'uppercase',
											letterSpacing: '0.1em',
											padding: '0 16px',
											lineHeight: '13px',
										}}
									>
										DOWNLOAD ALL RESULTS
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
				</div>
			)}
		</Container>
	);
};

SearchResults.propTypes = {
	/**
	 * Predefined data to be loaded into the results list.
	 */
	data: PropTypes.array,

	/**
	 * Selected category tab.
	 */
	tab: PropTypes.string,

	/**
	 * Callback fired on tab change.
	 */
	onTabChange: PropTypes.func,

	/**
	 * Callback fired to show related data list, when clicks show more
	 */
	handleRelatedTabData: PropTypes.func,

	/**
	 * Callback fired to close search dialog
	 */
	closeSearchDialog: PropTypes.func,
};

export default SearchResults;
