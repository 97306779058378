import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { categoriesList } from '@rs-ui/components/GlobalSearch/GlobalSearchV2/categoriesList';

import SearchBarV2 from './SearchBarV2';

const GlobalSearchV2 = ({ additionalSearchParams, setParentData, initialValue }) => {
	const [select, setSelect] = useState([]);

	const { appMode } = useAppModeContext();

	const [searchParams] = useSearchParams();
	const searchTextUrl = searchParams.get('name');

	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('marketplace')) {
			setSelect([{ label: 'Marketplace', key: 'Marketplace App' }]);
		}
		if (searchTextUrl && appMode === 'marketplace') {
			setSelect([categoriesList.find(item => item.label === 'Marketplace')]);
		}
	}, [location.pathname, appMode]);

	return (
		<SearchBarV2
			additionalSearchParams={additionalSearchParams}
			defaultSearchText={searchTextUrl}
			initialValue={initialValue}
			select={select}
			setParentData={setParentData}
			setSelect={setSelect}
		/>
	);
};

export default GlobalSearchV2;
