import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const NavButton = ({ icon, path, className, text, onClick, activeClassName, tooltipTxt }) => {
	const content = (
		<>
			<Tooltip placement="right" title={tooltipTxt}>
				<div>{icon}</div>
			</Tooltip>
			{path === 'profile' ? (
				<div className="label">
					<Tooltip title={<h3>{text}</h3>}>
						<Typography
							sx={{
								fontSize: '14px',
								overflow: 'hidden',
								width: '7.5rem',
								textOverflow: 'ellipsis',
							}}
						>
							{text}
						</Typography>
					</Tooltip>
				</div>
			) : (
				<div className="label">{text}</div>
			)}
		</>
	);

	if (path) {
		return (
			<NavLink
				key={text}
				className={({ isActive }) => classnames(isActive ? activeClassName : '', 'nav-link', className)}
				data-cy={`sidebar-${path}`}
				to={path}
				onClick={onClick}
			>
				{content}
			</NavLink>
		);
	}

	return (
		<div className={classnames('nav-link', className)} onClick={onClick}>
			{content}
		</div>
	);
};

NavButton.propTypes = {
	icon: PropTypes.element,
	path: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	activeClassName: PropTypes.string,
};

NavButton.defaultProps = {
	activeClassName: 'nav-link-active',
};
export default NavButton;
