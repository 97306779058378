import React from 'react';
import AddMore from '../FormFields/AddMore';
import Select from '../FormFields/Select';
import Text from '../FormFields/Text';
import APISelectAutocomplete from '../FormFields/APISelectAutocomplete';
import GoogleAutocomplete from '../FormFields/GoogleAutocomplete';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Time from '../FormFields/Time';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TimeRangePicker from '../../TimeRangePicker/TimeRangePicker';
import DayPicker from '../../DayPicker/DayPicker';
import SearchableMutiSelectCheckboxDropdown from '../../SearchableMutiSelectCheckboxDropdown';
import BasicSwitch from '@mui/material/Switch';
import { Col } from '../../SideBar/PatientSideBar';
import SearchableMultiSelectColumnFilter from '@worklist-2/ui/src/components/SearchableMultiSelectColumnFilter';
import Switch from '../FormFields/Switch';
import CheckboxComponent from '../FormFields/Checkbox';
import GridInput from '../FormFields/GridInput';
import _ from 'lodash';
import { SignatureDropZone } from '../../../views/UserInformationView/SignatureDropZone';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { NotesField } from '../../NotesField';
import { useTranslation } from 'react-i18next';
import { isValidBase64ImageData } from '@rs-ui/views/utils/signatureUtils';
import { Button, Divider, Tooltip } from '@mui/material';
import { Box } from '@mui/system';

const FormSection = ({
	className,
	disabled,
	formFields,
	sectionHeader,
	sectionIndex,
	getModel,
	variant,
	onChange,
	onAddMore,
	onRemove,
	disableAccordion,
	preRender,
	isEditing,
	setEditing,
	handleUpdateSignature,
	signatureData,
	singleMode,
	loggedInUser,
	isExpanded,
	submitCount,
	accordionStyle,
}) => {
	/*
	Each formField has these properties: name, id, isRequired, type, defaultValue, mapping
	*/

	const { t } = useTranslation('form');
	const model = getModel();

	const dividerObject = (
		<Grid item xs={12}>
			<div className={classnames(className, 'section-divider')} />
		</Grid>
	);

	const getFormField = (formField, id) => {
		let formFieldObject;

		switch (formField.type) {
			case 'autocomplete':
				var defaultOption = formField.options.includes(model[formField.mapping])
					? model[formField.mapping]
					: '';

				formFieldObject = (
					<Autocomplete
						key={`${formField.name}-field`}
						disablePortal
						className={className}
						data-testid="form-field"
						defaultValue={defaultOption}
						id={formField.id ? formField.id : `form-field-${formField.name}`}
						options={formField.options}
						renderInput={params => <TextField {...params} className={className} label={formField.name} />}
						sx={{
							width: 210,
							display: 'inline-block',
						}}
						variant="outlined"
						onChange={(event, value) =>
							onChange(event, value, formField.mapping ? formField.mapping : formField.name)
						}
					/>
				);
				break;
			case 'addMore':
				const options = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model);

				const onAddMore = () => {
					const value = formField.onAddMore(options || []);

					onChange(null, value.value, value.mapping);
				};

				formFieldObject = formField.hidden ? null : (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<AddMore
							disableEdit={formField.disableEdit}
							disabled={disabled || formField.disabled}
							formField={formField}
							hideAdd={formField.hideAdd}
							options={options}
							variant={variant || formField.variant}
							onAddMore={onAddMore}
							onChange={onChange}
						/>
					</Grid>
				);
				break;
			case 'APISelectAutocomplete':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<APISelectAutocomplete
							buttonIcon={formField.buttonIcon}
							buttonLabel={formField.buttonLabel}
							disabled={disabled || formField.disabled}
							formField={formField}
							helperText={formField.helperText}
							model={model}
							variant={variant}
							onButtonClick={formField.onButtonClick}
							onChange={(event, newValue, mapping, remove, customModelUpdate) => {
								if (formField.onChange) {
									formField.onChange(newValue);
								}

								onChange(
									event,
									newValue,
									mapping,
									remove,
									customModelUpdate || formField?.customModelUpdateFn
								);
							}}
						/>
					</Grid>
				) : null;
				break;
			case 'googleAutocomplete':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<GoogleAutocomplete
							className={className}
							disabled={disabled}
							formField={formField}
							model={model}
							variant={variant}
							onChange={onChange}
						/>
					</Grid>
				) : null;
				break;
			case 'select':
				const defaultValue = formField.mapping
					.split('.')
					.reduce((p, c) => (p && p[c]) || (formField.multiple ? [] : ''), model);

				const option = formField.options[formField.multiple ? 'filter' : 'find'](item => {
					if (formField.multiple && _.isArray(defaultValue)) {
						return defaultValue.find(
							defaultValueItem =>
								formField.renderValue &&
								String(formField.renderValue(item)).toLowerCase() ===
									String(formField.renderValue(defaultValueItem)).toLowerCase()
						);
					}

					return (
						formField.renderValue &&
						String(formField.renderValue(item)).toLowerCase() ===
							String(formField.renderValue(defaultValue)).toLowerCase()
					);
				});

				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<Select
							className={className}
							disabled={disabled}
							formField={formField}
							option={option}
							variant={variant}
							onChange={(event, newValue, mapping, remove, customModelUpdate) => {
								onChange(event, newValue, mapping, remove, customModelUpdate);
							}}
						/>
					</Grid>
				) : null;
				break;
			case 'time':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<Time
							className={className}
							disabled={disabled}
							formField={formField}
							model={model}
							variant={variant}
							onChange={onChange}
						/>
					</Grid>
				) : null;
				break;
			case 'datetime':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								disabled={disabled || formField.disabled}
								renderInput={props => (
									<TextField
										{...props}
										{...formField}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											...props.InputProps,
											disabled: disabled || formField.disabled,
											endAdornment: undefined,
											startAdornment: formField.hideAdornment
												? undefined
												: props.InputProps.endAdornment,
											sx: formField.hideAdornment
												? undefined
												: {
														paddingLeft: 0,
														'& > div': {
															height: '100%',
															width: '40px',

															'& > button': {
																padding: 0,
															},
														},
												  },
										}}
										label={formField.name}
										variant={variant || 'outlined'}
									/>
								)}
								value={formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model)}
								onChange={value => onChange(undefined, value, formField.mapping)}
							/>
						</LocalizationProvider>
					</Grid>
				) : null;
				break;
			case 'date':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								disabled={disabled}
								inputFormat="MM/dd/yyyy"
								renderInput={props => (
									<TextField
										{...props}
										{...formField}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											...props.InputProps,
											disabled,
											endAdornment: undefined,
											startAdornment: formField.hideAdornment
												? undefined
												: props.InputProps.endAdornment,
											sx: formField.hideAdornment
												? undefined
												: {
														paddingLeft: 0,
														'& > div': {
															height: '100%',
															width: '40px',

															'& > button': {
																padding: 0,
															},
														},
												  },
										}}
										inputProps={{ ...props.inputProps, placeholder: t('mm/dd/yyyy') }}
										label={formField.name}
										variant={variant || 'outlined'}
									/>
								)}
								value={formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model)}
								onChange={value => onChange(undefined, value, formField.mapping)}
							/>
						</LocalizationProvider>
					</Grid>
				) : null;
				break;
			case 'plainText':
				const defaultTextValue = formField?.mapping?.split('.').reduce((p, c) => (p && p[c]) || '', model);

				const renderedValue = formField.renderValue
					? formField.renderValue(defaultTextValue)
					: defaultTextValue || '';

				const renderedValueStr = String(renderedValue);

				formFieldObject = !formField.hidden ? (
					<Grid key={id || `${formField.name}-field`} item md={formField.size || 4}>
						<Col>
							<label>{formField.name}</label>
							<span>
								{formField.capitalizeOptions ? renderedValueStr.toUpperCase() : renderedValueStr}
							</span>
						</Col>
					</Grid>
				) : null;
				break;
			case 'plainTextOutlined':
				const defaultText = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || '', model);

				const renderedText = formField.renderValue ? formField.renderValue(defaultText) : defaultText || 'N/A';

				formFieldObject = !formField.hidden ? (
					<Grid key={id || `${formField.name}-field`} item md={formField.size || 4} sx={{ width: '210px' }}>
						<TextField
							disabled
							defaultValue={formField.capitalizeOptions ? renderedText.toUpperCase() : renderedText}
							helperText={t('Read-only')}
							label={formField.name}
							sx={{ width: '100%', fontColor: 'text.faint' }}
							variant="outlined"
						/>
					</Grid>
				) : null;
				break;
			case 'plainDate':
				const dateValue = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || '', model);

				const date = moment(dateValue);

				formFieldObject = !formField.hidden ? (
					<Grid key={id || `${formField.name}-field`} item md={formField.size || 4}>
						<Col>
							<label>{formField.name}</label>
							<span>{date.isValid() ? date.format('L') : ''}</span>
						</Col>
					</Grid>
				) : null;
				break;
			case 'plainDateTime':
				const dateTimeValue = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || '', model);

				const dateTime = new Date(dateTimeValue);

				formFieldObject = !formField.hidden ? (
					<Grid key={id || `${formField.name}-field`} item md={formField.size || 4}>
						<Col>
							<label>{formField.name}</label>
							<span>{isNaN(dateTime.getDate()) ? '' : dateTime.toLocaleString()}</span>
						</Col>
					</Grid>
				) : null;
				break;
			case 'grid':
				const rows = formField.mapping.split('.').reduce((p, c) => (p && p[c]) || [], model);

				const getGridMapping = (index, item) => {
					let mapping = `${formField.mapping}.${index}`;

					if (item.mapping) {
						mapping += `.${item.mapping}`;
					}

					return mapping;
				};

				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<GridInput
							key={`${formField.name}-field`}
							expandableRows={formField.expandableRows}
							formField={formField}
							getFormField={getFormField}
							getGridMapping={getGridMapping}
							isEditing={isEditing}
							model={model}
							rows={rows}
							onChange={onChange}
						/>
					</Grid>
				) : null;
				break;
			case 'multi-select':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<SearchableMultiSelectColumnFilter
							labelAlwaysShrunk
							capitalizeOptions={formField.capitalizeOptions}
							displayValue="code"
							label={formField.name}
							placeholder=""
							preSelectedValues={model[formField.mapping].replaceAll(' ', '').split('|')}
							valueSetType={formField.mapping}
							onSelectForm={onChange}
						/>
					</Grid>
				) : null;
				break;
			case 'switch':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4} sx={{ marginBottom: '8px' }}>
						<Switch
							className={className}
							disabled={disabled}
							formField={formField}
							model={model}
							variant={variant}
							onChange={onChange}
						/>
					</Grid>
				) : null;
				break;
			case 'checkbox':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4} sx={{ marginBottom: '8px' }}>
						<CheckboxComponent
							className={className}
							disabled={disabled}
							formField={formField}
							model={model}
							variant={variant}
							onChange={onChange}
						/>
					</Grid>
				) : null;
				break;
			case 'DragAndDrop':
				formFieldObject = !formField.hidden ? (
					<Grid key={`${formField.name}-field`} item md={formField.size || 4}>
						<SignatureDropZone
							disabled={disabled}
							handleUpdateSignature={handleUpdateSignature}
							isEditing={isEditing}
							signature={signatureData}
						/>
					</Grid>
				) : (
					isValidBase64ImageData(signatureData) && (
						<img alt="File preview" src={signatureData} style={{ padding: '15px' }} />
					)
				);
				break;
			case 'sectionDivider':
				formFieldObject = !formField.hidden ? (
					<Grid key={`section-divider-${formField.name}`} item md={12}>
						<Typography
							sx={{
								marginTop: '28px',
								fontSize: '16px',
								lineHeight: '24px',
								letterSpacing: '.15px',
								color: 'rsPrimary.contrastText',
							}}
						>
							{formField.name}
						</Typography>
					</Grid>
				) : null;
				break;
			case 'subSectionStart':
				formFieldObject = (
					<Grid key={`sub-section-start-${formField.name}`} item md={12}>
						<Divider component="hr" />
						<Typography
							sx={{
								marginTop: '15px',
								marginBottom: '10px',
								fontSize: '16px',
								fontFamily: 'Roboto',
								lineHeight: '24px',
								letterSpacing: '.15px',
								color: 'rsPrimary.contrastText',
							}}
						>
							{formField.name}
						</Typography>
					</Grid>
				);
				break;
			case 'subSectionEnd':
				formFieldObject = (
					<Grid key={`sub-section-end-${formField.name}`} item md={12}>
						<Divider component="hr" />
					</Grid>
				);
				break;
			case 'disclaimer':
				formFieldObject = (
					<Grid key={`disclaimer-${formField.name}`} item md={12}>
						<Box
							sx={{
								marginTop: '15px',
								marginBottom: '10px',
								fontSize: '12px',
								fontFamily: 'Roboto',
								lineHeight: '20px',
								letterSpacing: '.25px',
								color: 'rgba(255, 255, 255, 0.8)',
								background: '#363636',
								borderRadius: '8px',
								border: '1px solid var(--Neutral-Neutral-300, #4D4D4D)',
								padding: '10px 12px',
							}}
						>
							{formField.name}
						</Box>
					</Grid>
				);
				break;
			case 'button':
				formFieldObject = !formField.hidden ? (
					<Grid key={`button-${formField.name}`} item md={12}>
						<Button
							data-testid="buttonElement"
							startIcon={formField.icon}
							sx={{
								fontSize: '12px',
								fontFamily: 'Roboto',
								lineHeight: '20px',
								letterSpacing: '.25px',
								color: 'rgba(255, 255, 255, 0.8)',
								backgroundColor: '#363636 !important',
								borderRadius: '8px',
								border: '1px solid var(--Neutral-Neutral-300, #4D4D4D)  !important',
								padding: '10px 14px',
								textTransform: 'uppercase',
								width: '100%',
								marginBottom: '20px',
							}}
							variant={formField.variant}
						>
							{formField.name}
						</Button>
					</Grid>
				) : null;
				break;
			case 'linkMonitoringList':
				formFieldObject = !formField.hidden ? (
					<Grid
						key={`linkMonitoringList-${formField.name}`}
						item
						data-testid="editableLinkMonitoringList"
						md={12}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '20px',
						}}
					>
						{formField.list &&
							formField.list.map((item, index) => (
								<Box
									// eslint-disable-next-line react/no-array-index-key
									key={item.name + index}
									sx={{
										borderRadius: '8px',
										border: '1px solid #4D4D4D',
										background: '#363636',
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'column',
										gap: '15px',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											padding: '10px 20px',
											borderBottom: '1px solid #4D4D4D',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<TextField
												defaultValue={item.name}
												inputProps={{ 'data-testid': 'LinkMonitoringSessionName' }}
												sx={{
													marginTop: '8px',
												}}
												variant="outlined"
												onChange={newName => {
													const newList = formField.list;
													newList[index].name = newName.target.value;
													formField.setListOfNotifications(newList);
												}}
											/>
											<BasicSwitch
												checked={item.isEnable}
												sx={{
													'& .MuiSwitch-switchBase.Mui-checked': {
														color: '#42A5F5',
													},
													'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
														backgroundColor: '#317797',
													},
													'& .MuiSwitch-input': {
														left: '-180%',
													},
												}}
												onChange={event => {
													const newList = [...formField.list];
													newList[index].isEnable = event.target.checked;
													formField.setListOfNotifications(newList);
												}}
											/>
										</Box>
										<Button
											startIcon={
												<Tooltip title="Delete">
													<DeleteOutlineIcon sx={{ fontSize: '20px' }} />
												</Tooltip>
											}
											sx={{
												fontSize: '20px',
												color: 'rgba(255, 255, 255, 0.8)',
												'& .MuiButton-startIcon': {
													margin: '0px',
												},
											}}
											onClick={() => {
												const sessionToBeDeleted = item?.id;
												formField.setListOfNotifications(
													formField.list.filter((element, i) => i !== index)
												);
												formField.setSessionsToBeDeleted(prev => [...prev, sessionToBeDeleted]);
											}}
										/>
									</Box>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											padding: '10px 20px',
											flexDirection: 'column',
											gap: '15px',
											paddingBottom: '20px',
										}}
									>
										<SearchableMutiSelectCheckboxDropdown
											defaultValue={formField.options.filter(opt =>
												item.notificationType
													?.split(',')
													.map(i => i.trim())
													.includes(opt.title)
											)}
											index={index}
											label={t('Notification Type')}
											list={formField.list}
											options={formField.options}
											placeholder={t('Options')}
											setListOfNotifications={formField.setListOfNotifications}
											sx={{
												width: '100% !important',
											}}
										/>
										<TimeRangePicker
											defaultEndTime={item.endTime}
											defaultStartTime={item.startTime}
											index={index}
											list={formField.list}
											setListOfNotifications={formField.setListOfNotifications}
										/>
										<DayPicker
											defaultDays={item.schedule?.split(' ')}
											index={index}
											list={formField.list}
											setListOfNotifications={formField.setListOfNotifications}
										/>
									</Box>
								</Box>
							))}
						<Grid key="button-ADD NEW MONITORING" item md={12}>
							<Button
								data-testid="AddNewMonitoring"
								inputProps={{ 'data-testid': 'AddNewMonitoring' }}
								startIcon={formField.icon}
								sx={{
									fontSize: '12px',
									fontFamily: 'Roboto',
									lineHeight: '20px',
									letterSpacing: '.25px',
									color: 'rgba(255, 255, 255, 0.8)',
									backgroundColor: '#363636 !important',
									borderRadius: '8px',
									border: '1px solid var(--Neutral-Neutral-300, #4D4D4D)  !important',
									padding: '10px 14px',
									textTransform: 'uppercase',
									width: '100%',
									marginBottom: '20px',
								}}
								variant="outlined"
								onClick={() => {
									formField.setListOfNotifications([
										...formField.list,
										{
											notificationType: 'SMS,EMAIL',
											startTime: '10:00 AM',
											endTime: '12:00 PM',
											schedule: 'Mon Tue Wed Thu Fri',
											isEnable: true,
											internalObjectId: `${formField.deviceId}`,
											objectType: 'Device',
											name: 'New Link Monitoring',
										},
									]);
								}}
							>
								ADD NEW MONITORING
							</Button>
						</Grid>
					</Grid>
				) : null;
				break;
			case 'linkMonitoringListReadOnly':
				formFieldObject = !formField.hidden ? (
					<Grid
						key={`linkMonitoringList-${formField.name}`}
						item
						data-testid="linkMonitoringListReadOnly"
						md={12}
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{formField.list &&
							formField.list.map((item, index) => (
								<Box
									// eslint-disable-next-line react/no-array-index-key
									key={item.name + index}
									sx={{
										borderRadius: '8px',
										border: '1px solid #4D4D4D',
										background: '#363636',
										display: 'flex',
										justifyContent: 'space-between',
										marginBottom: '20px',
										flexDirection: 'column',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											textAlign: 'center',
											padding: '10px 20px',
											margin: '0px',
										}}
									>
										<TextField
											disabled
											defaultValue={item.name}
											sx={{
												marginBottom: '0px !important',
											}}
											variant="standard"
										/>
										<BasicSwitch
											disabled
											checked={item.isEnable}
											sx={{
												'& .MuiSwitch-switchBase.Mui-checked': {
													color: '#42A5F5 !important',
												},
												'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
													backgroundColor: '#317797',
												},
												'& .MuiSwitch-input': {
													left: '-180%',
												},
											}}
										/>
									</Box>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											textAlign: 'center',
											padding: '10px 20px',
											margin: '0px',
										}}
									>
										<Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
											Notification Type
										</Typography>
										<Typography>{item.notificationType}</Typography>
									</Box>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											textAlign: 'center',
											padding: '10px 20px',
											margin: '0px',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												textAlign: 'left',
												padding: '10px 0px',
												margin: '0px',
											}}
										>
											<Typography sx={{ fontSize: '14px', opacity: '0.8' }}>
												Start Time - End Time
											</Typography>
											<Typography>{`${item.startTime} - ${item.endTime}`}</Typography>
										</Box>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												textAlign: 'left',
												padding: '10px 0px',
												margin: '0px',
											}}
										>
											<Typography sx={{ fontSize: '14px', opacity: '0.8' }}>Week Days</Typography>
											<Typography>
												{item.schedule
													?.trim()
													.split(' ')
													.map(day => day[0])
													.join(',')}
											</Typography>
										</Box>
									</Box>
								</Box>
							))}
					</Grid>
				) : null;
				break;
			case 'custom':
				formFieldObject = formField.render({
					disabled,
					value: formField.mapping.split('.').reduce((p, c) => (p && p[c]) || null, model),
					onChange: value => onChange(undefined, value, formField.mapping),
				});
				break;

			case 'notesField':
				const initialNotesValue = model[formField?.mapping];

				formFieldObject = (
					<NotesField
						key={`${formField.name}-field`}
						disabled
						formHook=""
						initialValue={initialNotesValue}
						isEditing={isEditing}
						loggedInUser={loggedInUser}
						noteMapping={formField?.mapping}
						setEditing={setEditing}
						onChange={onChange}
					/>
				);
				break;
			default:
				formFieldObject = !formField.hidden ? (
					<Grid key={id || `${formField.name}-field`} item md={formField.size || 4}>
						<Text
							className={className}
							disabled={disabled}
							formField={formField}
							model={model}
							submitCount={submitCount}
							variant={variant}
							onChange={onChange}
						/>
					</Grid>
				) : null;
				break;
		}

		return formFieldObject;
	};

	const formFieldObjects = formFields.map(formField => getFormField(formField));

	const header = singleMode ? '' : sectionHeader;

	// Add header and save button
	return (
		<Grid
			container
			className={classnames(className, 'section')}
			columnSpacing={12}
			data-cy="form-section"
			data-testid="section"
			spacing={0}
		>
			{sectionIndex === 0 ? null : dividerObject}

			<Grid item xs={12}>
				{disableAccordion || singleMode ? (
					<>
						{(header || onAddMore) && (
							<Grid
								item
								className={classnames(className, 'section-header')}
								data-cy="section-header"
								data-testid="section-header"
								xs={12}
							>
								{header}{' '}
								{onAddMore && !disabled && (
									<AddIcon
										sx={{
											marginLeft: '20px',
											color: '#FFFFFF99',
										}}
										onClick={e => {
											e.preventDefault();
											e.stopPropagation();
											onAddMore(model, onChange);
										}}
									/>
								)}
							</Grid>
						)}

						<Grid item xs={12}>
							<Grid container spacing={2}>
								{formFieldObjects}
							</Grid>
						</Grid>
					</>
				) : (
					<Accordion disableGutters defaultExpanded={isExpanded} elevation={0}>
						<Grid
							item
							className={classnames(className, 'section-header')}
							data-testid="section-header"
							xs={12}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								{header}{' '}
								{onAddMore && !disabled && (
									<AddIcon
										sx={{
											marginLeft: '20px',
											color: '#FFFFFF99',
										}}
										onClick={e => {
											e.preventDefault();
											e.stopPropagation();
											onAddMore(model, onChange);
										}}
									/>
								)}
								{onRemove && !disabled && (
									<DeleteOutlineIcon
										sx={{
											marginLeft: '20px',
											color: '#FFFFFF99',
										}}
										onClick={e => {
											e.preventDefault();
											e.stopPropagation();
											onRemove(model, onChange);
										}}
									/>
								)}
							</AccordionSummary>
						</Grid>

						<AccordionDetails sx={accordionStyle ?? {}}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									{preRender}

									{formFieldObjects}
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				)}
			</Grid>
		</Grid>
	);
};

FormSection.propTypes = {
	/**
	 * Array of fields for this section. Each field is a JS object with three properties: "name", "mapping", "required", "disabled", "variant"
	 */
	formFields: PropTypes.array.isRequired,

	/**
	 * Section header text
	 */
	sectionHeader: PropTypes.string,

	/**
	 * Index of this section in enclosing container
	 */
	sectionIndex: PropTypes.number.isRequired,

	/**
	 * Function to handle user input
	 */
	onChange: PropTypes.func.isRequired,

	/**
	 * Function to retrieve the model that the form is populated with
	 */
	getModel: PropTypes.func.isRequired,

	/**
	 * Show/hide fields/buttons based on page mode
	 */
	isEditing: PropTypes.bool,
	/**
	 * Show only one section by id
	 */
	singleMode: PropTypes.string,
};

FormSection.defaultProps = {
	isExpanded: true,
	isEditing: false,
	singleMode: '',
};

export default FormSection;
