import React from 'react';
import { HelpPopOverButton } from './HelpCenter';
import { Container } from './WhatsNew/whatsNewStyles';

const HelpCenterContent = ({ md }) => {
	const Content = '';

	return (
		<Container className="content-container">
			<HelpPopOverButton />
			{Content}
		</Container>
	);
};

export default HelpCenterContent;
