import Box from '@mui/material/Box';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { CustomStyledTabs } from '../HelpCenter';
import { boxStyle, Scrollable } from '../helpCenterStyles';
import Highlights from '../WhatsNew/Highlights';
import BuildVersion from '../WhatsNew/BuildVersion';
import { useAuth } from '@worklist-2/core/src';

import { useTranslation } from 'react-i18next';

const HelpOverview = ({ tabIndex }) => {
	const { t } = useTranslation('helpCenter');
	const navigate = useNavigate();
	const { selectedLanguage } = useAuth();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	useEffect(() => {
		setSelectedTabIndex(0);
	}, []);

	useEffect(() => {
		setSelectedTabIndex(tabIndex || 0);
	}, [tabIndex]);

	const contentList = {
		Highlights,
		BuildVersion,
	};

	const tabList = useMemo(() => {
		const tabList = [
			{ label: t('Highlights'), content: 'Highlights' },
			{ label: t('Build Version'), content: 'BuildVersion' },
		];
		return tabList;
	}, []);

	const SelectedTabContent = useMemo(() => {
		const tab = tabList[selectedTabIndex];
		return contentList[tab?.content || 'Highlights'];
	}, [selectedTabIndex]);

	const handleChange = (event, newTabIndex) => {
		navigate(`/help/${selectedLanguage}/overview/${tabList[newTabIndex].content}`);
	};

	return (
		<Box sx={boxStyle}>
			<Box sx={{ bgcolor: '#2f2f2f' }}>
				<CustomStyledTabs tabs={tabList} value={selectedTabIndex} onClick={handleChange} />
			</Box>
			<Scrollable className="content-container" style={{ padding: '20px 70px' }}>
				<SelectedTabContent />
			</Scrollable>
		</Box>
	);
};

export default HelpOverview;
