import React from 'react';
import { HelpPopOverButton } from '..';
import { BannerBody, BannerHeader, Container, HeroBanner } from './whatsNewStyles';
import onlineBusiness from '../../../../assets/img/helpAssets/clip-online-business-negotiation1.png';
import { useTranslation } from 'react-i18next';

const BuildVersion = () => {
	const { t } = useTranslation('helpCenter');
	const Content = '';

	return (
		<Container className="content-container">
			<HelpPopOverButton />
			<HeroBanner className="content-banner">
				<BannerBody>
					<BannerHeader>{t('Current Build OmegaAI')}</BannerHeader>
					<small>{t('We break down the ins and outs of our newest build. Fast and efficient.')}</small>
				</BannerBody>
				<img src={onlineBusiness} />
			</HeroBanner>
			{Content}
		</Container>
	);
};
export default BuildVersion;
