import { create } from 'zustand';
import _ from 'lodash';

import commonMiddlewares from '@rs-core/store/middlewares';
import getUserInfo from '@worklist-2/patientPortal/src/api/getUserInfo';

export const useUserInfoStore = create(
	commonMiddlewares((set, get) => ({
		// personal state
		firstName: '',
		middleName: '',
		lastName: '',
		birthDate: '',
		gender: '',
		race: '',
		ethnicity: '',
		birthSex: '',
		language: '',
		ssn: '',
		maritalStatus: '',
		smokingStatus: '',
		alcoholUse: '',
		motherMaidenName: '',

		// address state
		country: '',
		state: '',
		city: '',
		zip: '',
		address: '', // could be renamed to addressLine1
		addressLine2: '',

		// contact state
		phone: '',
		email: '',

		// height and weight
		height: '',
		weight: '',

		profileId: '',

		physicians: [],
		allergies: [],
		emergencyContacts: [],
		abha: {},
		img: '', // check where it is used (don't see it)

		isInitializedInProfilePage: false,
		setIsInitializedInProfilePage: isInitializedInProfilePage =>
			set(() => ({
				isInitializedInProfilePage,
			})),

		// actions
		getUserInfo: async ({ __config }) => {
			try {
				const user = await getUserInfo({ __config });
				let profileId;

				const aggregatedDetails = Object.keys(user.aggregatedetails).reduce((acc, key) => {
					if (user.aggregatedetails[key].isPrimary) {
						acc = user.aggregatedetails[key];
						profileId = key;
					}
					return acc;
				}, {});

				const userState = {
					// personal state
					firstName: aggregatedDetails.firstName || '',
					middleName: aggregatedDetails.middleName || '',
					lastName: aggregatedDetails.lastName || '',
					birthDate: aggregatedDetails.birthDate || '',
					gender: aggregatedDetails.gender || '',
					race: aggregatedDetails.race || '', // could be [] from backend
					ethnicity: aggregatedDetails.ethnicity || '', // could be [] from backend
					birthSex: aggregatedDetails.birthSex || '',
					language: aggregatedDetails.language || '',
					ssn: aggregatedDetails.ssn || '',
					maritalStatus: aggregatedDetails.maritalStatus || '',
					smokingStatus: aggregatedDetails.smokingStatus || '',
					alcoholUse: aggregatedDetails.alcoholUse || '',
					motherMaidenName: aggregatedDetails.motherMaidenName || '',

					// address state
					country: aggregatedDetails?.address?.length ? aggregatedDetails?.address[0]?.country : '',
					state: aggregatedDetails?.address?.length ? aggregatedDetails?.address[0]?.state : '',
					city: aggregatedDetails?.address?.length ? aggregatedDetails?.address[0]?.city : '',
					zip: aggregatedDetails?.address?.length ? aggregatedDetails?.address[0]?.zip : '',
					address: aggregatedDetails?.address?.length ? aggregatedDetails?.address[0]?.addressLine1 : '',
					addressLine2: aggregatedDetails?.address?.length ? aggregatedDetails?.address[0]?.addressLine2 : '',

					// contact state
					phone: user.phone,
					email: user.email,
				};

				set(() => userState);
				return {
					fetchedUserInfo: user,
					userState,
				};
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
		updateUserInfo: async ({ changes }) => {
			try {
				set(() => ({
					...changes,
				}));
			} catch (error) {}
		},
	}))
);
