import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Chip } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';

const GroupingDragItem = ({ id, label, index, moveGroupingItem, removeGroupingItem }) => {
	const ref = useRef(null);

	/**
	 * Drop component
	 */
	const [, drop] = useDrop({
		accept: 'GROUPING',
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Determine pixels to the left
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			// Only move when cursor has crossed half (50%) of column width
			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
				return;
			}
			// Time to actually perform the action
			moveGroupingItem(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
	});

	/**
	 * Drag area
	 */
	const [{ isDragging }, drag] = useDrag({
		canDrag: true,
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		item: { id, index, label },
		type: 'GROUPING',
	});

	const opacity = isDragging ? 0.4 : 1;

	drag(drop(ref));

	return (
		<Box
			ref={ref}
			data-testid="worklist-grouping-drag-item"
			sx={{
				opacity,
			}}
		>
			<Chip
				deleteIcon={<CancelIcon data-testid="cancel-icon-grouping" />}
				label={label}
				size="small"
				onDelete={() => {
					removeGroupingItem(index);
				}}
			/>
		</Box>
	);
};

GroupingDragItem.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	index: PropTypes.number.isRequired,
	moveGroupingItem: PropTypes.func,
	removeGroupingItem: PropTypes.func,
};

GroupingDragItem.defaultProps = {
	label: '',
	moveGroupingItem: () => {},
	removeGroupingItem: () => {},
};

export default GroupingDragItem;
