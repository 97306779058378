import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { eventTarget } from '@cornerstonejs/core';
import { annotation, Enums, ReferenceLinesTool, ScaleOverlayTool, ToolGroupManager } from '@cornerstonejs/tools';
import { useAuth, logDebug } from '@worklist-2/core/src';
import { groupBy } from 'lodash';
import isValidMeasurement, { measurementTools } from './utils/isValidMeasurement';
import { BROADCAST_EVENTS } from './consts/consts';
import { useImageViewerStudiesContext } from './ImageViewerStudiesContext';
import { useImageViewerLayoutContext } from './ImageViewerLayoutContext';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { useImageViewerMultiMonitorContext } from './ImageViewerMultiMonitorContext';
import { useImageViewerCornerstoneContext } from './ImageViewerCornerstoneContext';
import { useMeasurements } from '../hooks/useMeasurements';
import {
	cadsrMeasurements,
	diagramMeasurements,
	getMeasurementsFromAnnotations,
	insertMeasurement,
	measurementSeriesId,
	measurementStudyId,
	measurementSeriesInstanceId,
	srMeasurements,
	getMeasurementManagingOrganizationId,
} from './helpers/measurementBodypartDefiner';
import { getSeriesUID } from '../utils/utils';
import {
	PERMISSIONS_MODULES,
	QC_MODULE_PERMISSIONS,
} from '@worklist-2/worklist/src/DocumentViewerV3/consts/imageViewerPermissionTags';
import { useImageViewerPermissionsContext } from './ImageViewerPermissionsContext';
import { useImageViewerSpineLabelingContext } from './ImageViewerSpineLabelingContext';
import SpineLabelingTool from '../cornerstoneTools/SpineLabelingTool';
import { useSearchParams } from 'react-router-dom';
import { ShutterToolsNames } from '../components/ShutterToolPresetMenu/ShutterToolPresetMenu';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import usePresentationStates, { usePresentationStatesStore } from '../hooks/usePresentationStates';
import { useConfig } from '@rs-core/hooks';

const ImageViewerMeasurementContext = createContext({});

const ImageViewerMeasurementContextProvider = ({ children }) => {
	const [searchParams] = useSearchParams();

	const wonIvSrview = useBooleanFlagValue('WON-IV-SRVIEW');
	const wonIvPrBookmark = useBooleanFlagValue('WON-IV-PR-BOOKMARK');
	const { loggedInUser, authorized } = useAuth();

	const { setLatestMarker } = useImageViewerSpineLabelingContext();

	const [cadsrAnnotations, setCadsrAnnotations] = useState([]);
	const [measurementData, setMeasurementData] = useState([]);
	const [initialMeasurements, setInitialMeasurements] = useState([]);
	const [measurementHistory, setMeasurementHistory] = useState([]);
	const [measurementAreas, setMeasurementAreas] = useState([]);
	const [selectedAnnotationUID, setSelectedAnnotationUID] = useState([]);
	const { series, studies } = useImageViewerStudiesContext();
	const { postMessage } = useImageViewerMultiMonitorContext();
	const {
		isMPRViewRef,
		isFusionViewRef,
		activeViewportIdRef,
		layoutItemsRef,
		activeViewportId,
		highlightedViewportRef,
	} = useImageViewerLayoutContext();
	const { setViewportImageIdIndex } = useImageViewerCornerstoneContext();
	const { isViewOnly, imageViewerPermission } = useImageViewerPermissionsContext();
	const { fetchData, loadSRobjects, updateMeasurements, updateMeasurementItem, deleteMeasurementItem } =
		useMeasurements();
	const lastModifiedByRef = useRef(null);
	const [annotationsEnabled, setAnnotationsEnabled] = useState(true);
	const [structuredReportObjects, setStructuredReportObjects] = useState([]);
	const { currentSeries } = useImageViewerStudiesContext();

	const __config = useConfig();

	const internalManagingOrganizationID = searchParams.get('internalManagingOrganizationID');
	const studyInstanceUID = searchParams.get('StudyInstanceUIDs');

	const { presentationStates } = usePresentationStates({
		studyInstanceUID,
		internalManagingId: internalManagingOrganizationID,
	});

	const fetchPresentationState = usePresentationStatesStore(state => state.fetchResource);

	const _layoutItems = highlightedViewportRef?.current
		? [highlightedViewportRef.current]
		: layoutItemsRef?.current
		? layoutItemsRef.current
		: [];

	const isSharedStudy = searchParams.get('SharedStudy');
	const isEmergencySearch = searchParams.get('isEmergencySearch') === 'true';

	useEffect(() => () => annotation.state.getAnnotationManager().removeAllAnnotations(), []);

	useEffect(() => {
		if (measurementData?.length > 0 && series?.length > 0 && studies?.length > 0) {
			const diagramAreas = diagramMeasurements(series, studies, measurementData);
			setMeasurementAreas(diagramAreas);
		} else {
			setMeasurementAreas([]);
		}
		if (currentSeries?.length > 0) {
			const cadsr = cadsrMeasurements(currentSeries);
			setCadsrAnnotations(cadsr);
		}
	}, [measurementData, studies, series, currentSeries]);

	const undoMeasurementHistory = async (measurements = []) => {
		if (measurements.length === 0) {
			const studyId = measurementStudyId(_layoutItems, activeViewportIdRef?.current);
			await updateMeasurements([], studyId);
		} else {
			const studiesMeasurements = groupBy(measurements, m => m.studyId);
			Object.entries(studiesMeasurements).forEach(async ([key, value]) => {
				await updateMeasurements(value);
			});
		}

		setMeasurementData(measurements);

		const renderingEngine = getDefaultRenderingEngine();
		const viewports = renderingEngine.getViewports();

		const annotationManager = annotation.state.getAnnotationManager();
		const allAnnotations = annotationManager.getAllAnnotations();
		allAnnotations?.forEach(annotationTool => {
			// Remove all annotations except scale overlay tool
			if (annotationTool?.metadata?.toolName !== ScaleOverlayTool.toolName) {
				annotationManager.removeAnnotation(annotationTool.annotationUID);
			}
		});

		viewports.forEach(viewport => {
			const currentImageId = viewport.getCurrentImageId();
			measurements.filter(isValidMeasurement).forEach(m => {
				const referencedImageId = m.metadata?.referencedImageId;
				if (currentImageId === referencedImageId) {
					annotationManager.addAnnotation(m);
				}
			});
			renderingEngine.renderViewport(viewport.id);
		});
	};

	const resetMeasurementHistory = async () => {
		if (initialMeasurements.length === 0) {
			const studyId = measurementStudyId(_layoutItems, activeViewportIdRef?.current);
			await updateMeasurements([], studyId);
		} else {
			const studiesMeasurements = groupBy(initialMeasurements, m => m.studyId);
			Object.entries(studiesMeasurements).forEach(async ([key, value]) => {
				await updateMeasurements(value);
			});
		}

		setMeasurementHistory([]);
		setMeasurementData(initialMeasurements);

		// This is important because cornerstone mutates initialMeasurements and cause incorrect behaviour
		const initialMeasurementsCopy = JSON.parse(JSON.stringify(initialMeasurements));

		const renderingEngine = getDefaultRenderingEngine();
		const viewports = renderingEngine.getViewports();

		const annotationManager = annotation.state.getAnnotationManager();
		const allAnnotations = annotationManager.getAllAnnotations();

		allAnnotations?.forEach(_annotation => {
			if (
				_annotation?.metadata?.toolName === ScaleOverlayTool.toolName ||
				_annotation?.metadata?.toolName === ReferenceLinesTool.toolName
			) {
				return;
			}

			annotationManager.removeAnnotation(_annotation.annotationUID);
		});

		viewports.forEach(viewport => {
			const currentImageId = viewport.getCurrentImageId();
			initialMeasurementsCopy.filter(isValidMeasurement).forEach(m => {
				const referencedImageId = m.metadata?.referencedImageId;
				if (currentImageId === referencedImageId) {
					annotationManager.addAnnotation(m);
				}
			});
			renderingEngine.renderViewport(viewport.id);
		});
	};

	const mergeAndReplaceMeasurements = (measurements = [], newMeasurements = []) => {
		if (!measurements.length && !newMeasurements.length) {
			return [];
		}

		const measurementMap = new Map();

		// Add all measurements to the map (initial state)
		measurements.forEach(measurement => {
			measurementMap.set(measurement.annotationUID, measurement);
		});

		// Replace or add measurements from newMeasurements
		newMeasurements.forEach(measurement => {
			measurementMap.set(measurement.annotationUID, measurement);
		});

		// Return the merged list
		return Array.from(measurementMap.values());
	};

	const loadMeasurements = async (loader, data, isPriorStudy) => {
		const isApplyPrPermission =
			imageViewerPermission?.[PERMISSIONS_MODULES.QC_MODULE]?.[QC_MODULE_PERMISSIONS.PRESENTATION_STATE]?.read;

		const measurements = await fetchData(loader, data);

		if (wonIvSrview && isPriorStudy) {
			const srObjects = await loadSRobjects(data.StudyInstanceUID, isEmergencySearch);
			srObjects?.length > 0 && setStructuredReportObjects(srObjects.map(sr => srMeasurements(sr)).flat());
		}

		let mergedMeasurements = [...measurements];
		let prMeasurements = [];
		if (wonIvPrBookmark && isApplyPrPermission) {
			// Fetches presentation state data
			if (!presentationStates?.isLoaded) {
				await fetchPresentationState({
					__config,
					studyInstanceUID,
					internalManagingId: internalManagingOrganizationID,
				});
			}

			// Deep copy important to avoid mutation issues in cornerstone which can lead to incorrect behavior (PR#16664).
			prMeasurements = Array.isArray(presentationStates?.measurements)
				? JSON.parse(JSON.stringify(presentationStates?.measurements))
				: [];

			// Merge `measurements` and `PrMeasurement` ensuring replacements for matching `annotationUID`
			mergedMeasurements = mergeAndReplaceMeasurements(measurements, prMeasurements);
			logDebug('IV::PR::', 'Applied PR Measurement', { prLength: prMeasurements?.length });
		}

		// Update state with merged measurements
		if (mergedMeasurements.length > 0) {
			// Post unique measurements
			postMessage?.({
				event: BROADCAST_EVENTS.MEASUREMENTS_INIT,
				value: mergedMeasurements,
			});

			setMeasurementData(prevState => mergeAndReplaceMeasurements(prevState, mergedMeasurements));
			setInitialMeasurements(prevState =>
				mergeAndReplaceMeasurements(prevState, JSON.parse(JSON.stringify(mergedMeasurements)))
			);
		}
	};

	useEffect(() => {
		if (loggedInUser?.fullName) {
			lastModifiedByRef.current = loggedInUser.fullName;
		}
	}, [loggedInUser]);

	const onViewMeasurement = measurement => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) return;

		renderingEngine.getViewports().forEach(viewport => {
			const imageIdIndex = viewport
				.getImageIds()
				.findIndex(imageId => imageId === measurement.metadata.referencedImageId);

			if (imageIdIndex !== -1) {
				viewport.setImageIdIndex(imageIdIndex);
				viewport.targetImageIdIndex = imageIdIndex;
			}
		});
	};

	const addMeasurementToAnnotationManager = measurement => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) return;

		const defaultAnnotationManager = annotation.state.getAnnotationManager();
		defaultAnnotationManager.addAnnotation(measurement);

		renderingEngine.render();
	};

	const updateMeasurementInAnnotationManager = modifiedMeasurement => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) return;

		const defaultAnnotationManager = annotation.state.getAnnotationManager();

		const measurementExistInAnnotationManager = defaultAnnotationManager.getAnnotation(
			modifiedMeasurement.annotationUID
		);

		if (!measurementExistInAnnotationManager) {
			return;
		}

		deleteMeasurementFromAnnotationManager(modifiedMeasurement);
		addMeasurementToAnnotationManager(modifiedMeasurement);
	};

	const deleteMeasurementFromAnnotationManager = measurement => {
		const renderingEngine = getDefaultRenderingEngine();
		if (!renderingEngine) return;

		annotation.state.removeAnnotation(measurement.annotationUID);

		renderingEngine.render();
	};

	const deleteSelectedMeasurement = () => {
		const annotationManager = annotation.state.getAnnotationManager();
		const framesOfReference = annotationManager.getFramesOfReference();
		const allMeasurements = [];
		framesOfReference.forEach(frameOfReference => {
			const frameAnnotations = annotationManager.getAnnotations(frameOfReference);
			allMeasurements.push(...getMeasurementsFromAnnotations(frameAnnotations));
		});
		const selectedAnnotations = annotation.selection.getAnnotationsSelected();
		const selectedMeasurements = allMeasurements.filter(m => selectedAnnotations.includes(m.annotationUID));

		if (selectedMeasurements.length > 0) {
			selectedMeasurements.forEach(selectedMeasurement => {
				annotation.selection.deselectAnnotation(selectedMeasurement.annotationUID);
				onDeleteMeasurement(selectedMeasurement);
			});
		} else {
			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			const viewport = renderingEngine.getViewport(activeViewportIdRef.current);

			if (!viewport) {
				return;
			}

			const imageId = viewport.getCurrentImageId();
			const viewportFrameOfReference = viewport.getFrameOfReferenceUID();
			const viewportAnnotations = annotationManager.getAnnotations(viewportFrameOfReference);
			const viewportMeasurements = getMeasurementsFromAnnotations(viewportAnnotations);
			const lastMeasurement = viewportMeasurements.at(-1);

			const allAnnotations = annotationManager.getAllAnnotations();

			const shutterAnnotations = allAnnotations?.filter(
				_annotation =>
					ShutterToolsNames.includes(_annotation.metadata?.toolName) &&
					_annotation.metadata?.referencedImageId === imageId
			);

			if (shutterAnnotations?.length) {
				const lastShutterAnnotation = shutterAnnotations[shutterAnnotations.length - 1];

				annotationManager.removeAnnotation(lastShutterAnnotation.annotationUID);

				viewport.render();
			} else if (lastMeasurement) {
				onDeleteMeasurement(lastMeasurement);
			}
		}
	};

	const toggleAnnotationVisibility = (annotationItem, visibility) => {
		const renderingEngine = getDefaultRenderingEngine();
		if (!renderingEngine) return;
		annotation.visibility.setAnnotationVisibility(annotationItem.annotationUID, visibility);
		renderingEngine.render();
		const updatedAnnotaion = {
			...annotationItem,
			isVisible: visibility,
		};

		postMessage({
			event: BROADCAST_EVENTS.MEASUREMENT_CHANGE_VISIBILITY,
			value: updatedAnnotaion,
		});
		applyAnnotationsChanges(updatedAnnotaion);
	};

	const onDeleteMeasurement = (measurement, shouldPostMessage = true) => {
		deleteMeasurementItem(measurement, measurementData, newMeasurementData => {
			setMeasurementHistory(prev => [...prev, newMeasurementData]);
			setMeasurementData(newMeasurementData);
		});

		if (shouldPostMessage) {
			postMessage({ event: BROADCAST_EVENTS.MEASUREMENT_DELETE, value: measurement });
		}

		deleteMeasurementFromAnnotationManager(measurement);

		const isSpineLabelMeasurement = measurement.metadata?.toolName === SpineLabelingTool.toolName;

		if (isSpineLabelMeasurement) {
			setLatestMarker(measurement.metadata?.referencedImageId);
		}
	};

	const updateMeasurementProps = (uid, propType, value) => {
		const renderingEngine = getDefaultRenderingEngine();
		if (!renderingEngine) return;

		const defaultAnnotationManager = annotation.state.getAnnotationManager();
		let targetAnnotation = measurementData.find(measurement => measurement.annotationUID === uid);

		const { cachedStats, ...otherData } = targetAnnotation.data;
		targetAnnotation = {
			...targetAnnotation,
			...{ data: { cachedStats, ...otherData, [propType]: value } },
		};

		defaultAnnotationManager.removeAnnotation(uid);
		defaultAnnotationManager.addAnnotation(targetAnnotation);
		renderingEngine.render();

		postMessage({
			event: BROADCAST_EVENTS.MEASUREMENT_COMPLETED,
			value: targetAnnotation,
		});
		applyAnnotationsChanges(targetAnnotation);
	};

	const toggleAnnotations = () => {
		const annotationTools = measurementData
			.filter(measurement => measurement.cornerstone3D)
			.map(measurement => measurement.metadata.toolName);

		const toolNames = new Set(annotationTools);

		const toolGroups = ToolGroupManager.getAllToolGroups();

		toolGroups?.forEach(toolGroup => {
			if (!toolGroup) {
				return;
			}

			toolNames.forEach(name => {
				if (!toolGroup._toolInstances[name]) {
					return;
				}

				if (annotationsEnabled) {
					toolGroup.setToolDisabled(name);
					setAnnotationsEnabled(false);
				} else {
					toolGroup.setToolPassive(name);
					setAnnotationsEnabled(true);
				}
			});
		});
	};

	const applyAnnotationsChanges = async measurement => {
		if (isMPRViewRef.current || isFusionViewRef.current || !authorized) {
			return;
		}

		const currentStudyMeasurements = measurementData.filter(m => {
			if (m.managingOrganizationId && measurement.managingOrganizationId) {
				return (
					m.managingOrganizationId === measurement.managingOrganizationId && m.studyId === measurement.studyId
				);
			}
			return m.studyId === measurement.studyId;
		});

		const updatedMeasurements = insertMeasurement(currentStudyMeasurements, measurement);

		try {
			await updateMeasurementItem(updatedMeasurements, measurement);

			const otherStudyMeasurements = measurementData.filter(m => m.studyId !== updatedMeasurements[0].studyId);
			const newMeasurements = [...otherStudyMeasurements, ...updatedMeasurements];
			setMeasurementData(newMeasurements);
			setMeasurementHistory(prev => [...prev, newMeasurements]);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const element = document.getElementById(activeViewportId);
		element?.addEventListener(Enums.Events.KEY_DOWN, KEY_DOWN);

		return () => {
			element?.removeEventListener(Enums.Events.KEY_DOWN, KEY_DOWN);
		};
	}, [activeViewportId]);

	const CORNERSTONE_TOOLS_ANNOTATION_COMPLETED = e => {
		if (isSharedStudy) {
			return;
		}

		const currentMeasurement = e.detail.annotation;
		if (!measurementTools.includes(currentMeasurement.metadata.toolName)) return;

		const renderingEngine = getDefaultRenderingEngine();
		if (!renderingEngine) return;

		const renderingEngineViewport = renderingEngine.getViewport(activeViewportIdRef?.current);

		if (!renderingEngineViewport) {
			return;
		}

		const imageIndex = renderingEngineViewport.getCurrentImageIdIndex();
		const seriesId = measurementSeriesId(_layoutItems, activeViewportIdRef?.current);
		const studyId = measurementStudyId(_layoutItems, activeViewportIdRef?.current);
		const managingOrganizationId = getMeasurementManagingOrganizationId(_layoutItems, activeViewportIdRef?.current);
		const seriesInstanceId = measurementSeriesInstanceId(_layoutItems, activeViewportIdRef?.current, imageIndex);

		const { cachedStats, ...otherData } = currentMeasurement.data;
		const measurement = {
			...currentMeasurement,
			data: { cachedStats, ...JSON.parse(JSON.stringify(otherData)) },
			cornerstone3D: true,
			studyId,
			seriesId,
			seriesInstanceId,
			managingOrganizationId,
			imageIndex: renderingEngineViewport.getCurrentImageIdIndex(),
			modifiedBy: lastModifiedByRef.current,
		};

		postMessage({
			event: BROADCAST_EVENTS.MEASUREMENT_COMPLETED,
			value: measurement,
		});
		if (!isViewOnly) {
			applyAnnotationsChanges(measurement);
		}
	};

	const KEY_DOWN = e => {
		if (e.detail.key === 'Escape') {
			annotation.selection.deselectAnnotation();
		}
	};

	const ANNOTATION_SELECTION_CHANGE = e => {
		postMessage({
			event: BROADCAST_EVENTS.MEASUREMENT_SELECTED,
			value: e.detail.selection,
		});
		setSelectedAnnotationUID(() => [...e.detail.selection]);
	};

	const annotationSelectionChangeRef = useRef(ANNOTATION_SELECTION_CHANGE);
	const annotationCompletedRef = useRef(CORNERSTONE_TOOLS_ANNOTATION_COMPLETED);
	annotationSelectionChangeRef.current = ANNOTATION_SELECTION_CHANGE;
	annotationCompletedRef.current = CORNERSTONE_TOOLS_ANNOTATION_COMPLETED;
	useEffect(() => {
		const onAnnotationSelectionChange = e => {
			annotationSelectionChangeRef.current(e);
		};
		const onAnnotationCompleted = e => {
			annotationCompletedRef.current(e);
		};
		eventTarget.addEventListener(Enums.Events.ANNOTATION_SELECTION_CHANGE, onAnnotationSelectionChange);
		eventTarget.addEventListener(Enums.Events.ANNOTATION_COMPLETED, onAnnotationCompleted);

		return () => {
			eventTarget.removeEventListener(Enums.Events.ANNOTATION_SELECTION_CHANGE, onAnnotationSelectionChange);
			eventTarget.removeEventListener(Enums.Events.ANNOTATION_COMPLETED, onAnnotationCompleted);
		};
	}, [imageViewerPermission]);

	const propagateNavigationToMeasurement = measurement => {
		navigateToMeasurement(measurement);
		postMessage({
			event: BROADCAST_EVENTS.NAVIGATE_TO_MEASUREMENT,
			value: measurement,
		});
	};

	const navigateToMeasurement = measurement => {
		const imageIndex = measurement.imageIndex || 0;
		const { seriesId } = measurement;
		const viewport = _layoutItems.find(layout => getSeriesUID(layout.series) === seriesId);
		if (viewport) {
			setViewportImageIdIndex({
				viewportId: viewport.viewports.at(0).id,
				imageIdIndex: imageIndex,
			});
		}
	};

	return (
		<ImageViewerMeasurementContext.Provider
			value={{
				loadMeasurements,
				measurementAreas,
				measurementData,
				initialMeasurements,
				setInitialMeasurements,
				measurementHistory,
				resetMeasurementHistory,
				undoMeasurementHistory,
				structuredReportObjects,
				cadsrAnnotations,
				setMeasurementData,
				onViewMeasurement,
				onDeleteMeasurement,
				deleteSelectedMeasurement,
				toggleAnnotations,
				addMeasurementToAnnotationManager,
				updateMeasurementInAnnotationManager,
				deleteMeasurementFromAnnotationManager,
				toggleAnnotationVisibility,
				updateMeasurementProps,
				selectedAnnotationUID,
				setSelectedAnnotationUID,
				applyAnnotationsChanges,
				propagateNavigationToMeasurement,
				navigateToMeasurement,
			}}
		>
			{children}
		</ImageViewerMeasurementContext.Provider>
	);
};

const useImageViewerMeasurementContext = () => useContext(ImageViewerMeasurementContext);

export { useImageViewerMeasurementContext, ImageViewerMeasurementContextProvider, ImageViewerMeasurementContext };
