import _ from 'lodash';

const dicomTags = {
	DCM_StudyInstanceUID: '0020000d',
	DCM_Manufacturer: '00080070',
	DCM_ContentSequence: '0040a730',
	DCM_TextValue: '0040a160',
	DCM_ConceptCodeSequence: '0040a168',
	DCM_ConceptNameCodeSequence: '0040a043',
	DCM_ReferencedContentItemIdentifier: '0040db73',
	DCM_ReferencedFrameNumber: '00081160',
	DCM_ReferencedSOPSequence: '00081199',
	DCM_ReferencedSOPClassUID: '00081150',
	DCM_ReferencedSOPInstanceUID: '00081155',
	DCM_CodeValue: '00080100',
	DCM_CodeMeaning: '00080104',
	DCM_GraphicData: '00700022',
	DCM_GraphicType: '00700023',
};

const dicomCADSRCode = {
	IndividualImpressionRecommendationCodeValue: '111034',
	SingleImageFindingCodeValue: '111059',
	RenderingIntentCodeValue: '111056',
	AlgorithmNameCodeValue: '111001',
	AlgorithmVersionCodeValue: '111003',
	CenterCodeValue: '111010',
	OutlineCodeValue: '111041',
	SummaryOfAnalysesCodeValue: '111065',
	LanguageOfContentCodeValue: '121049',
	ImageLibraryCodeValue: '111028',
	OverallImpressionRecommendationCodeValue: '111017',
	SummaryOfDetectionsCodeValue: '111064',
	CompositeFeatureCodeValue: '111015',
	CompositeTypeCodeValue: '111016',
	SuccessfulDetectionsCodeValue: '111063',
	DetectionPerformedCodeValue: '111022',
	RenderingIntentPresRequired: '111150',
	CADProcessingAndFindingFail: '111245',
	CADProcessingAndFindingPartiallySucceeded: '111244',

	CalcificationCluster: 'F-01775',
	IndividualCalcification: 'F-01776',
	MammographyBreastDensity: 'F-01796',
};

const defaultMammoCADSRInfo = {
	data: {
		ProcessingAndFindingSummary: '',
		RenderingIntent: '',
		numOfCalcCluster: 0,
		numOfDensity: 0,
		numOfIndivCalc: 0,
		algorithms: [],
		summaryOfDetections: [],
		manufacturers: [],
		graphicObjectList: [],
	},
};

let imageLibrarys = [];

const loadCADSR = (CADSRObjectList, studyInstanceUID, referencedSOPInstanceUID, imageSOPInstanceUID, frameNumber) => {
	let mammoCADSRInfo = _.cloneDeep(defaultMammoCADSRInfo);

	for (const CADSRObject of CADSRObjectList) {
		let studyInstanceUIDObj = CADSRObject[dicomTags['DCM_StudyInstanceUID']],
			contentSequence,
			conceptNameSQ,
			conceptNameList,
			codeValueSQ,
			codeValue;

		let ProcessingAndFindingSummary = '',
			CADProcessingFailed = false;

		if (studyInstanceUIDObj['Value'][0] == studyInstanceUID) {
			if (
				CADSRObject[dicomTags['DCM_Manufacturer']] &&
				CADSRObject[dicomTags['DCM_Manufacturer']]['Value'].length > 0
			) {
				if (
					mammoCADSRInfo.data['manufacturers'].indexOf(
						CADSRObject[dicomTags['DCM_Manufacturer']]['Value'][0]
					) < 0
				) {
					mammoCADSRInfo.data['manufacturers'].push(CADSRObject[dicomTags['DCM_Manufacturer']]['Value'][0]);
				}
			}

			try {
				contentSequence = CADSRObject[dicomTags['DCM_ContentSequence']]['Value'];
			} catch {
				contentSequence = [];
			}

			for (const content of contentSequence) {
				conceptNameSQ = content[dicomTags['DCM_ConceptNameCodeSequence']];

				if (conceptNameSQ) {
					conceptNameList = conceptNameSQ['Value'];

					for (const conceptName of conceptNameList) {
						codeValueSQ = conceptName[dicomTags['DCM_CodeValue']];
						codeValue = codeValueSQ['Value'];

						if (codeValue.length > 0) {
							if (codeValue[0] == dicomCADSRCode['OverallImpressionRecommendationCodeValue']) {
								let curConceptCodeSQ = content[dicomTags['DCM_ConceptCodeSequence']];

								if (
									curConceptCodeSQ &&
									curConceptCodeSQ['Value'].length > 0 &&
									curConceptCodeSQ['Value'][0][dicomTags['DCM_CodeValue']]
								) {
									ProcessingAndFindingSummary =
										curConceptCodeSQ['Value'][0][dicomTags['DCM_CodeValue']]['Value'][0];
									if (ProcessingAndFindingSummary == dicomCADSRCode['CADProcessingAndFindingFail']) {
										CADProcessingFailed = true;
										break;
									}
								}

								//Issue #55078 framenumber in imagemodel is zero-based but one-based in CAD SR
								loadIndividualImpressionRecommendations(
									content,
									referencedSOPInstanceUID,
									imageSOPInstanceUID,
									frameNumber /*+ 1*/,
									mammoCADSRInfo
								);
							} else if (codeValue[0] == dicomCADSRCode['ImageLibraryCodeValue']) {
								imageLibrarys = content[dicomTags['DCM_ContentSequence']]['Value'];
							} else if (codeValue[0] == dicomCADSRCode['SummaryOfDetectionsCodeValue']) {
								let frameListStr = getReferenceFrames(referencedSOPInstanceUID);
								if (!frameListStr || frameListStr == '') {
									frameListStr = getReferenceFrames(imageSOPInstanceUID);
								}
								loadSummaryOfDetections(content, mammoCADSRInfo, frameListStr);
							}
						}
					}
				}

				if (mammoCADSRInfo.data['ProcessingAndFindingSummary'] == '') {
					if (
						CADProcessingFailed ||
						(ProcessingAndFindingSummary == dicomCADSRCode['CADProcessingAndFindingPartiallySucceeded'] &&
							mammoCADSRInfo.data['numOfDensity'] == 0 &&
							mammoCADSRInfo.data['numOfCalcCluster'] == 0 &&
							mammoCADSRInfo.data['numOfIndivCalc'] > 0)
					) {
						mammoCADSRInfo.data['drawXMark'] = true;
						break;
					} else {
						mammoCADSRInfo.data['ProcessingAndFindingSummary'] = ProcessingAndFindingSummary;
						mammoCADSRInfo.data['drawXMark'] = false;
					}
				} else {
					mammoCADSRInfo.data['ProcessingAndFindingSummary'] = ProcessingAndFindingSummary;
					mammoCADSRInfo.data['drawXMark'] = false;
				}
			}
		}
	}

	return _.isEqual(defaultMammoCADSRInfo, mammoCADSRInfo) ? null : mammoCADSRInfo;
};

const getReference = (sopInstanceUID, frameNumber) => {
	let reference = 0,
		foundReference = false;

	if (imageLibrarys && imageLibrarys.length > 0) {
		for (let i = 0; i < imageLibrarys.length; i++) {
			let curImageLibrary = imageLibrarys[i],
				refSOPSeq = curImageLibrary[dicomTags['DCM_ReferencedSOPSequence']];

			if (refSOPSeq && refSOPSeq['Value'].length > 0) {
				for (const curRefSOP of refSOPSeq['Value']) {
					// from CAD SR object
					let refSOPUID = curRefSOP[dicomTags['DCM_ReferencedSOPInstanceUID']];

					if (refSOPUID && refSOPUID['Value'].length > 0 && refSOPUID['Value'][0] == sopInstanceUID) {
						let refFrameNumber = curRefSOP[dicomTags['DCM_ReferencedFrameNumber']];

						if (frameNumber > 0 && refFrameNumber && refFrameNumber['Value'].length > 0) {
							let frameList = String(refFrameNumber['Value'][0]).split('\\');
							if (frameList.indexOf(String(frameNumber)) >= 0) {
								//array indexOf uses strict equality i.e. ===
								foundReference = true; //matching frame number is preferred, no need to continue search
								reference = i + 1;
								break;
							}
						} else {
							reference = i + 1;
							//it is possible that we have a match with framenumber as well so we continueto see if we have another match

							if (frameNumber <= 0) {
								foundReference = true; //matching frame number is preferred, no need to continue search
								break;
							}
						}
					}
				}
			}

			if (foundReference) {
				reference = i + 1;
				break;
			}
		}
	}

	return reference;
};

const getReferenceFrames = sopInstanceUID => {
	let frameListStr = '';

	if (imageLibrarys && imageLibrarys.length > 0) {
		for (const curImageLibrary of imageLibrarys) {
			let refSOPSeq = curImageLibrary[dicomTags['DCM_ReferencedSOPSequence']];

			if (refSOPSeq && refSOPSeq['Value'].length > 0) {
				for (const curRefSOP of refSOPSeq['Value']) {
					let refSOPUID = curRefSOP[dicomTags['DCM_ReferencedSOPInstanceUID']];

					if (refSOPUID && refSOPUID['Value'].length > 0 && refSOPUID['Value'][0] == sopInstanceUID) {
						let refFrameNumber = curRefSOP[dicomTags['DCM_ReferencedFrameNumber']];

						if (refFrameNumber && refFrameNumber['Value'].length > 0) {
							let frameList = String(refFrameNumber['Value'][0]).split('\\');

							if (frameListStr == '') {
								frameListStr += frameList.toString();
							} else {
								frameListStr += ',' + frameList.toString();
							}
						}
					}
				}
			}
		}
	}

	return frameListStr;
};

const loadSingleImageFindings = (
	ContentSequenceIn,
	referencedSOPInstanceUID,
	imageSOPInstanceUID,
	frameNumber,
	isComposite,
	mammoCADSRInfo
) => {
	let contentSequence, conceptNameSQ, conceptNameList, codeValueSQ, codeValue;

	contentSequence = ContentSequenceIn[dicomTags['DCM_ContentSequence']]['Value'];
	let algorithmName = '',
		algorithmVersion = '',
		graphicType = '',
		graphicData = [],
		reference = 0;

	for (const content of contentSequence) {
		conceptNameSQ = content[dicomTags['DCM_ConceptNameCodeSequence']];

		if (conceptNameSQ) {
			conceptNameList = conceptNameSQ['Value'];

			for (const conceptName of conceptNameList) {
				codeValueSQ = conceptName[dicomTags['DCM_CodeValue']];
				codeValue = codeValueSQ['Value'];

				if (codeValue[0] == dicomCADSRCode['AlgorithmNameCodeValue']) {
					algorithmName = content[dicomTags['DCM_TextValue']]['Value'][0];
					break;
				} else if (codeValue[0] == dicomCADSRCode['AlgorithmVersionCodeValue']) {
					algorithmVersion = content[dicomTags['DCM_TextValue']]['Value'][0];
					break;
				} else if (codeValue[0] == dicomCADSRCode['OutlineCodeValue']) {
					graphicType = content[dicomTags['DCM_GraphicType']]['Value'][0];
					graphicData = content[dicomTags['DCM_GraphicData']]['Value'];

					let refContentSeq = content[dicomTags['DCM_ContentSequence']];

					if (refContentSeq && refContentSeq['Value'] && refContentSeq['Value'].length > 0) {
						let seqValue = refContentSeq['Value'][0];
						if (
							seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']] &&
							seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'].length >= 3
						) {
							reference = seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'][2];
						}
					}
					break;
				} else if (codeValue[0] == dicomCADSRCode['CenterCodeValue']) {
					if (graphicType == '') {
						/* If outline is present, outline value should be respected */
						graphicType = content[dicomTags['DCM_GraphicType']]['Value'][0];
						graphicData = content[dicomTags['DCM_GraphicData']]['Value'];

						let refContentSeq = content[dicomTags['DCM_ContentSequence']];

						if (refContentSeq && refContentSeq['Value'] && refContentSeq['Value'].length > 0) {
							let seqValue = refContentSeq['Value'][0];
							if (
								seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']] &&
								seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'].length >= 3
							) {
								reference = seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'][2];
							}
						}
						break;
					} else {
						continue;
					}
				}
			}
		}
	}

	let conceptCodeSequence = ContentSequenceIn[dicomTags['DCM_ConceptCodeSequence']];

	if (conceptCodeSequence && mammoCADSRInfo) {
		conceptNameList = conceptCodeSequence['Value'];
		let algorithmNameVer = algorithmName + '-' + algorithmVersion;

		if (mammoCADSRInfo.data['algorithms'].indexOf(algorithmNameVer) < 0) {
			mammoCADSRInfo.data['algorithms'].push(algorithmNameVer);
		}

		codeValueSQ = conceptNameList[0][dicomTags['DCM_CodeValue']];
		codeValue = codeValueSQ['Value'];

		let referenceInCAD = 0,
			isValidGraphicObject = false;

		if (referencedSOPInstanceUID) {
			referenceInCAD = getReference(referencedSOPInstanceUID, frameNumber);
		}

		if (referenceInCAD == 0 && imageSOPInstanceUID) {
			referenceInCAD = getReference(imageSOPInstanceUID, frameNumber);
		}

		if (parseInt(reference) > 0 && referenceInCAD > 0) {
			isValidGraphicObject = parseInt(reference) == referenceInCAD;
		}

		if (isValidGraphicObject) {
			if (codeValue[0] == dicomCADSRCode['CalcificationCluster']) {
				mammoCADSRInfo.data['numOfCalcCluster']++;
				let graphicObject = {
					isComposite: isComposite,
					singleImageFinding: codeValue[0],
					graphicType: graphicType,
					graphicData: graphicData,
					reference: reference,
				};
				mammoCADSRInfo.data['graphicObjectList'].push(graphicObject);
			} else if (codeValue[0] == dicomCADSRCode['IndividualCalcification']) {
				mammoCADSRInfo.data['numOfIndivCalc']++;
				let graphicObject = {
					isComposite: isComposite,
					singleImageFinding: codeValue[0],
					graphicType: graphicType,
					graphicData: graphicData,
					reference: reference,
				};
				mammoCADSRInfo.data['graphicObjectList'].push(graphicObject);
			} else if (codeValue[0] == dicomCADSRCode['MammographyBreastDensity']) {
				mammoCADSRInfo.data['numOfDensity']++;
				let graphicObject = {
					isComposite: isComposite,
					singleImageFinding: codeValue[0],
					graphicType: graphicType,
					graphicData: graphicData,
					reference: reference,
				};
				mammoCADSRInfo.data['graphicObjectList'].push(graphicObject);
			}
		}
	}
};

const loadCompositeFeatures = (
	IndividualImpressionRecommendation,
	referencedSOPInstanceUID,
	imageSOPInstanceUID,
	frameNumber,
	mammoCADSRInfo
) => {
	let contentSequence, conceptNameSQ, conceptNameList, codeValueSQ, codeValue;

	contentSequence = IndividualImpressionRecommendation[dicomTags['DCM_ContentSequence']]['Value'];
	let graphicType = '',
		graphicData = [],
		reference = 0;

	for (const content of contentSequence) {
		conceptNameSQ = content[dicomTags['DCM_ConceptNameCodeSequence']];

		if (conceptNameSQ) {
			conceptNameList = conceptNameSQ['Value'];

			for (const conceptName of conceptNameList) {
				codeValueSQ = conceptName[dicomTags['DCM_CodeValue']];
				codeValue = codeValueSQ['Value'];

				if (codeValue[0] == dicomCADSRCode['OutlineCodeValue']) {
					graphicType = content[dicomTags['DCM_GraphicType']]['Value'][0];
					graphicData = content[dicomTags['DCM_GraphicData']]['Value'];

					let refContentSeq = content[dicomTags['DCM_ContentSequence']];

					if (refContentSeq && refContentSeq['Value'] && refContentSeq['Value'].length > 0) {
						let seqValue = refContentSeq['Value'][0];
						if (
							seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']] &&
							seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'].length >= 3
						) {
							reference = seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'][2];
						}
					}
					break;
				} else if (codeValue[0] == dicomCADSRCode['CenterCodeValue']) {
					if (graphicType == '') {
						// If outline is present, outline value should be respected
						graphicType = content[dicomTags['DCM_GraphicType']]['Value'][0];
						graphicData = content[dicomTags['DCM_GraphicData']]['Value'];

						let refContentSeq = content[dicomTags['DCM_ContentSequence']];

						if (refContentSeq && refContentSeq['Value'] && refContentSeq['Value'].length > 0) {
							let seqValue = refContentSeq['Value'][0];
							if (
								seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']] &&
								seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'].length >= 3
							) {
								reference = seqValue[dicomTags['DCM_ReferencedContentItemIdentifier']]['Value'][2];
							}
						}
						break;
					}
				} else if (codeValue[0] == dicomCADSRCode['SingleImageFindingCodeValue']) {
					loadSingleImageFindings(content, referencedSOPInstanceUID, imageSOPInstanceUID, frameNumber, true);
				}
			}
		}
	}

	let conceptCodeSequence = IndividualImpressionRecommendation[dicomTags['DCM_ConceptCodeSequence']];

	if (conceptCodeSequence && mammoCADSRInfo) {
		conceptNameList = conceptCodeSequence['Value'];
		codeValueSQ = conceptNameList[0][dicomTags['DCM_CodeValue']];
		codeValue = codeValueSQ['Value'];

		let referenceInCAD = 0,
			isValidGraphicObject = false;

		if (referencedSOPInstanceUID) {
			referenceInCAD = getReference(referencedSOPInstanceUID, frameNumber);
		}

		if (referenceInCAD == 0 && imageSOPInstanceUID) {
			referenceInCAD = getReference(imageSOPInstanceUID, frameNumber);
		}

		if (parseInt(reference) > 0 && referenceInCAD > 0) {
			isValidGraphicObject = parseInt(reference) == referenceInCAD;
		}

		if (isValidGraphicObject) {
			if (codeValue[0] == dicomCADSRCode['CalcificationCluster']) {
				mammoCADSRInfo.data['numOfCalcCluster']++;
				let graphicObject = {
					isComposite: true,
					singleImageFinding: codeValue[0],
					graphicType: graphicType,
					graphicData: graphicData,
					reference: reference,
				};
				mammoCADSRInfo.data['graphicObjectList'].push(graphicObject);
			} else if (codeValue[0] == dicomCADSRCode['IndividualCalcification']) {
				mammoCADSRInfo.data['numOfIndivCalc']++;
				let graphicObject = {
					isComposite: true,
					singleImageFinding: codeValue[0],
					graphicType: graphicType,
					graphicData: graphicData,
					reference: reference,
				};
				mammoCADSRInfo.data['graphicObjectList'].push(graphicObject);
			} else if (codeValue[0] == dicomCADSRCode['MammographyBreastDensity']) {
				mammoCADSRInfo.data['numOfDensity']++;
				let graphicObject = {
					isComposite: true,
					singleImageFinding: codeValue[0],
					graphicType: graphicType,
					graphicData: graphicData,
					reference: reference,
				};
				mammoCADSRInfo.data['graphicObjectList'].push(graphicObject);
			}
		}
	}
};

const loadIndividualImpressionRecommendations = (
	overallImpressionRecommendation,
	referencedSOPInstanceUID,
	imageSOPInstanceUID,
	frameNumber,
	mammoCADSRInfo
) => {
	let contentSequence, conceptNameSQ, conceptNameList, codeValueSQ, codeValue;

	contentSequence = overallImpressionRecommendation[dicomTags['DCM_ContentSequence']]?.['Value'];
	let IndividualImpressionRecommendationContentSequence = null;

	if (!contentSequence) {
		return;
	}

	//Now time to read and fill this data
	for (const content of contentSequence) {
		conceptNameSQ = content[dicomTags['DCM_ConceptNameCodeSequence']];

		if (conceptNameSQ) {
			conceptNameList = conceptNameSQ['Value'];
			let isIndividualImpressionRecommendation = false;

			for (const conceptName of conceptNameList) {
				codeValueSQ = conceptName[dicomTags['DCM_CodeValue']];
				codeValue = codeValueSQ['Value'];

				if (
					codeValue.length > 0 &&
					codeValue[0] == dicomCADSRCode['IndividualImpressionRecommendationCodeValue']
				) {
					isIndividualImpressionRecommendation = true;
					break;
				}
			}

			if (isIndividualImpressionRecommendation) {
				IndividualImpressionRecommendationContentSequence = content[dicomTags['DCM_ContentSequence']]['Value'];

				for (const IndividualImpressionRecommendationContent of IndividualImpressionRecommendationContentSequence) {
					conceptNameSQ = IndividualImpressionRecommendationContent[dicomTags['DCM_ConceptNameCodeSequence']];

					if (conceptNameSQ) {
						conceptNameList = conceptNameSQ['Value'];

						for (const conceptName of conceptNameList) {
							codeValueSQ = conceptName[dicomTags['DCM_CodeValue']];
							codeValue = codeValueSQ['Value'];

							if (codeValue.length > 0) {
								if (codeValue[0] == dicomCADSRCode['RenderingIntentCodeValue']) {
									let conceptCodeSQ =
										IndividualImpressionRecommendationContent[dicomTags['DCM_ConceptCodeSequence']];

									if (conceptCodeSQ) {
										conceptNameList = conceptCodeSQ['Value'];

										for (const _conceptName of conceptNameList) {
											codeValueSQ = _conceptName[dicomTags['DCM_CodeValue']];
											codeValue = codeValueSQ['Value'];

											if (codeValue.length > 0 && mammoCADSRInfo) {
												mammoCADSRInfo.data['RenderingIntent'] = codeValue[0];
												break;
											}
										}
									}
								} else if (codeValue[0] == dicomCADSRCode['SingleImageFindingCodeValue']) {
									loadSingleImageFindings(
										IndividualImpressionRecommendationContent,
										referencedSOPInstanceUID,
										imageSOPInstanceUID,
										frameNumber,
										false,
										mammoCADSRInfo
									);
								} else if (codeValue[0] == dicomCADSRCode['CompositeFeatureCodeValue']) {
									loadCompositeFeatures(
										IndividualImpressionRecommendationContent,
										referencedSOPInstanceUID,
										imageSOPInstanceUID,
										frameNumber,
										mammoCADSRInfo
									);
								}
							}
						}
					}
				} //Done for all IndividualImpressionRecommendation
			}
		}
	}
};

const loadSummaryOfDetections = (summaryOfDetections, mammoCADSRInfo, frameListStr) => {
	let conceptCodeSQ = summaryOfDetections[dicomTags['DCM_ConceptCodeSequence']];

	if (conceptCodeSQ) {
		let conceptCode = conceptCodeSQ['Value'];

		if (conceptCode.length > 0) {
			let codeMeaning = conceptCode[0][dicomTags['DCM_CodeMeaning']];

			if (codeMeaning && codeMeaning['Value'] && codeMeaning['Value'].length > 0) {
				let summaryOfDetectionsStr = codeMeaning['Value'][0];

				if (frameListStr && frameListStr != '') {
					if (summaryOfDetectionsStr && summaryOfDetectionsStr != '') {
						summaryOfDetectionsStr += ',';
					}

					summaryOfDetectionsStr += 'Slices: ' + frameListStr;
				}

				if (mammoCADSRInfo.data['summaryOfDetections'].indexOf(summaryOfDetectionsStr) < 0) {
					mammoCADSRInfo.data['summaryOfDetections'].push(summaryOfDetectionsStr);
				}
			}
		}
	}
};

export default loadCADSR;
