import React from 'react';
import { Box } from '@mui/material';

const DropZoneWrapper = ({ children, wrapperSx }) => (
	<Box
		sx={{
			position: 'absolute',
			width: '100%',
			height: '100%',
			...wrapperSx,

			'& .file-drop > .file-drop-target': {
				position: 'absolute',
				top: 0,
				left: 0,
				height: '100%',
				width: '100%',
				overflow: 'hidden',
				borderRadius: '10px',
			},
			'& .file-drop > .file-drop-target.file-drop-dragging-over-frame::after': {
				content: '""',
				display: 'flex',
				position: 'absolute',
				top: 0,
				left: 0,
				height: '100%',
				width: '100%',
				borderRadius: '10px',
				boxSizing: 'border-box',
				fontSize: '16px',
				color: 'rgba(255,255,255,.87)',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				alignItems: 'center',
				fontWeight: '400',
				zIndex: 1,
			},
		}}
	>
		{children}
	</Box>
);

export default DropZoneWrapper;
