// core
import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
// mui
import UploadIcon from '../../assets/upload.svg';
import DropZoneWrapper from './DropZoneWrapper';
import DropZoneFooterWrapper from './DropZoneFooterWrapper';

const FilesDropZone = ({ children, enableDropZone, onFileDrop, wrapperSx, studyId }) => {
	const [showFooter, setFooter] = useState(false);
	const handleDrop = (files, e) => {
		e.preventDefault();
		onFileDrop(files);
		setFooter(false);
	};
	const handleDragLeave = e => {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			setFooter(false);
		}
	};
	if (enableDropZone) {
		return (
			<DropZoneWrapper
				data-testid="dropzone-wrapper"
				wrapperSx={{
					...wrapperSx,
					...(showFooter
						? {
								'& .file-drop > .file-drop-target::after': {
									background: 'rgba(66, 165, 245, 0.2)',
									border: '1px dashed #42A5F5',
								},
						  }
						: {}),
				}}
			>
				<FileDrop
					data-testid="file-drop"
					onDragLeave={handleDragLeave}
					onDragOver={() => {
						setFooter(true);
					}}
					onDrop={(files, e) => {
						handleDrop(files, e);
					}}
					onFrameDrop={() => {
						setFooter(false);
					}}
				>
					{showFooter ? (
						<DropZoneFooterWrapper data-testid="dropzone-footer">
							<div className="icon-holder">
								<UploadIcon />
							</div>
							<div className="container">
								<span>Drop file to upload</span>
							</div>
						</DropZoneFooterWrapper>
					) : null}
				</FileDrop>
			</DropZoneWrapper>
		);
	}
	return <>{children}</>;
};

FilesDropZone.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element.isRequired]),
	onFileDrop: PropTypes.func.isRequired,
	enableDropZone: PropTypes.bool,
};

export default FilesDropZone;
