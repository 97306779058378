class FetchService {
	constructor() {
		const { instance } = this.constructor;
		if (instance) {
			return instance;
		}
		this.constructor.instance = this;
	}

	fetchKeepAlive({ url, method = 'POST', headers, body, callback }) {
		fetch(url, {
			method,
			headers,
			body: JSON.stringify(body),
			keepalive: true,
		})
			.then(response => {
				callback(response);
			})
			.catch(err => {
				console.error('Error in fetchKeepAlive: ', err);
			});
	}
}
export const fetchService = new FetchService();
